import { KEY_GRAPHQL_ACTIVITY_LIST_ACTIVITY } from '@activity/config/queryKeys';
import { getQuery } from '@activity/pages/list/helper';
import { activityListQueryAtom } from '@activity/recoil/list';
import { Activity } from '@activity/types/interfaces';
import { Button, Checkbox } from '@base/components/form';
import useInfinitePosts from '@base/hooks/useInfinitePosts';
import { pageDataByMenuAtom } from '@base/recoil/atoms';
import { FilterInput } from '@base/types/interfaces/common';
import { DatasPromise } from '@base/utils/axios/graphql';
import { InfiniteData } from '@tanstack/react-query';
import React, { useCallback, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Item from './item';

interface SectionProps {
  id: string;
  title: string;
}

function makeActivity<T>(data: InfiniteData<DatasPromise<T[]>> | undefined) {
  const items: T[] = [];
  data?.pages.forEach((page) => {
    page.data?.forEach((item) => items.push(item));
  });
  return items;
}

const Section = (props: SectionProps) => {
  const { menu: category } = useParams();
  const { id, title } = props;
  const [isAutoScroll, setAutoScroll] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const listFullQuery = useRecoilValue(activityListQueryAtom);
  const pageData = useRecoilValue(pageDataByMenuAtom(category as string));
  const { filter } = pageData;
  const sectionFilter = { ...filter, headerFilters: { ...filter?.headerFilters, section: id } };
  const filtersQuery: FilterInput = {
    keyword: sectionFilter?.keyword ?? '',
    sort: sectionFilter?.sort,
    paging: sectionFilter?.paging,
    query: getQuery(sectionFilter),
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError } =
    useInfinitePosts<Activity[]>(
      [KEY_GRAPHQL_ACTIVITY_LIST_ACTIVITY, id],
      listFullQuery,
      {
        filter: filtersQuery,
      },
      {
        enabled: !!category,
      },
    );

  const scrollbarRef = useRef<Scrollbars>(null);
  const onScroll = useCallback(
    (values: any) => {
      if (isAutoScroll && values.top === 1 && hasNextPage) {
        fetchNextPage();
        scrollbarRef.current?.scrollToBottom();
      }
    },
    [isAutoScroll, fetchNextPage, scrollbarRef, hasNextPage],
  );

  const getMore = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
      scrollbarRef.current?.scrollToBottom();
    }
  }, [fetchNextPage, scrollbarRef, hasNextPage]);

  const totalItems = data?.pages?.[data.pages.length - 1].paging.totalItems;
  const items = makeActivity<Activity>(data);
  const checkedAll = items.length > 0 && selectedIds.length === items.length;

  const onCheckedRow = useCallback(
    (checkedValue: string) => {
      let newSelectedIds = selectedIds;
      switch (checkedValue) {
        case 'all':
          if (checkedAll) {
            newSelectedIds = [];
          } else {
            for (const i in items) {
              newSelectedIds = [...newSelectedIds, items[i]?.id];
            }
          }
          break;
        default:
          if (selectedIds.indexOf(checkedValue) !== -1) {
            newSelectedIds.splice(selectedIds.indexOf(checkedValue), 1);
          } else {
            newSelectedIds = [...selectedIds, checkedValue];
          }
          break;
      }
      setSelectedIds([...newSelectedIds]);
    },
    [selectedIds, items],
  );

  return (
    <div className="bg-gray-100 wd-25p mg-r-10 rounded">
      {!isError && (
        <Scrollbars
          ref={scrollbarRef}
          autoHeight
          autoHeightMin={400}
          autoHeightMax={document.body.scrollHeight - 400}
          autoHide
          onScrollFrame={onScroll}
        >
          <div className="pd-x-10 pd-b-10">
            <div className="pd-10 d-flex align-items-center tx-bold tx-14 tx-uppercase">
              <Checkbox checked={checkedAll} onChange={() => onCheckedRow('all')} />
              {title}({totalItems})
            </div>
            {items.map((item) => (
              <Item
                key={item.id}
                data={item}
                onCheckedRow={onCheckedRow}
                checked={selectedIds.includes(item.id)}
                category={category}
              />
            ))}
          </div>
        </Scrollbars>
      )}

      {(isLoading || isFetchingNextPage) && (
        <div className="wrap-hanloading" style={{ top: '0px' }}>
          <div className="han-loading" />
        </div>
      )}

      {!isAutoScroll && hasNextPage && (
        <Button
          block={true}
          color={'light'}
          className="bd-0 tx-uppercase tx-10 tx-spacing-1 tx-medium mg-t-10"
          name="Load More"
          onClick={() => getMore()}
        />
      )}
    </div>
  );
};

export default React.memo(Section);
