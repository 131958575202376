import TaskChecklistContainer from '.';
import Loading from '@base/components/loading';
import { useGetView } from '@base/services/view';
import { BaseResponse } from '@base/types/interfaces/response';
import { ACTIVITY_GET_CHECKLIST } from '@activity/services/mywork';
import { TaskChecklist } from '@activity/types/interfaces';
import { useCallback } from 'react';

interface TaskChecklistViewProps {
  menuSourceId: string;
  mode?: 'write' | 'view';
}

const TaskChecklistView = (props: TaskChecklistViewProps) => {
  const { menuSourceId, mode = 'write' } = props;

  /** =================== handle View : Start =============== */
  // get taskchecklist
  const { data, isLoading } = useGetView<BaseResponse<TaskChecklist>>({
    menuKey: ['activity_checklist'],
    menu: 'activity_checklist',
    schemas: ACTIVITY_GET_CHECKLIST,
    params: {
      id: menuSourceId,
    },
    enabled: mode == 'view',
  });

  const onChange = useCallback((val: TaskChecklist[]) => {
    // console.log('onChange', val);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <TaskChecklistContainer
          mode={mode}
          menuSourceId={menuSourceId}
          value={data?.results}
          onChange={onChange}
        />
      )}
    </>
  );
};

export default TaskChecklistView;
