import {
  KEY_GRAPHQL_ACTIVITY_CREATE_RELATEDTO,
  KEY_GRAPHQL_ACTIVITY_DELETE_RELATEDTO,
  KEY_GRAPHQL_ACTIVITY_GET_RELATEDTOS,
} from '@activity/config/queryKeys';
import { ADD_RELATEDTO, DELETE_RELATEDTO } from '@activity/services/mywork';
import { RelatedTo } from '@activity/types/interfaces';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { useQueryClient } from '@tanstack/react-query';

function AssignRelatedToMutate() {
  const queryClient = useQueryClient();
  const mAddRelatedTo: any = useMutationPost<BaseMutationResponse>(
    ADD_RELATEDTO,
    KEY_GRAPHQL_ACTIVITY_CREATE_RELATEDTO,
    {
      onMutate: async () => {},
      onSuccess: (data: BaseMutationResponse, variables: any, context: any) => {
        // console.log('mAddRelatedTo', data);
        const optimistic = variables.relatedTo as RelatedTo;
        queryClient.setQueryData([KEY_GRAPHQL_ACTIVITY_GET_RELATEDTOS], (old: any) => {
          return { results: [...old.results, optimistic] };
        });
      },
      onError: (data: BaseMutationResponse) => {
        // console.log('mAddRelatedTo: Error', data);
      },
    },
  );

  const mDeleteRelatedTo: any = useMutationPost<BaseMutationResponse>(
    DELETE_RELATEDTO,
    KEY_GRAPHQL_ACTIVITY_DELETE_RELATEDTO,
    {
      onMutate: async () => {},
      onSuccess: (data: BaseMutationResponse, variables: any, context: any) => {
        // console.log('mDeleteRelatedTo', data);
        const optimistic = variables.relatedId as string;
        queryClient.setQueryData([KEY_GRAPHQL_ACTIVITY_GET_RELATEDTOS], (old: any) => {
          return {
            results: old.results?.filter((v: RelatedTo) => v.id != optimistic),
          };
        });
      },
      onError: (data: BaseMutationResponse) => {
        // console.log('mDeleteRelatedTo: Error', data);
      },
    },
  );

  return { mAddRelatedTo, mDeleteRelatedTo };
}

export default AssignRelatedToMutate;
