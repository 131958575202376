import {
  KEY_GRAPHQL_ACTIVITY_CREATE_TAG,
  KEY_GRAPHQL_ACTIVITY_DELETE_TAG,
  KEY_GRAPHQL_ACTIVITY_GET_TAGS,
} from '@activity/config/queryKeys';
import { ADD_TAG, DELETE_TAG } from '@activity/services/mywork';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { useQueryClient } from '@tanstack/react-query';

function AssignTagsMutate() {
  const queryClient = useQueryClient();
  const mAddTags: any = useMutationPost<BaseMutationResponse>(
    ADD_TAG,
    KEY_GRAPHQL_ACTIVITY_CREATE_TAG,
    {
      onMutate: async () => {},
      onSuccess: (data: BaseMutationResponse, variables: any, context: any) => {
        // console.log('mAddTags', data);
        const optimistic = variables.tag as string;
        queryClient.setQueryData([KEY_GRAPHQL_ACTIVITY_GET_TAGS], (old: any) => {
          return { results: [...old.results, optimistic] };
        });
      },
      onError: (data: BaseMutationResponse) => {
        // console.log('mAddTags: Error', data);
      },
    },
  );

  const mDeleteTags: any = useMutationPost<BaseMutationResponse>(
    DELETE_TAG,
    KEY_GRAPHQL_ACTIVITY_DELETE_TAG,
    {
      onMutate: async () => {},
      onSuccess: (data: BaseMutationResponse, variables: any, context: any) => {
        // console.log('mDeleteTags', data);
        const optimistic = variables.tag as string;
        queryClient.setQueryData([KEY_GRAPHQL_ACTIVITY_GET_TAGS], (old: any) => {
          return {
            results: old.results?.filter((v: string) => v != optimistic),
          };
        });
      },
      onError: (data: BaseMutationResponse) => {
        // console.log('mDeleteTags: Error', data);
      },
    },
  );

  return { mAddTags, mDeleteTags };
}

export default AssignTagsMutate;
