import { SpanLang } from '@base/components/form';
import {
  CustomerAutoComplete,
  Select,
  UserAutoComplete,
} from '@base/config/write-field/components';
import { LabelValue } from '@base/types/types/app';
import { useCallback, useState } from 'react';
import { UserOrCustomer } from '@activity/types/interfaces';
import { X } from 'react-feather';
import _ from 'lodash';

const CustomerUserOptions: LabelValue[] = [
  {
    label: 'Customer',
    value: 'TYPE_CUSTOMER',
  },
  {
    label: 'User',
    value: 'TYPE_USER',
  },
];

interface CustomerUserProps {
  value: UserOrCustomer[];
  mode?: 'phone' | 'email' | 'none';
  single?: boolean;
  onChange: (val: UserOrCustomer[]) => void;
}

function CustomerUser(props: CustomerUserProps) {
  const { value: items, onChange, single = true, mode = 'none' } = props;
  const [option, setOption] = useState(CustomerUserOptions[0]);

  const onChangeOption = useCallback((val: LabelValue) => {
    setOption(val);
  }, []);

  const onChangeCustomer = useCallback(
    (val: any) => {
      const item = single ? val : val[0];
      if (_.isEmpty(item) || items.findIndex((o) => o.id == item.id) != -1) {
        return;
      }

      let customer: UserOrCustomer = {
        type: item.category == 'CATEGORY_ACCOUNT' ? 'TYPE_ACCOUNT' : 'TYPE_CONTACT',
        id: item.id,
        name: item.name,
      };
      if (mode == 'email') {
        const email = item.emails?.find((o: any) => o.label?.label == 'LABEL_PRIMARY');
        customer.email = email?.email ?? '';
      } else if (mode == 'phone') {
        const phone = item.phones?.find((o: any) => o.label?.label == 'LABEL_PRIMARY');
        customer.phone = phone?.phoneNumber ?? '';
      }

      onChange && onChange(single ? [customer] : [...items, customer]);
    },
    [items],
  );

  const onChangeUser = useCallback(
    (val: any) => {
      const item = single ? val : val[0];
      if (_.isEmpty(item) || items.findIndex((o) => o.id == item.id) != -1) {
        return;
      }
      let user: UserOrCustomer = {
        type: 'TYPE_USER',
        id: item.id,
        name: item.name,
        group: item.properties?.crmBaseGroup,
      };
      if (mode == 'email') {
        const email = item.emails?.find((o: any) => o.label?.label == 'LABEL_PRIMARY');
        user.email = email?.email ?? '';
      } else if (mode == 'phone') {
        const phone = item.phones?.find((o: any) => o.label?.label == 'LABEL_PRIMARY');
        user.phone = phone?.phoneNumber ?? '';
      }

      onChange && onChange(single ? [user] : [...items, user]);
    },
    [items],
  );

  const deleteItem = useCallback(
    (val: string) => {
      onChange && onChange(items.filter((item) => item.id !== val));
    },
    [items],
  );

  return (
    <div className="form-row">
      <div className="col-3">
        <Select
          outSide={true}
          isSearchable={false}
          getOptionLabel={(option: LabelValue) => <SpanLang keyLang={option.label} />}
          options={CustomerUserOptions}
          value={option}
          onChange={(val: LabelValue) => onChangeOption(val)}
        />
      </div>
      <div className="col-9">
        {option.value == 'TYPE_CUSTOMER' ? (
          <CustomerAutoComplete
            single={single}
            value={{}}
            onChange={(val: any) => onChangeCustomer(val)}
          />
        ) : (
          <UserAutoComplete single={single} value={{}} onChange={(val: any) => onChangeUser(val)} />
        )}
      </div>
      <div className="col-12 mg-t-10">
        {items.map((item) => (
          <div key={item.id} className="list-inline-item">
            <div className="tag-item d-flex align-items-center mg-r-5">
              {item.name}
              <button
                type="button"
                className="btn btn-xs btn-link link-03 btn-icon"
                onClick={() => deleteItem(item.id)}
              >
                <X />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CustomerUser;
