import * as keyNames from '@activity/config/keyNames';

const allReceiveEmailColumn = [
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_SUBJECT,
    languageKey: 'Subject',
    defaultViewInList: true,
    sortable: true,
    name: keyNames.KEY_NAME_ACTIVITY_SUBJECT,
    title: 'Subject',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_PURPOSE,
    languageKey: 'Purpose',
    defaultViewInList: true,
    sortable: false,
    name: keyNames.KEY_NAME_ACTIVITY_PURPOSE,
    title: 'Purpose',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_TO,
    languageKey: 'Customer',
    defaultViewInList: true,
    sortable: false,
    name: keyNames.KEY_NAME_ACTIVITY_TO,
    title: 'Customer',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_START_TIME,
    languageKey: 'Received Date',
    defaultViewInList: true,
    sortable: true,
    name: keyNames.KEY_NAME_ACTIVITY_START_TIME,
    title: 'Received Date',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_FROM,
    languageKey: 'Assigned Rep',
    defaultViewInList: true,
    sortable: false,
    name: keyNames.KEY_NAME_ACTIVITY_FROM,
    title: 'Assigned Rep',
  },
];

export default allReceiveEmailColumn;
