import { UserOrCustomer } from '@activity/types/interfaces';
import _ from 'lodash';

interface ToCustomerViewProps {
  value: UserOrCustomer[];
  mode?: 'phone' | 'email';
}

function ToCustomerView(props: ToCustomerViewProps) {
  const { value, mode = 'none' } = props;

  return (
    <div className="form-row">
      <div className="col-12">
        {value?.length > 0 && value?.map((item) => {
          return (
            <div key={item.id} className="tag-item mg-x-5">
              {item.name}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ToCustomerView;
