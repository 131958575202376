import TaskSequenceContainer from '.';
import Loading from '@base/components/loading';
import { useGetView } from '@base/services/view';
import { BaseResponse } from '@base/types/interfaces/response';
import { ACTIVITY_GET_SEQUENCES } from '@activity/services/mywork';
import { TaskSequence } from '@activity/types/interfaces';
import { useCallback } from 'react';

interface TaskSequenceViewProps {
  menuSourceId: string;
  mode?: 'write' | 'view';
}

const TaskSequenceView = (props: TaskSequenceViewProps) => {
  const { menuSourceId, mode = 'write' } = props;

  /** =================== handle View : Start =============== */
  // get tasksequence
  const { data, isLoading } = useGetView<BaseResponse<TaskSequence>>({
    menuKey: ['activity_sequences'],
    menu: 'activity_sequences',
    schemas: ACTIVITY_GET_SEQUENCES,
    params: {
      id: menuSourceId,
    },
    enabled: mode == 'view',
  });

  const onChange = useCallback((val: TaskSequence[]) => {
    // console.log('onChange', val);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <TaskSequenceContainer
          mode={mode}
          menuSourceId={menuSourceId}
          value={data?.results}
          onChange={onChange}
        />
      )}
    </>
  );
};

export default TaskSequenceView;
