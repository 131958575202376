import { atom } from 'recoil';

export const activityWriteOptionAtom = atom({
  key: 'activityWriteOptionAtom',
  default: {
    writeType: '',
    isOpenWrite: false,
    reloadList: () => null,
  },
});

export const activityListQueryAtom = atom({
  key: 'activityListQueryAtom',
  default: '',
});

export const activityListGroupByAtom = atom({
  key: 'activityListGroupByAtom',
  default: 'all',
});

export const activityComparisonFilterState = atom({
  key: 'activityComparisonFilterState',
  default: {
    settingColumns: [],
    filter: {
      query: {} as any
    } as any
  }
});