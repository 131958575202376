import {
  ACTIVITY_DIRECTION_OUT,
  ACTIVITY_TASK_TYPE_CHECKLIST,
  ACTIVITY_TASK_TYPE_MANUAL,
  ACTIVITY_TASK_TYPE_SEQUENCE,
  ACTIVITY_TYPE_CALL,
  ACTIVITY_TYPE_MAIL,
  ACTIVITY_TYPE_SMS,
  ACTIVITY_TYPE_TASK,
} from '@activity/config/constants';
import {
  KEY_NAME_ACTIVITY_CONTENT,
  KEY_NAME_ACTIVITY_STATUS,
  KEY_NAME_ACTIVITY_TASK_CHECKLIST,
  KEY_NAME_ACTIVITY_TASK_SEQUENCE,
} from '@activity/config/keyNames';
import { KEY_GRAPHQL_ACTIVITY_GET_ACTIVITY } from '@activity/config/queryKeys';
import CallBody from '@activity/containers/call-body';
import MailBody from '@activity/containers/mail-body';
import SmsBody from '@activity/containers/sms-body';
import StatusBar from '@activity/containers/status-bar';
import { Activity } from '@activity/types/interfaces';
import { ViewFields } from '@base/components/hook-form/view';
import Loading from '@base/components/loading';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

interface DetailProps {
  menuSource: string;
  menuSourceId?: string;
  ignoreFields?: string[];
  column?: number;
}

const ViewDetail = (props: DetailProps) => {
  const { menuSource, ignoreFields = [], column = 2 } = props;
  const queryClient = useQueryClient();
  const activityData = queryClient.getQueryData([KEY_GRAPHQL_ACTIVITY_GET_ACTIVITY]) as Activity;

  const viewData = useRecoilValue(viewDataByMenuAtom(menuSource));
  const basicFields: any[] = viewData?.layout?.data?.[0]?.children || [];
  const moreFields: any[] = viewData?.layout?.data?.[1]?.children || [];
  _.remove(ignoreFields, (n) => n == KEY_NAME_ACTIVITY_STATUS);

  const checkTaskType = useCallback(
    (v: string) => {
      let match = false;
      if (
        activityData.taskType == ACTIVITY_TASK_TYPE_CHECKLIST &&
        v == KEY_NAME_ACTIVITY_TASK_CHECKLIST
      ) {
        match = true;
      }

      if (
        activityData.taskType == ACTIVITY_TASK_TYPE_SEQUENCE &&
        v == KEY_NAME_ACTIVITY_TASK_SEQUENCE
      ) {
        match = true;
      }

      if (activityData.taskType == ACTIVITY_TASK_TYPE_MANUAL && v == KEY_NAME_ACTIVITY_CONTENT) {
        match = true;
      }
      return match;
    },
    [activityData.taskType],
  );

  // sms: content, call: content, email : mail form, task : content
  const newBasicFields: any[] = [];
  const newMoreFields: any[] = [];

  if (activityData.direction == ACTIVITY_DIRECTION_OUT) {
    newBasicFields.push({
      keyName: 'status',
      config: {
        component: StatusBar,
        hideTitle: true,
        viewProps: {
          userPermission: { isEdit: false, isShow: true },
        },
        showFullRow: true,
      },
      data: activityData.status,
    });
  }

  if (activityData.type == ACTIVITY_TYPE_TASK) {
    // extract content/checklist/sequence
    for (const k in basicFields) {
      const v = basicFields[k];
      if (checkTaskType(v.keyName)) {
        newBasicFields.push(v);
        _.remove(ignoreFields, (n) => n == v.keyName);
      }
    }

    for (const k in moreFields) {
      const v = moreFields[k];
      newMoreFields.push(v);
      if (checkTaskType(v.keyName)) {
        _.remove(ignoreFields, (n) => n == v.keyName);
      }
    }
  } else if (activityData.type == ACTIVITY_TYPE_CALL) {
    newMoreFields.push({
      keyName: 'call-body',
      config: {
        component: CallBody,
        hideTitle: true,
        viewProps: {
          userPermission: { isEdit: false, isShow: true },
        },
        showFullRow: true,
      },
      data: activityData,
    });
  } else if (activityData.type == ACTIVITY_TYPE_MAIL) {
    newMoreFields.push({
      keyName: 'mail-body',
      config: {
        component: MailBody,
        hideTitle: true,
        viewProps: {
          userPermission: { isEdit: false, isShow: true },
        },
        showFullRow: true,
      },
      data: activityData,
    });
  } else if (activityData.type == ACTIVITY_TYPE_SMS) {
    newMoreFields.push({
      keyName: 'sms-body',
      config: {
        component: SmsBody,
        hideTitle: true,
        viewProps: {
          userPermission: { isEdit: false, isShow: true },
        },
        showFullRow: true,
      },
      data: activityData,
    });
  }

  const fields = _.union(newBasicFields, newMoreFields);

  return (
    <React.Suspense fallback={<Loading />}>
      <div className="detail-view scroll-box">
        <div className="form-row">
          <ViewFields
            fields={fields}
            column={column}
            ignoreFields={ignoreFields}
            menu={viewData?.menu ?? ''}
            menuSource={viewData?.menuSource ?? ''}
            menuSourceId={viewData?.menuSourceId ?? ''}
          />
        </div>
      </div>
    </React.Suspense>
  );
};

export default ViewDetail;
