import { atom, atomFamily, selectorFamily } from 'recoil';
import { TaskChecklist, TaskSequence } from '@activity/types/interfaces';

export const checklistIdsAtom = atom<string[]>({
  key: 'checklistIdsAtom',
  default: [],
});

export const checklistAtom = atom<TaskChecklist[]>({
  key: 'checklistAtom',
  default: [],
});

// export const checklistSelectedItemAtom = atom({
//   key: 'checklistSelectedItemAtom',
//   default: '',
// });

export const checklistAtomFamily = atomFamily<TaskChecklist, string>({
  key: 'checklistAtomFamily',
  default: (id) => ({
    id: id,
    title: '',
    description: '',
    duration: {
      time: 86400,
      unit: 'UNIT_DAY',
    },
    instruction: '',
    done: false,
    workers: [],
  }),
});

export const sequenceIdsAtom = atom<string[]>({
  key: 'sequenceIdsAtom',
  default: [],
});

export const sequenceAtom = atom<TaskSequence[]>({
  key: 'sequenceAtom',
  default: [],
});

export const sequenceAtomFamily = atomFamily<TaskSequence, string>({
  key: 'sequenceAtomFamily',
  default: (id) => ({
    id: id,
    seq: 0,
    title: '',
    description: '',
    duration: {
      time: 86400,
      unit: 'UNIT_DAY',
    },
    instruction: '',
    done: false,
    workers: [],
  }),
});
