import React from 'react';
import { useRecoilState } from 'recoil';
import ListHeader from '@activity/components/comparison/list-header';
import ListHeaderFilters from '@activity/components/comparison/list-header/list-header-filters';
import { filterOptions } from '@activity/config/comparison';
import { isArray } from 'lodash';
import { activityComparisonFilterState } from '@activity/recoil/list';

const PageHeader: React.FC<any> = (props) => {
  const { fields, refetch, category } = props;

  const [listFilter, setListFilter] = useRecoilState(activityComparisonFilterState);

  let { filter } = listFilter;

  const handleFilterSubmit = (filterData: any) => {
    let newQuery: any = { ...filter.query };

    delete newQuery?.period;
    if (filterData?.period) {
      newQuery.period = {
        condition: 'AND',
        criteria: [
          { value: filterData?.period?.startDate, operator: '>=' },
          { value: filterData?.period?.endDate, operator: '<=' },
        ],
      };
    }

    delete newQuery?.lastPeriod;
    if (filterData?.lastPeriod) {
      newQuery.lastPeriod = {
        condition: 'AND',
        criteria: [
          { value: filterData?.lastPeriod?.startDate, operator: '>=' },
          { value: filterData?.lastPeriod?.endDate, operator: '<=' },
        ],
      };
    }

    delete newQuery?.groupBy;
    if (filterData?.groupBy) {
      switch (filterData.groupBy) {
        default:
          newQuery.groupBy = {
            value: filterData.groupBy,
            operator: '=',
          };
      }
    }

    filterOptions.map((_option: any) => {
      if (filterData[_option.value]) {
        if (isArray(filterData[_option.value])) {
          const tmpValues: any[] = [];
          filterData[_option.value].map((_ele: any) => {
            tmpValues.push({ value: _ele, operator: '=' });
          });
          newQuery[_option.value] = { condition: 'OR', criteria: tmpValues };
        } else {
          newQuery[_option.value] = { value: filterData[_option.value], operator: '=' };
        }
      } else {
        newQuery[_option.value] = { value: '', operator: '=' };
      }
    });

    let newListFilter = {
      ...listFilter,
      filter: {
        ...listFilter.filter,
        query: newQuery,
      },
    };
    // console.log(newListFilter, 'newListFilter');
    setListFilter(newListFilter);
  };

  return (
    <ListHeader
      category={category}
      columns={fields}
      filterComponent={
        <ListHeaderFilters filterData={filter?.query} onFilterDataChanged={handleFilterSubmit} />
      }
    />
  );
};

export default PageHeader;
