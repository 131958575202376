import {
  ACTIVITY_MENU_CALL,
  ACTIVITY_MENU_EMAIL,
  ACTIVITY_MENU_SMS,
  ACTIVITY_MENU_TASK,
  ACTIVITY_MENU_TICKET,
} from '@activity/config/constants';
import { SpanLang } from '@base/components/form';
import { Select } from '@base/config/write-field/components';
import { LabelValue } from '@base/types/types/app';
import ActivityAutoComplete from '@activity/containers/activity-auto-complete';
import React, { useCallback, useState } from 'react';
import TicketAutoComplete from '@desk/ticket/containers/ticket-auto-complete';
import { ITicket } from '@desk/ticket/types/interfaces/ticket';
import { Activity } from '@activity/types/interfaces';
import { X } from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';

const RelatedToOptions: LabelValue[] = [
  {
    label: 'Ticket',
    value: ACTIVITY_MENU_TICKET,
    extra: 'TYPE_TICKET',
  },
  {
    label: 'Call',
    value: ACTIVITY_MENU_CALL,
    extra: 'TYPE_CALL',
  },
  {
    label: 'Email',
    value: ACTIVITY_MENU_EMAIL,
    extra: 'TYPE_EMAIL',
  },
  {
    label: 'SMS',
    value: ACTIVITY_MENU_SMS,
    extra: 'TYPE_SMS',
  },
  {
    label: 'Task',
    value: ACTIVITY_MENU_TASK,
    extra: 'TYPE_TASK',
  },
];

interface RelatedValue {
  type: string;
  id: string;
  name: string;
}

interface RelatedToProps {
  value: RelatedValue[];
  onChange?: (val: RelatedValue[]) => void;
  onAdd?: (val: RelatedValue) => void;
  onDelete?: (val: string) => void;
  mode?: 'write' | 'view';
}

function RelatedTo(props: RelatedToProps) {
  const { value: items, onChange, onAdd, onDelete, mode = 'write' } = props;
  const [option, setOption] = useState(RelatedToOptions[0]);
  const onChangeOption = useCallback((val: LabelValue) => {
    setOption(val);
  }, []);

  const onChangeRelated = useCallback(
    (val: ITicket | Activity) => {
      // console.log(val);
      // find if there is same ticket in items.
      const found = items.findIndex((_v) => _v.type == option.extra || _v.id == val.id) != -1;
      if (found) return;
      const item = {
        type: option.extra,
        id: val.id,
        name: val.subject,
      };
      onChange && onChange([...items, item]);
      onAdd && onAdd(item);
    },
    [items, option],
  );

  const deleteItem = useCallback(
    (val: string) => {
      onChange && onChange(items.filter((item) => item.id !== val));
      onDelete && onDelete(val);
    },
    [items],
  );

  return (
    <div className="form-row">
      <div className="col-12 mg-b-10">
        {items?.map((item) => {
          const type = RelatedToOptions.find((_v) => _v.extra == item.type)!!;
          return (
            <React.Fragment key={item.id}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="mg-x-5">
                  <span className="mg-r-10">{Icon(type.value as string)}</span>
                  {item.name}
                </div>
                <button
                  type="button"
                  className="btn btn-link btn-icon"
                  onClick={() => deleteItem(item.id)}
                >
                  <X className="tx-danger" />
                </button>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <div className="col-3">
        <Select
          outSide={true}
          isSearchable={false}
          className={'ht-38'}
          getOptionLabel={(option: LabelValue) => <SpanLang keyLang={option.label} />}
          options={RelatedToOptions}
          value={option}
          onChange={(val: LabelValue) => onChangeOption(val)}
        />
      </div>
      <div className="col-9">
        {option.value == ACTIVITY_MENU_TICKET ? (
          <TicketAutoComplete
            single={true}
            value={[]}
            onChange={(val: ITicket) => onChangeRelated(val)}
          />
        ) : (
          <ActivityAutoComplete
            single={true}
            value={[]}
            type={option.extra}
            iconIndicator={option.value as string}
            onChange={(val: Activity) => onChangeRelated(val)}
          />
        )}
      </div>
    </div>
  );
}

export default RelatedTo;
