import React, {useEffect, useState} from 'react';
import SelectDropdownFilters from '@base/components/form/select-dropdown-filters';
import {
  filterOptions,
  periodOptions
} from '@activity/config/comparison';
import PeriodFilter from "./period-filter";
import {EDateRangeType} from "@base/types/enums";

const ListHeaderFilterNormalMode = (props: any) => {
  const {
    filterData,
    onFilterDataChanged,
    filterOptions,
    periodOptions
  } = props;

  return (
    <>
      <SelectDropdownFilters
        placeholder="Filters"
        options={filterOptions}
        onChange={(data: any) => onFilterDataChanged(data, 'filter')}
      />
      <PeriodFilter
        defaultSelected={EDateRangeType.DATE_RANGE_THIS_MONTH}
        options={periodOptions}
        label="Period of:"
        // selected={filterData?.period ?? ''}
        onChange={(data: any) => onFilterDataChanged(data, 'period')}
      />
      <PeriodFilter
        defaultSelected={EDateRangeType.DATE_RANGE_PREVIOUS_MONTH}
        options={periodOptions}
        label="Compare with:"
        // selected={filterData?.period ?? ''}
        onChange={(data: any) => onFilterDataChanged(data, 'lastPeriod')}
      />
    </>
  );
};

interface ListHeaderFilters {
  filterData: {
    [key: string]: any;
  };
  onFilterDataChanged: (value: any) => void;
}

const ListHeaderFilters = (props: ListHeaderFilters) => {
  const {
    filterData,
    onFilterDataChanged,
  } = props;

  const [period, setPeriod] = useState<any>({});
  const [lastPeriod, setLastPeriod] = useState<any>({});
  const [filter, setFilter] = useState<any>({});

  useEffect(() => {
    onFilterDataChanged({filter, period, lastPeriod});
  }, [period, lastPeriod, filter]);

  const filterProps = {
    filterData,
    onFilterDataChanged: (data: any, type: string) => {
      if (type === 'period') {
        setPeriod(data)
      } else if (type === 'lastPeriod') {
        setLastPeriod(data)
      } else {
        setFilter(data);
      }
    },
    filterOptions,
    periodOptions
  };

  return (
    <div className="list-filter-items">
      <ListHeaderFilterNormalMode {...filterProps} />
    </div>
  );
};

export default ListHeaderFilters;
