import * as keyNames from '@activity/config/keyNames';
import { activityComparisonFilterState } from '@activity/recoil/list';
import { FormIcon } from '@base/components/form';
import { ListBody } from '@base/components/list';
import ListLoading, { TableNothing } from '@base/components/list/list-loading';
import ListReactTable8 from '@base/components/list/list-react-table-v8';
import { makeTable8Columns } from '@base/components/utils/helpers/react-table';
import { ASC, DESC } from '@base/config/constant';
import { convertSecondsToString } from '@base/utils/helpers';
import styled from '@emotion/styled';
import { ColumnDef, flexRender, Table } from '@tanstack/react-table';
import classNames from 'classnames';
import { round } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

const footCellData = (
  data: any,
  lastData: any,
  keyName: string,
  isFindPercent: boolean = false,
): any => {
  let newValue: number = data?.[keyName] ?? 0;
  const oldValue: number = lastData?.[keyName] ?? 0;
  const isUp = newValue > oldValue;
  const isDown = newValue < oldValue;

  if (isFindPercent) {
    newValue = oldValue > 0 ? round((newValue * 100) / oldValue - 100, 2) : newValue > 0 ? 100 : 0;
  }

  return {
    value: newValue,
    isUp,
    isDown,
  };
};

const TableWrapper = styled.div`
  tfoot tr {
    position: sticky;
    z-index: 999;
    background-color: var(--background-header-color);

    box-shadow: inset 0 1px 0 var(--border-color), inset 0 1px 0 var(--border-color);
  }

  tfoot tr:first-child {
    bottom: 44px;
  }

  tfoot tr:last-child {
    bottom: 0;
  }
`;

interface TableBodyCellProps {
  data: any;
  keyName: string;
  viewValue?: string;
}

const TableBodyCell = (props: TableBodyCellProps) => {
  const {
    data: { total = {}, lastTotal = {} },
    keyName,
    viewValue,
  } = props;

  const { isUp, isDown, value } = footCellData(total, lastTotal, keyName);

  return (
    <>
      {viewValue ?? value}
      {isUp && (
        <span className="tx-success mg-l-5">
          <FormIcon icon="ArrowUp" />
        </span>
      )}
      {isDown && (
        <span className="tx-danger mg-l-5">
          <FormIcon icon="ArrowDown" />
        </span>
      )}
    </>
  );
};

const PageBody: React.FC<any> = (props) => {
  const {
    category,
    isFetching,
    fields,
    listData: { results: itemsList = [], total = {}, lastTotal = {} },
  } = props;

  const [listFilter, setListFilter] = useRecoilState(activityComparisonFilterState);

  const getMapColumns = () => {
    return {
      [keyNames.KEY_NAME_COMPARISON_USERNAME](col: string, data: any) {
        return data?.[col]?.name ?? '(none)';
      },
      [keyNames.KEY_NAME_COMPARISON_OUTGOING_CALL](col: string, data: any) {
        return <TableBodyCell keyName={col} data={data} />;
      },
      [keyNames.KEY_NAME_COMPARISON_INCOMING_CALL](col: string, data: any) {
        return <TableBodyCell keyName={col} data={data} />;
      },
      [keyNames.KEY_NAME_COMPARISON_ALL_CALL_DURATION](col: string, data: any) {
        const iSeconds = data?.total?.[col] ?? 0;
        const timeStr = convertSecondsToString(iSeconds);
        return <TableBodyCell keyName={col} data={data} viewValue={timeStr} />;
      },
      [keyNames.KEY_NAME_COMPARISON_SENT_EMAIL](col: string, data: any) {
        return <TableBodyCell keyName={col} data={data} />;
      },
      [keyNames.KEY_NAME_COMPARISON_RECEIVED_EMAIL](col: string, data: any) {
        return <TableBodyCell keyName={col} data={data} />;
      },
      [keyNames.KEY_NAME_COMPARISON_SENT_SMS](col: string, data: any) {
        return <TableBodyCell keyName={col} data={data} />;
      },
      [keyNames.KEY_NAME_COMPARISON_TASK](col: string, data: any) {
        return <TableBodyCell keyName={col} data={data} />;
      },
    };
  };

  const setSort = (sort: any) => {
    let newListFilter = {
      ...listFilter,
      filter: {
        ...listFilter.filter,
        sort: sort,
      },
    };
    setListFilter(newListFilter);
  };

  useEffect(() => {
    // console.log(listFilter)
  }, [listFilter]);

  const disableSortByColumns: string | any[] = [];
  const newFields = fields.map((_ele: any) => {
    return {
      ..._ele,
      ...(_ele.keyName === keyNames.KEY_NAME_COMPARISON_USERNAME
        ? {
            width: '300px',
          }
        : {}),
      enableSorting: !disableSortByColumns.includes(_ele.keyName),
    };
  });

  const columns = useMemo<ColumnDef<any>[]>(() => {
    return makeTable8Columns(newFields, getMapColumns(), { category }, []);
  }, [newFields]);

  const listTableProps = {
    nodata: <TableNothing />,
    columns,
    data: itemsList,
    onSortBy: (clName: any, isSorted: any) => {
      if (isSorted !== false) {
        let orderBy = isSorted === 'desc' ? DESC : ASC;
        setSort({ field: clName, orderBy });
      }
    },
    style: {
      maxHeight: 'calc(100vh - 185px)',
    },
    renderBody: (table: Table<any>) => {
      return (
        <>
          <tbody>
            {table.getRowModel().rows.map((row: any, rIdx: number) => {
              return (
                <tr key={rIdx}>
                  {row.getVisibleCells().map((cell: any, ceIdx: number) => {
                    return (
                      <td
                        {...{
                          key: ceIdx,
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {!isFetching && itemsList.length === 0 && (
              <tr>
                <td colSpan={columns.length}>
                  <TableNothing />
                </td>
              </tr>
            )}
          </tbody>
          <tfoot className="bd-t bd-2">
            <tr>
              {newFields.map((v: any, i: number) => {
                if (v.keyName === keyNames.KEY_NAME_COMPARISON_USERNAME) {
                  return (
                    <th scope="row" className="tx-right" key={i}>
                      Total
                    </th>
                  );
                } else if (v.keyName === keyNames.KEY_NAME_COMPARISON_ALL_CALL_DURATION) {
                  return (
                    <td key={i}>
                      {total?.[v.keyName] ? convertSecondsToString(total[v.keyName]) : 0}
                    </td>
                  );
                }
                return <td key={i}>{total?.[v.keyName] ?? 0}</td>;
              })}
            </tr>
            <tr>
              {newFields.map((v: any, i: number) => {
                if (v.keyName === keyNames.KEY_NAME_COMPARISON_USERNAME) {
                  return (
                    <th className="bd-b-0-f tx-right" key={i}>
                      Percentage Change
                    </th>
                  );
                }

                const {
                  value = 0,
                  isUp = false,
                  isDown = false,
                } = footCellData(total, lastTotal, v.keyName, true);

                return (
                  <td className={classNames({ 'tx-success': isUp, 'tx-danger': isDown })} key={i}>
                    {Math.abs(value)}%{isUp && <FormIcon icon="ArrowUp" className="mg-l-5" />}
                    {isDown && <FormIcon icon="ArrowDown" className="mg-l-5" />}
                  </td>
                );
              })}
            </tr>
          </tfoot>
        </>
      );
    },
  };

  const ListBodyMemo = useMemo(() => {
    return (
      <ListBody isScrollY={false}>
        <TableWrapper>
          <ListReactTable8 {...listTableProps} />
        </TableWrapper>
      </ListBody>
    );
  }, [itemsList, columns]);

  return <>{ListBodyMemo}</>;
};

export default PageBody;
