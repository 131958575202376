// GraphQL key
export const KEY_GRAPHQL_ACTIVITY_LIST_ACTIVITY = 'activity_activities';
export const KEY_GRAPHQL_ACTIVITY_GET_ACTIVITY = 'activity_activity';
export const KEY_GRAPHQL_ACTIVITY_GET_ASSIGNTOS = 'activity_assignTos';
export const KEY_GRAPHQL_ACTIVITY_CREATE_ASSIGNTO = 'activity_createAssignTo';
export const KEY_GRAPHQL_ACTIVITY_DELETE_ASSIGNTO = 'activity_deleteAssignTo';
export const KEY_GRAPHQL_ACTIVITY_GET_PRODUCTS = 'activity_products';
export const KEY_GRAPHQL_ACTIVITY_CREATE_PRODUCT = 'activity_createProduct';
export const KEY_GRAPHQL_ACTIVITY_DELETE_PRODUCT = 'activity_deleteProduct';
export const KEY_GRAPHQL_ACTIVITY_GET_TAGS = 'activity_tags';
export const KEY_GRAPHQL_ACTIVITY_CREATE_TAG = 'activity_createTag';
export const KEY_GRAPHQL_ACTIVITY_DELETE_TAG = 'activity_deleteTag';
export const KEY_GRAPHQL_ACTIVITY_GET_RELATEDTOS = 'activity_relatedTos';
export const KEY_GRAPHQL_ACTIVITY_CREATE_RELATEDTO = 'activity_createRelatedTo';
export const KEY_GRAPHQL_ACTIVITY_DELETE_RELATEDTO = 'activity_deleteRelatedTo';
