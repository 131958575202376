import { UserOrCustomer } from '@activity/types/interfaces';
import _ from 'lodash';

interface FromUserViewProps {
  value: UserOrCustomer[];
  mode?: 'phone' | 'email';
}

function FromUserView(props: FromUserViewProps) {
  const { value, mode = 'none' } = props;

  return (
    <>
      {value?.map((item) => {
        return (
          <div key={item.id} className="tag-item mg-r-5">
            {item.name}
          </div>
        );
      })}
    </>
  );
}

export default FromUserView;
