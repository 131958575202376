import Timeline from '@base/containers/timeline';
import ViewDetail from '@activity/containers/view-detail';
import Notes from '@base/containers/note';

export const ACTIVITY_TABS = [
  {
    order: 1,
    default: true,
    label: 'Details',
    path: 'detail',
    tabComponent: ViewDetail,
  },
  {
    order: 2,
    label: 'Timeline',
    path: 'timeline',
    tabComponent: Timeline,
  },
  {
    order: 3,
    label: 'Notes',
    path: 'note',
    tabComponent: Notes,
  },
];
