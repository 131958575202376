import * as keyNames from '@activity/config/keyNames';
import { AssignRepView, DurationView, TextAreaView, TextView } from '@base/config/view-field/components';
import { Input, TextArea, UserAutoComplete } from '@base/config/write-field/components';

export const ChecklistViewField = {
  [keyNames.KEY_NAME_CHECKLIST_TITLE]: {
    languageKey: 'task_checklist_title',
    showFullRow: true,
    component: TextView,
    getValueView: (value: string) => value,
    getValueEdit: (value: string) => value,
    getMutationValue: (value: string) => ({
      [keyNames.KEY_NAME_CHECKLIST_TITLE]: value,
    }),
  },
  [keyNames.KEY_NAME_ACTIVITY_DURATION]: {
    languageKey: 'activity_duration',
    showFullRow: true,
    component: DurationView,
    getValueView: (value: any) => ({
      duration: value.time,
      durationUnit: value.unit,
    }),
    getValueEdit: (value: any) => ({
      duration: value.time,
      durationUnit: value.unit,
    }),
    getMutationValue: (value: any) => ({
      [keyNames.KEY_NAME_ACTIVITY_DURATION]: {
        time: value.duration,
        unit: value.durationUnit,
      },
    }),
    getChangedValue: (value: any) => ({
      time: value.duration,
      unit: value.durationUnit,
    }),
  },
  [keyNames.KEY_NAME_CHECKLIST_WORKER]: {
    languageKey: 'task_checklist_worker',
    showFullRow: true,
    component: AssignRepView,
    getValueView: (value: string) => value,
    getValueEdit: (value: string) => value,
    getMutationValue: (value: string) => ({
      [keyNames.KEY_NAME_CHECKLIST_WORKER]: value,
    }),
  },
  [keyNames.KEY_NAME_CHECKLIST_DESCRIPTION]: {
    languageKey: 'task_checklist_description',
    showFullRow: true,
    component: TextAreaView,
    getValueView: (value: string) => value,
    getValueEdit: (value: string) => value,
    getMutationValue: (value: string) => ({
      [keyNames.KEY_NAME_CHECKLIST_DESCRIPTION]: value,
    }),
  },
  [keyNames.KEY_NAME_CHECKLIST_INSTRUCTION]: {
    languageKey: 'task_checklist_instruction',
    showFullRow: true,
    component: TextAreaView,
    getValueView: (value: string) => value,
    getValueEdit: (value: string) => value,
    getMutationValue: (value: string) => ({
      [keyNames.KEY_NAME_CHECKLIST_INSTRUCTION]: value,
    }),
  },
};

export const ChecklistWriteField = {
  [keyNames.KEY_NAME_CHECKLIST_TITLE]: {
    component: Input,
    showFullRow: true,
    defaultValue: '',
    languageKey: 'task_checklist_title',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_NAME_CHECKLIST_DURATION]: {
    component: DurationView,
    showFullRow: true,
    languageKey: 'task_checklist_duration',
    defaultValue: {
      duration: 86400,
      durationUnit: 'UNIT_DAY',
    },
    parseParam: (value: any) => ({
      time: value.duration,
      unit: value.durationUnit,
    }),
  },
  [keyNames.KEY_NAME_CHECKLIST_WORKER]: {
    component: UserAutoComplete,
    componentProps: {
      single: false,
      showAvatar: true,
      placement: 'left',
    },
    showFullRow: true,
    defaultValue: [],
    languageKey: 'task_checklist_worker',
    parseParam: (_ele: any) => {
      if (_ele.length == 0) {
        return _ele;
      }

      return _ele.map((v: any) => {
        const group =
          v.properties?.crmGroups?.length > 0 ? v.properties.crmGroups[0] : { id: '', name: '' };
        return {
          user: {
            id: v.id,
            name: v.name,
          },
          group: {
            id: group.id,
            name: group.name,
          },
        };
      });
    },
  },
  [keyNames.KEY_NAME_CHECKLIST_DESCRIPTION]: {
    component: TextArea,
    showFullRow: true,
    defaultValue: '',
    languageKey: 'task_checklist_description',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_NAME_CHECKLIST_INSTRUCTION]: {
    component: TextArea,
    showFullRow: true,
    defaultValue: '',
    languageKey: 'task_checklist_instruction',
    parseParam: (value: string) => value,
  },
};
