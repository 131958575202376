import {
  ACTIVITY_CALL_TYPE_LOG,
  ACTIVITY_DIRECTION_IN,
  ACTIVITY_MENU_CALL,
  ACTIVITY_MENU_EMAIL,
  ACTIVITY_MENU_PROTO,
  ACTIVITY_MENU_SMS,
  ACTIVITY_MENU_TASK,
  ACTIVITY_SEND_TYPE_NOW,
  ACTIVITY_SMS_TYPE_LMS,
  ACTIVITY_TASK_TYPE_CHECKLIST,
  ACTIVITY_TASK_TYPE_SEQUENCE,
} from '@activity/config/constants';
import * as keyNames from '@activity/config/keyNames';
import { ADD_ACTIVITY } from '@activity/services/mywork';
import { Source } from '@activity/types/interfaces';
import Button from '@base/components/form/button';
import NoteAndError from '@base/components/hook-form/error-note';
import Field from '@base/components/hook-form/field';
import withMiModal from '@base/hooks/hocs/withMiModal';
import withWriteForm from '@base/hooks/hocs/withWriteForm';
import useMutationPost from '@base/hooks/useMutationPost';
import useUploadFile from '@base/hooks/useUploadFile';
import { ListType } from '@base/types/enums';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { useQueryClient } from '@tanstack/react-query';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { ChevronUp } from 'react-feather';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { KEY_GRAPHQL_ACTIVITY_LIST_ACTIVITY } from '../../config/queryKeys';
import { getSelectionFields } from './helper';

interface WriteFormProps {
  fullScreen: boolean;
  menuApi: string;
  onClose: () => void;
  onReload?: () => void;
  listType: ListType;
  //with write form
  defaultValues: any;
  fields: any[];
  tabs: any[];
  getParams: any;
  source?: Source;
}

const ActivityWrite = (props: WriteFormProps) => {
  const { fullScreen, menuApi, onClose, onReload, defaultValues, fields, getParams, listType } =
    props;
  const activityType = menuApi.split('_').length > 1 ? menuApi.split('_')[1] : ACTIVITY_MENU_TASK;

  //state
  const [isSaving, setIsSaving] = useState(false);
  const [isNew, setIsNew] = useState(false);

  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  const taskType = watch(keyNames.KEY_NAME_ACTIVITY_TASK_TYPE);
  const callType = watch(keyNames.KEY_NAME_ACTIVITY_CALL_TYPE);
  const direction = watch(keyNames.KEY_NAME_ACTIVITY_DIRECTION);
  const sendType = watch(keyNames.KEY_NAME_ACTIVITY_SEND_TYPE);
  const smsType = watch(keyNames.KEY_NAME_ACTIVITY_SMS_TYPE);
  const showMailCc = watch(keyNames.KEY_NAME_ACTIVITY_MAIL_SHOW_CC);
  const smsImageFiles = watch(keyNames.KEY_NAME_ACTIVITY_SMS_IMAGE);
  const { curFileIndex, uploadedFiles } = useUploadFile(smsImageFiles);
  // console.log('taskType', taskType);
  // console.log('callType', callType);
  // console.log('sendType', sendType);
  // console.log('form values', watch());

  // purpose
  const purposeOptions = getSelectionFields(
    setValue,
    keyNames.KEY_NAME_ACTIVITY_PURPOSE,
    'keyRoot=activity_purpose',
  );

  // call result
  const callResultOptions = getSelectionFields(
    setValue,
    keyNames.KEY_NAME_ACTIVITY_CALL_RESULT,
    'keyRoot=activity_call_result',
    {
      enabled: activityType == ACTIVITY_MENU_CALL,
    },
  );

  // priority
  const priorityOptions = getSelectionFields(
    setValue,
    keyNames.KEY_NAME_ACTIVITY_PRIORITY,
    'keyRoot=priority',
  );

  //create mutation
  const queryClient = useQueryClient();
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    ADD_ACTIVITY,
    'activity_createActivity',
    {
      onMutate: () => {
        setIsSaving(true);
      },
      onSuccess: (data: any) => {
        // console.log('success data', data);
        toast.success('Create activity successfully!');
        setIsSaving(false);
        // It has to apply optimistic updates
        if (isNew) {
          // for kanban
          if (listType == ListType.KANBAN) {
            queryClient.invalidateQueries([KEY_GRAPHQL_ACTIVITY_LIST_ACTIVITY, 'overdue']);
            queryClient.invalidateQueries([KEY_GRAPHQL_ACTIVITY_LIST_ACTIVITY, 'today']);
            queryClient.invalidateQueries([KEY_GRAPHQL_ACTIVITY_LIST_ACTIVITY, 'thisweek']);
            queryClient.invalidateQueries([KEY_GRAPHQL_ACTIVITY_LIST_ACTIVITY, 'others']);
            // for calendar
          } else if (listType == ListType.CALENDAR) {
            const currentDate = new Date();
            queryClient.invalidateQueries([
              KEY_GRAPHQL_ACTIVITY_LIST_ACTIVITY,
              {
                year: currentDate.getFullYear(),
                month: currentDate.getMonth(),
              },
            ]);
          } else {
            // for list, grid
            onReload && onReload();
            reset();
          }
        } else {
          onReload && onReload();
          onClose();
          // in case, create in activity. it will go to activity view page
          // else, does not go
          // if (isUndefined(source)) {
          //   setTimeout(() => {
          //     navigate(`/${MENU_ACTIVITY}/${menu}/${activityType}_${data.id}`);
          //   }, SET_TIMEOUT);
          // }
        }
      },
      onError: (error: any) => {
        setIsSaving(false);
        // console.log('failed', error);
        toast.success('Create activity failed');
      },
    },
  );

  //submit form
  const onSubmit = (formData: any) => {
    //upload files
    // console.log('uploadFiles', uploadedFiles);
    // console.log('formData => ', formData);
    delete formData.showCc;

    let configParams: any = getParams(formData);
    configParams.type = ACTIVITY_MENU_PROTO[activityType];
    console.log('configParams => ', configParams);
    mutationAdd.mutate({ activity: configParams });
  };
  // // upload
  // const mUpload: any = useUploadMutation<BaseMutationResponse>(
  //   {
  //     onSuccess: (data: any, variables: any, context: any) => {
  //       //toast.success('Uploaded successfully!');
  //     },
  //     onError: (error: any, variables: any, context: any) => {
  //       //// console.log('mutation error', error);
  //       toast.error('There is error during uploading: ' + JSON.parse(error).message);
  //     },
  //   },
  //   // (pEvent: ProgressEvent, partsNumber: number, partIndex: number, uploadId?: string) =>
  //   //   uploadProgressHandler(pEvent, partsNumber, partIndex, uploadId),
  // );

  // //upload success
  // useEffect(() => {
  //   // console.log('<<< completed useEffect >>>', mUpload);
  //   if (mUpload.isSuccess) {
  //     const newUploadedFiles = [...uploadedFiles];
  //     const selectedFiles: any = [getValues()[keyNames.KEY_NAME_ACTIVITY_SMS_IMAGE]];
  //     const newUpload = {
  //       objectId: mUpload.data.id, //upload id
  //       objectUrl: mUpload.data.url, //download url
  //       name: selectedFiles[curFileIndex].name,
  //       size: selectedFiles[curFileIndex].size,
  //       contentType: selectedFiles[curFileIndex].type,
  //     };
  //     newUploadedFiles.push(newUpload);
  //     setUploadedFiles(newUploadedFiles);
  //     //next file uploading
  //     setLastUploadedFileIndex(curFileIndex);
  //   }
  // }, [mUpload.isSuccess]);

  // /** =============================== UPLOAD HANDLER =========================== */
  // //upload current file
  // useEffect(() => {
  //   // console.log('curFileIndex', curFileIndex);
  //   const selectedFiles = [getValues()[keyNames.KEY_NAME_ACTIVITY_SMS_IMAGE]];
  //   // console.log('selectedFiles', selectedFiles)
  //   if (curFileIndex !== -1) {
  //     mUpload.mutate(selectedFiles[curFileIndex]);
  //   }
  // }, [curFileIndex]);

  // //next file - last file
  // useEffect(() => {
  //   if (lastUploadedFileIndex === -1) {
  //     return;
  //   }
  //   // console.log(getValues())
  //   const selectedFiles = getValues()[keyNames.KEY_NAME_ACTIVITY_SMS_IMAGE];
  //   const isLastFile = lastUploadedFileIndex === selectedFiles.length - 1;
  //   if (isLastFile) {
  //     setCurFileIndex(-1);
  //     //start save to db
  //     // const params = {
  //     //   comment: {
  //     //     ticket: { id: ticketId, name: ticketName },
  //     //     kind: TICKET_COMMENT_KIND_NEW,
  //     //     comment: {
  //     //       content: getValues()[keyNames.KEY_TICKET_REPLY_MESSAGE],
  //     //       display: isPublic ? TICKET_COMMENT_DISPLAY_PUBLIC : TICKET_COMMENT_DISPLAY_PRIVATE,
  //     //       attachedFiles: uploadedFiles,
  //     //     },
  //     //   },
  //     // };
  //     // mutationAdd.mutate(params);
  //   } else {
  //     //next uploaded
  //     const nextFileIndex = curFileIndex + 1;
  //     setCurFileIndex(nextFileIndex);
  //   }
  // }, [lastUploadedFileIndex]);

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-15 bd-t">
        <div className="d-flex mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
          <div className="btn-group dropup">
            <Button
              color="success"
              onClick={() => {
                setIsNew(false);
                handleSubmit((data) => onSubmit(data))();
              }}
              type="button"
              disabled={isSaving || !isValid}
              loading={isSaving}
              name="Save"
            />
            <button
              type="button"
              className="btn btn-success dropdown-toggle-split"
              data-toggle="dropdown"
            >
              <ChevronUp />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <Button
                className="dropdown-item"
                color="primary"
                type="button"
                onClick={() => {
                  setIsNew(true);
                  handleSubmit((data) => onSubmit(data))();
                }}
                disabled={isSaving || !isValid}
                loading={isSaving}
                name="Save and Create New"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  //render
  let formHeight = Object.keys(errors).length > 0 ? 'calc(100vh - 360px)' : 'calc(100vh - 320px)';
  if (fullScreen) {
    formHeight = Object.keys(errors).length > 0 ? 'calc(100vh - 280px)' : 'calc(100vh - 240px)';
  }

  return (
    <>
      <div className="bd-b pd-b-10">
        <NoteAndError errors={errors} />
      </div>
      <div
        className="pos-relative pd-t-10 scroll-box"
        style={{ height: formHeight, overflowY: 'auto', overflowX: 'hidden' }}
      >
        <div className="pd-15 form-row">
          {fields.map((_item) => {
            if (direction?.value == ACTIVITY_DIRECTION_IN) {
              if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_FROM) {
                const item = fields.find((field) => field.keyName == keyNames.KEY_NAME_ACTIVITY_TO);
                _item = {
                  ...item,
                  keyName: keyNames.KEY_NAME_ACTIVITY_FROM,
                  languageKey: _item.languageKey,
                };
              } else if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_TO) {
                const item = fields.find(
                  (field) => field.keyName == keyNames.KEY_NAME_ACTIVITY_FROM,
                );
                _item = {
                  ...item,
                  keyName: keyNames.KEY_NAME_ACTIVITY_TO,
                  languageKey: _item.languageKey,
                };
              }
            }

            // hide direction
            if (
              activityType == ACTIVITY_MENU_TASK ||
              activityType == ACTIVITY_MENU_SMS ||
              activityType == ACTIVITY_MENU_EMAIL
            ) {
              if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_DIRECTION) {
                return;
              }
            }

            if (activityType == ACTIVITY_MENU_TASK) {
              if (taskType.value == ACTIVITY_TASK_TYPE_CHECKLIST) {
                if (
                  _item.keyName == keyNames.KEY_NAME_ACTIVITY_CONTENT ||
                  _item.keyName == keyNames.KEY_NAME_ACTIVITY_TASK_SEQUENCE
                ) {
                  return;
                }
              } else if (taskType.value == ACTIVITY_TASK_TYPE_SEQUENCE) {
                if (
                  _item.keyName == keyNames.KEY_NAME_ACTIVITY_CONTENT ||
                  _item.keyName == keyNames.KEY_NAME_ACTIVITY_TASK_CHECKLIST
                ) {
                  return;
                }
              } else {
                if (
                  _item.keyName == keyNames.KEY_NAME_ACTIVITY_TASK_CHECKLIST ||
                  _item.keyName == keyNames.KEY_NAME_ACTIVITY_TASK_SEQUENCE
                ) {
                  return;
                }
              }
            } else if (activityType == ACTIVITY_MENU_CALL) {
              if (
                _item.keyName == keyNames.KEY_NAME_ACTIVITY_REMINDER &&
                callType.value == ACTIVITY_CALL_TYPE_LOG
              ) {
                return;
              }
              if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_FROM) {
                _item.componentProps = {
                  ..._item.componentProps,
                  mode: 'phone',
                };
              }

              if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_TO) {
                _item.componentProps = {
                  ..._item.componentProps,
                  mode: 'phone',
                };
              }
            } else if (activityType == ACTIVITY_MENU_EMAIL || activityType == ACTIVITY_MENU_SMS) {
              if (
                _item.keyName == keyNames.KEY_NAME_ACTIVITY_START_TIME &&
                sendType.value == ACTIVITY_SEND_TYPE_NOW
              ) {
                return;
              }
              if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_DIRECTION) {
                return;
              }
              if (activityType == ACTIVITY_MENU_SMS) {
                if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_DESCRIPTION) {
                  _item.componentProps = {
                    ..._item.componentProps,
                    checkLength: true,
                    validateLength: smsType == ACTIVITY_SMS_TYPE_LMS ? 2000 : 80,
                  };
                }

                if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_DESCRIPTION) {
                  _item.componentProps = {
                    ..._item.componentProps,
                    checkLength: true,
                    validateLength: smsType == ACTIVITY_SMS_TYPE_LMS ? 2000 : 80,
                  };
                }
              }
              if (activityType == ACTIVITY_MENU_EMAIL) {
                if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_CC && !showMailCc.cc) {
                  return;
                }
                if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_BCC && !showMailCc.bcc) {
                  return;
                }
                if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_FROM) {
                  _item.componentProps = {
                    ..._item.componentProps,
                    mode: 'email',
                  };
                }

                if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_TO) {
                  _item.componentProps = {
                    ..._item.componentProps,
                    mode: 'email',
                  };
                }

                if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_CC) {
                  _item.componentProps = {
                    ..._item.componentProps,
                    mode: 'email',
                  };
                }

                if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_BCC) {
                  _item.componentProps = {
                    ..._item.componentProps,
                    mode: 'email',
                  };
                }
              }
            }

            if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_TPL) {
              // email : { filter: { query: 'group=2 groupBy=all' } }
              // sms : { filter: { query: 'group=3 groupBy=all' } }
              // call : { filter: { query: 'group=5 groupBy=all' } }
              // task : { filter: { query: 'group=4 groupBy=all' } }
              let templateGroup = 0;
              if (activityType == ACTIVITY_MENU_TASK) {
                templateGroup = 4;
              } else if (activityType == ACTIVITY_MENU_EMAIL) {
                templateGroup = 2;
              } else if (activityType == ACTIVITY_MENU_CALL) {
                templateGroup = 5;
              } else if (activityType == ACTIVITY_MENU_SMS) {
                templateGroup = 3;
              }

              _item.componentProps = {
                ..._item.componentProps,
                extraParams: {
                  query: `group=${templateGroup} stage=STAGE_ACTIVE`,
                },
              };
            }

            if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_PURPOSE) {
              _item.componentProps = {
                ..._item.componentProps,
                defaultOptions: purposeOptions,
              };
            }

            if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_CALL_RESULT) {
              _item.componentProps = {
                ..._item.componentProps,
                defaultOptions: callResultOptions,
              };
            }

            if (_item.keyName == keyNames.KEY_NAME_ACTIVITY_PRIORITY) {
              _item.componentProps = {
                ..._item.componentProps,
                defaultOptions: priorityOptions,
              };
            }

            return (
              <Suspense key={_item.keyName}>
                <Field item={_item} control={control} errors={errors} />
              </Suspense>
            );
          })}
        </div>
      </div>
      {renderFooter()}
    </>
  );
};

export default withMiModal(withWriteForm(ActivityWrite));
