import displayLayoutConfig from '@activity/config/display-layout';
import {
  KEY_NAME_ACTIVITY_ATTACHMENTS,
  KEY_NAME_ACTIVITY_BCC,
  KEY_NAME_ACTIVITY_CC,
  KEY_NAME_ACTIVITY_CONTENT,
  KEY_NAME_ACTIVITY_DESCRIPTION,
  KEY_NAME_ACTIVITY_DIRECTION,
  KEY_NAME_ACTIVITY_FROM,
  KEY_NAME_ACTIVITY_MAIL_SEND_INDIVIDUAL,
  KEY_NAME_ACTIVITY_PRODUCTS,
  KEY_NAME_ACTIVITY_RELATED_TO,
  KEY_NAME_ACTIVITY_SEND_TYPE,
  KEY_NAME_ACTIVITY_SMS_IMAGE,
  KEY_NAME_ACTIVITY_STATUS,
  KEY_NAME_ACTIVITY_SUBJECT,
  KEY_NAME_ACTIVITY_TAGS,
  KEY_NAME_ACTIVITY_TASK_CHECKLIST,
  KEY_NAME_ACTIVITY_TASK_KNOWLEDGE,
  KEY_NAME_ACTIVITY_TASK_SEQUENCE,
  KEY_NAME_ACTIVITY_TO,
} from '@activity/config/keyNames';
import { KEY_GRAPHQL_ACTIVITY_GET_ACTIVITY } from '@activity/config/queryKeys';
import viewConfig from '@activity/config/view-field';
import PrintPreview from '@activity/containers/print-preview';
import ViewLeft from '@activity/layouts/desktop/layout1/view/view-left';
import ViewRight from '@activity/layouts/desktop/layout1/view/view-right';
import { DELETE_ACTIVITY } from '@activity/services/mywork';
import { Activity } from '@activity/types/interfaces';
import { ActivityDirection } from '@activity/types/types';
import NotFound from '@base/components/errors/notFound';
import Loading from '@base/components/loading';
import { SET_TIMEOUT } from '@base/config/constant';
import { MENU_ACTIVITY } from '@base/config/menus';
import { ACTIVITY_MENU } from '@base/config/route-menus';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import Title from '@base/containers/view-field/title';
import useMutationPost from '@base/hooks/useMutationPost';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import { IDestopLayout1ViewProps } from '@base/layouts/desktop/layout1/view';
import { deviceAtom, viewDataByMenuAtom } from '@base/recoil/atoms';
import { useGetView } from '@base/services/view';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { isEmptyLayoutData, mergeLayoutData } from '@base/utils/helpers/page-layout-utils';
import { buildViewSchema } from '@base/utils/helpers/schema';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { ACTIVITY_TABS } from './tabs';

interface ActivityViewProps {
  isSplitMode?: boolean;
}

/**
 * Pages: detect layout, device,  init props
 */
const ActivityViewPage = (props: ActivityViewProps) => {
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);
  const { isSplitMode } = props;
  const navigate = useNavigate();

  const params = useParams();
  const id = params.id as string;
  const menu = params.menu as string;
  const currentTab = params['*'];
  const [activityType, menuSourceId] = id?.split('_');
  const menuSource = MENU_ACTIVITY;
  const setViewData = useSetRecoilState(viewDataByMenuAtom(menuSource));
  const [showPrint, setShowPrint] = useState(false);

  // language key format
  // > languageKey = layoutKey+ ‘_field_‘+keyName
  // ex: activity_task_field_type
  // get layout fields according to activity type
  const { data: layoutView, isLoading: layoutLoading } = usePageLayoutByMenu(
    `activity_${activityType.toLowerCase()}`,
    'view',
  );

  // The fields on below : IT has to request single api.
  const dataIgnoreFields = [
    KEY_NAME_ACTIVITY_TASK_CHECKLIST,
    KEY_NAME_ACTIVITY_TASK_SEQUENCE,
    KEY_NAME_ACTIVITY_ATTACHMENTS,
    KEY_NAME_ACTIVITY_RELATED_TO,
    KEY_NAME_ACTIVITY_TAGS,
    KEY_NAME_ACTIVITY_PRODUCTS,
    KEY_NAME_ACTIVITY_TASK_KNOWLEDGE,
    KEY_NAME_ACTIVITY_SMS_IMAGE,
  ];

  const ignoreFields = [
    ...dataIgnoreFields,
    KEY_NAME_ACTIVITY_CONTENT,
    KEY_NAME_ACTIVITY_DESCRIPTION,
    KEY_NAME_ACTIVITY_STATUS,
    KEY_NAME_ACTIVITY_MAIL_SEND_INDIVIDUAL,
  ];

  if (activityType == 'task') {
    ignoreFields.push(KEY_NAME_ACTIVITY_TO);
    ignoreFields.push(KEY_NAME_ACTIVITY_DIRECTION);
    dataIgnoreFields.push(KEY_NAME_ACTIVITY_TO);
  } else if (activityType == 'sms') {
    ignoreFields.push(KEY_NAME_ACTIVITY_FROM);
    ignoreFields.push(KEY_NAME_ACTIVITY_TO);
    ignoreFields.push(KEY_NAME_ACTIVITY_DIRECTION);
  } else if (activityType == 'email') {
    ignoreFields.push(KEY_NAME_ACTIVITY_FROM);
    ignoreFields.push(KEY_NAME_ACTIVITY_TO);
    ignoreFields.push(KEY_NAME_ACTIVITY_CC);
    ignoreFields.push(KEY_NAME_ACTIVITY_BCC);
  } else if (activityType == 'call') {
    ignoreFields.push(KEY_NAME_ACTIVITY_FROM);
    ignoreFields.push(KEY_NAME_ACTIVITY_TO);
  } else {
    return <NotFound />;
  }

  const viewSchema = buildViewSchema({
    sections: layoutView?.data,
    configFields: viewConfig,
    ignore: dataIgnoreFields,
  });

  const { isLoading, data, refetch } = useGetView<Activity>({
    menuKey: [KEY_GRAPHQL_ACTIVITY_GET_ACTIVITY],
    menu: KEY_GRAPHQL_ACTIVITY_GET_ACTIVITY,
    schemas: viewSchema,
    params: {
      id: menuSourceId,
    },
  });

  const layoutData = useMemo(
    () => mergeLayoutData(layoutView, data, viewConfig),
    [layoutView, data],
  );

  useEffect(() => {
    if (!isEmptyLayoutData(layoutData)) {
      setViewData({
        ...layoutData,
        menuSourceId: menuSourceId,
        menuSource: menuSource,
      });
    }
  }, [layoutData, menuSource, menuSourceId]);

  // show or hide according to dataa
  useEffect(() => {
    if (data) {
      if (data.direction == 'DIRECTION_IN') {
        ignoreFields.push(KEY_NAME_ACTIVITY_SEND_TYPE);
      }
    }
  }, [data]);

  const mDeleteActivity: any = useMutationPost<BaseMutationResponse>(
    DELETE_ACTIVITY,
    'activity_deleteActivity',
    {
      onSuccess: () => {
        setTimeout(() => {
          navigate(`/${MENU_ACTIVITY}/${menu}`, { replace: true });
        }, SET_TIMEOUT);
      },
    },
  );

  const deleteActivity = () => {
    mDeleteActivity.mutate({ ids: [menuSourceId] });
  };

  const pageProps: IDestopLayout1ViewProps = {
    headerProps: {
      menu: menu,
      menus: ACTIVITY_MENU,
      isDeleting: false,
      //onExportToPDF: () => { },
      onPrint: () => setShowPrint(true),
      onDelete: deleteActivity,
      onNew: () => {},
      onClone: () => {},
      splitViewMode: isSplitMode,
      moreActions: [
        {
          label: 'Refresh',
          icon: 'RefreshCw',
          onClick: refetch,
        },
      ],
      componentLeft: (
        <Title
          value={data?.subject}
          userPermission={{
            isEdit: true,
          }}
          menuSourceId={menuSourceId}
          keyName={'subject'}
          menuSource={menuSource}
        />
      ),
    },
    centerProps: {
      menuSource: menuSource,
      menuSourceId: menuSourceId,
      ignoreFields: ignoreFields,
      activityType: activityType,
      taskType: data?.taskType,
      tabs: ACTIVITY_TABS,
      currentTab: currentTab,
    },
    componentLeft: (
      <ViewLeft
        ignoreFields={[...ignoreFields, KEY_NAME_ACTIVITY_SUBJECT]}
        menuSourceId={menuSourceId}
        menuSource={menuSource}
      />
    ),
    leftProps: {},
    componentRight: (
      <ViewRight
        showFields={layoutData.showFields}
        menuSourceId={menuSourceId}
        menuSource={menuSource}
        activityType={activityType}
        direction={data?.direction as ActivityDirection}
      />
    ),
    rightProps: {},
  };

  return (
    <>
      {layoutLoading || isLoading ? (
        <Loading />
      ) : (
        <>
          {data ? (
            <>
              <BaseLayoutDisplay
                displayConfig={displayLayoutConfig}
                device={device}
                layout={layout}
                form="view"
                {...pageProps}
              />
              {showPrint && (
                <PrintPreview
                  title="Activity Print Preview"
                  isOpen={showPrint}
                  onClose={() => setShowPrint(false)}
                  data={layoutData}
                />
              )}
            </>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </>
  );
};
export default ActivityViewPage;
