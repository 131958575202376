import ListGridItem from '@activity/components/list/list-grid/list-grid-item';
import { ACTIVITY_ADD_OPTIONS } from '@activity/config/constants';
import displayLayoutConfig from '@activity/config/display-layout';
import { configFields, listLayoutColumns } from '@activity/config/list-field/column';
import { dateByOptions, filterByOptions, groupByOptions } from '@activity/config/list-field/options';
import { KEY_GRAPHQL_ACTIVITY_LIST_ACTIVITY } from '@activity/config/queryKeys';
import Calendar from '@activity/containers/calendar';
import Kanban from '@activity/containers/kanban';
import WritePage from '@activity/pages/write';
import { activityListQueryAtom } from '@activity/recoil/list';
import { activityWriteOptionAtom } from '@activity/recoil/list/atom';
import { getListQuery } from '@activity/services/mywork';
import { Activity } from '@activity/types/interfaces';
import { SpanLang } from '@base/components';
import {
  ListBody,
  ListContainer,
  ListGrid,
  ListHeader,
  ListHeaderFilters,
  ListPagination,
  ListSearch,
  ListTableColumnSetting,
} from '@base/components/list';
import ListLoading, { GridNothing, TableNothing } from '@base/components/list/list-loading';
import ListReactTable from '@base/components/list/list-react-table';
import { makeTableColumns } from '@base/components/utils/helpers/react-table';
import { ASC, DESC, LIST_STALE_TIME } from '@base/config/constant';
import { MENU_ACTIVITY, MENU_MYWORK } from '@base/config/menus';
import { ACTIVITY_MENU } from '@base/config/route-menus';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import ListToolbarContainer from '@base/containers/list/toolbar';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import usePosts from '@base/hooks/usePosts';
import { deviceAtom, pageDataByMenuAtom } from '@base/recoil/atoms';
import { ListType } from '@base/types/enums';
import { SearchFilter } from '@base/types/interfaces/app';
import { FilterInput, SortInput } from '@base/types/interfaces/common';
import { keyStringify } from '@base/utils/helpers';
import { buildListSchema } from '@base/utils/helpers/schema';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { columnRenderRemap, getQuery } from './helper';

interface ListPageProps {
  isSplitMode: boolean;
  setIsSplitMode: (isSplitMode: boolean) => void;
}

const ActivityListPage = (props: ListPageProps) => {
  // console.log('activity list page');
  const { menu: category } = useParams();
  const navigate = useNavigate();
  const { isSplitMode, setIsSplitMode } = props;
  const [{ isDesktop, isMobile, isTablet, device, layout = 'layout1' }] =
    useRecoilState(deviceAtom);
  const [pageData, setPageData] = useRecoilState(
    pageDataByMenuAtom(category == MENU_ACTIVITY ? MENU_ACTIVITY : MENU_MYWORK),
  );
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [writeOption, setWriteOption] = useRecoilState(activityWriteOptionAtom);
  const [fullScreen, setFullScreen] = useState(false); //for write page
  const [showColumns, setShowColumns] = useState<any[]>([]);
  const setListQuery = useSetRecoilState(activityListQueryAtom);
  const { filter, listType, settingColumns } = pageData;
  const layoutMenu = [MENU_ACTIVITY, category].join('_');
  const groupBy = filter?.headerFilters?.groupBy;

  const { data: listLayoutData, isLoading: isLayoutLoading } = usePageLayoutByMenu(
    'activity_activity',
    'list',
  );
  const { listFullQuery, fields } = useMemo(() => {
    let fields = [];
    if (groupBy == 'all') {
      if (listLayoutData && listLayoutData.data) {
        fields = listLayoutData.data;
      }
    } else {
      fields = listLayoutColumns?.[groupBy];
    }
    const listQuerySchema = buildListSchema({ fields, configFields });
    const listFullQuery = getListQuery(listQuerySchema);
    return { fields, listFullQuery };
  }, [listLayoutData, groupBy]);

  useEffect(() => {
    setListQuery(listFullQuery);
  }, [listFullQuery]);

  const setSort = useCallback(
    (sort: SortInput) => {
      setPageData({
        ...pageData,
        filter: {
          ...pageData.filter,
          sort: sort,
        },
      });
    },
    [pageData],
  );

  useEffect(() => {
    if (fields?.length > 0) {
      const newColumns = fields.map((_ele: any) => {
        return {
          ..._ele,
          isViewing: _ele.defaultViewInList,
          disableSortBy: !_ele.sortable,
        };
      });
      setPageData({
        ...pageData,
        settingColumns: {
          ...settingColumns,
          [layoutMenu]: newColumns,
        },
      });
    }
  }, [fields]);

  // columns will show in list
  useEffect(() => {
    if (settingColumns && settingColumns[layoutMenu]) {
      const newShowColumns = settingColumns[layoutMenu].filter((_ele: any) => _ele.isViewing);
      setShowColumns(newShowColumns);
    }
  }, [settingColumns]);

  // list filters
  const filtersQuery: FilterInput = {
    keyword: filter?.keyword ?? '',
    sort: filter?.sort,
    paging: filter?.paging,
    query: getQuery(filter),
  };

  const {
    data: activities,
    isLoading,
    isFetching,
    isPreviousData,
    refetch,
  } = usePosts<Activity[]>(
    [KEY_GRAPHQL_ACTIVITY_LIST_ACTIVITY, keyStringify(filtersQuery, '')],
    listFullQuery,
    {
      filter: filtersQuery,
    },
    {
      keepPreviousData: true,
      // refetchOnWindowFocus: true,
      staleTime: LIST_STALE_TIME,
      enabled:
        fields.length > 0 && (listType === ListType.GRID || listType === ListType.LIST)
          ? true
          : false,
    },
  );

  const sortColumns = useMemo(() => {
    return showColumns?.reduce((options: any, col: any) => {
      options.push({
        label: <SpanLang keyLang={col.title} />,
        value: col.name,
      });
      return options;
    }, []);
  }, [showColumns]);

  const actionsProps = useMemo(
    () => ({
      actions: {
        import: {
          label: 'Import',
          icon: 'UploadCloud',
        },
        export: {
          label: 'Export',
          icon: 'Download',
        },
      },
      onSelect: (key: string) => {},
    }),
    [],
  );

  const onCheckedRow = useCallback(
    (checkedValue: string | number) => {
      const checkedValueStr = checkedValue as string;
      let newSelectedIds = selectedIds;
      switch (checkedValueStr) {
        case 'all':
          break;
        case 'notAll':
          newSelectedIds = [];
          break;
        default:
          if (selectedIds.indexOf(checkedValueStr) !== -1) {
            newSelectedIds.splice(selectedIds.indexOf(checkedValueStr), 1);
          } else {
            newSelectedIds = [...selectedIds, checkedValueStr];
          }
          break;
      }

      setSelectedIds([...newSelectedIds]);
    },
    [selectedIds, setSelectedIds],
  );

  const listProps = {
    listData: activities?.data ?? [],
    isFetching,
    fetching: <ListLoading />,
    onCheckedRow: onCheckedRow,
  };

  const hideColumns: string[] = [];
  const columnRender = columnRenderRemap(category as string);
  const listGridProps = {
    isSplitMode,
    selectedIds,
    device: device !== undefined ? device : 'desktop',
    nodata: <GridNothing />,
    columns: showColumns,
    columnRenderRemap: columnRender,
    hideColumns: hideColumns,
  };

  const listTableProps = {
    nodata: <TableNothing />,
    data: activities?.data ?? [],
    columns: makeTableColumns(showColumns, columnRender, { menu: category }, hideColumns),
    initialState: {
      pageSize: activities?.paging?.totalPage,
      pageIndex: activities?.paging?.currentPage,
      selectedIds: selectedIds,
    },
    onCheckedRow: onCheckedRow,
    onRowClick: (row: any, e: any) => {
      e && e.preventEvent && e.preventDefault();
    },
    onSortBy: (clName: string, isSortedDesc: boolean) => {
      let orderBy = isSortedDesc ? DESC : ASC;
      setSort({ field: clName, orderBy: orderBy });
    },
  };

  const pagingProps = {
    totalPage: activities?.paging?.totalPage || 1,
    totalItems: activities?.paging?.totalItems || 0,
    currentPage: activities?.paging?.currentPage || 1,
    itemPerPage: activities?.paging?.itemPerPage || 10,
    nextPage: activities?.paging?.nextPage || null,
    previousPage: activities?.paging?.previousPage || null,
    isPreviousData,
    onChange: (page: number, size: number) => {
      setPageData({
        ...pageData,
        filter: {
          ...filter,
          paging: {
            page,
            size,
          },
        },
      });
    },
  };

  const handleSearchSubmit = useCallback(
    (formData: any) => {
      let newFilter: SearchFilter = {
        ...pageData.filter,
      };

      if (formData?.keyword != '') {
        newFilter = {
          ...newFilter,
          keyword: formData?.keyword,
        };
        delete formData.keyword;
      }
      newFilter = {
        ...newFilter,
        searchFilters: formData,
      };
      let newData: any = {
        ...pageData,
        filter: newFilter,
      };
      // console.log(newData);
      setPageData(newData);
    },
    [pageData],
  );

  const onChangeColumnSetting = useCallback(
    (newColumns: any) => {
      setPageData({
        ...pageData,
        settingColumns: {
          ...settingColumns,
          [layoutMenu]: newColumns,
        },
      });
    },
    [pageData],
  );

  const categoryOptions = useMemo(() => {
    const menus: Record<string, any> = {};
    for (const m of ACTIVITY_MENU) {
      menus[m.value] = m.label;
    }
    return menus;
  }, []);

  const pageProps = {
    toolbar: (
      <ListToolbarContainer
        isSplitMode={isSplitMode}
        setIsSplitMode={setIsSplitMode}
        categoryOptions={categoryOptions}
        categorySelected={category}
        onCategoryChange={(cate: string) => navigate(`/activity/${cate}`)}
        addOptions={ACTIVITY_ADD_OPTIONS}
        onAdd={(category: string | undefined) => {
          setWriteOption((filter) => ({
            ...filter,
            isOpenWrite: true,
            writeType: category === undefined ? 'task' : category,
          }));
        }}
        listTypeProps={{
          listTypeStr: listType,
          onChangeListType: (listType: ListType) => {
            let newData = {
              ...pageData,
              listType: listType,
            };
            setPageData(newData);
          },
          listTypes: [
            ListType.GRID,
            ListType.LIST,
            ListType.SPLIT,
            ListType.CALENDAR,
            ListType.KANBAN,
          ],
        }}
        actionsProps={actionsProps}
        onRefresh={refetch}
        menu={MENU_ACTIVITY}
        tooltipMessage={'Tooltip'}
      />
    ),
    header: (
      <ListHeader
        isSimple={isSplitMode}
        searchComponent={
          <ListSearch
            isSimple={isSplitMode}
            placeholder={'Enter subject to search'}
            onSubmit={handleSearchSubmit}
          />
        }
        filterComponent={
          <ListHeaderFilters
            category={category ?? ''}
            isSplitMode={isSplitMode}
            groupByOptions={groupByOptions}
            filterByOptions={filterByOptions}
            dateByOptions={dateByOptions}
            filterData={filter?.headerFilters}
            onFilterDataChanged={(valueData: any) => {
              setPageData({
                ...pageData,
                filter: {
                  ...filter,
                  headerFilters: valueData,
                },
              });
              // if (valueData?.groupBy && groupBy != valueData.groupBy) {
              //   setGroupBy(valueData.groupBy);
              // }
            }}
            sortData={filter?.sort}
            onSortDataChanged={(s: any) => {
              setSort(s);
            }}
            sortColumns={sortColumns}
          />
        }
        columnSettingComponent={
          !isSplitMode &&
          listType == ListType.LIST &&
          groupBy == 'all' && (
            <ListTableColumnSetting
              columns={
                settingColumns && settingColumns[layoutMenu] ? settingColumns[layoutMenu] : []
              }
              hideColumns={[]}
              columLimit={10}
              onChange={onChangeColumnSetting}
            />
          )
        }
        onSelectedAll={() => onCheckedRow('all')}
        onDeselectedAll={() => onCheckedRow('notAll')}
        onRefresh={refetch}
        actionsProps={actionsProps}
      />
    ),
    body: (
      <ListBody>
        {isSplitMode ? (
          <ListGrid
            {...listProps}
            {...listGridProps}
            listGridItemRender={(props: any) => <ListGridItem {...props} />}
          />
        ) : (
          <>
            {listType == ListType.LIST && <ListReactTable {...listTableProps} />}
            {listType == ListType.GRID && (
              <ListGrid
                {...listProps}
                {...listGridProps}
                listGridItemRender={(props: any) => <ListGridItem {...props} />}
              />
            )}
            {listType == ListType.KANBAN && <Kanban />}
            {listType == ListType.CALENDAR && <Calendar />}
          </>
        )}

        {(listType == ListType.LIST || listType == ListType.GRID) && (
          <ListPagination isSplitMode={isSplitMode} type={'full'} {...pagingProps} />
        )}
      </ListBody>
    ),
  };

  return (
    <>
      <ListContainer
        isLoading={
          isLayoutLoading ||
          (listType === ListType.GRID || listType === ListType.LIST
            ? isLoading || isFetching
            : false)
        }
        isSplitMode={isSplitMode}
        isDesktop={isDesktop}
      >
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="list"
          {...pageProps}
        />
      </ListContainer>

      {writeOption.isOpenWrite && (
        <WritePage
          title={<SpanLang keyLang={`crm_new_${writeOption.writeType}`} />}
          size="lg"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          //defaultValue={writeDefaultValue}
          isOpen={writeOption.isOpenWrite}
          onClose={() =>
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }))
          }
          onReload={refetch}
          listType={listType}
          menuApi={`activity_${writeOption.writeType}`}
        />
      )}
    </>
  );
};

export default ActivityListPage;
