import React, { useEffect } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useRecoilCallback, useRecoilState, useResetRecoilState } from 'recoil';
import classNames from 'classnames';

import { TaskChecklist } from '@activity/types/interfaces';
import ChecklistItem from './item';
import { Button } from '@base/components/form';
import { checklistAtom } from '@activity/recoil/write/atom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DurationOptions } from '@base/utils/helpers/date-utils';

export const DUR_OPTIONS = _.cloneDeep(DurationOptions).splice(1, 4);

interface TaskChecklistContainerProps {
  menuSourceId?: string;
  mode?: 'write' | 'view';
  className?: string;
  value?: TaskChecklist[];
  onChange?: (val: TaskChecklist[]) => void;
}

const TaskChecklistContainer = (props: TaskChecklistContainerProps) => {
  // console.log('taskchecklist', props);
  const { menuSourceId, mode = 'write', className, value, onChange } = props;
  const resetChecklist = useResetRecoilState(checklistAtom);
  const [checklist, setChecklist] = useRecoilState(checklistAtom);

  // https://react.vlpt.us/basic/16-useEffect.html
  useEffect(() => {
    if (value) {
      if (_.isArray(value) && JSON.stringify(value) !== JSON.stringify(checklist)) {
        setChecklist(value);
      }
    }
  }, [value]);

  useEffect(() => {
    // console.log('컴포넌트 나타남');

    return () => {
      // console.log('컴포넌트 사라짐');
      resetChecklist();
    };
  }, []);

  const handleAdd = useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const items = snapshot.getLoadable(checklistAtom).contents as TaskChecklist[];
        // if there is newFlag: true in items, It can't add new one.
        if (mode == 'view' && items.findIndex((v) => v.newFlag) != -1) return;

        let newItem: TaskChecklist = {
          id: uuidv4(),
          title: '',
          description: '',
          instruction: '',
          duration: {
            time: 1,
            unit: 'UNIT_DAY',
          },
          workers: [],
        };

        if (mode == 'view') {
          newItem.newFlag = true;
        }

        const newItems = [...items, newItem];
        set(checklistAtom, newItems);
        onChange && onChange(newItems);
      },
    [],
  );

  const handleEnd = useRecoilCallback(
    ({ set }) =>
      (result: any) => {
        if (!result.destination) return;
        const items = _.clone(checklist);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        set(checklistAtom, items);
        onChange && onChange(items);
      },
    [checklist],
  );

  return (
    <div className={classNames('pos-relative w-100', className)}>
      <div className="table-responsive">
        <div className="divTable minimalist">
          <div className="divTableHeading">
            <div className="divTableRow">
              <div className="divTableHead">Title</div>
              <div className="divTableHead">Duration</div>
              <div className="divTableHead">Worker</div>
              <div className="divTableHead"></div>
            </div>
          </div>
          <DragDropContext onDragEnd={handleEnd}>
            <Droppable droppableId="checklist">
              {(provided) => (
                <div className="divTableBody" {...provided.droppableProps} ref={provided.innerRef}>
                  {checklist.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          className={classNames(
                            'divTableRow',
                            snapshot.isDragging ? 'selected' : 'not-selected',
                          )}
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                        >
                          <ChecklistItem
                            item={item}
                            mode={mode}
                            menuSourceId={menuSourceId}
                            onChange={onChange}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <Button name="Add a line" color="link" icon="Plus" className="mg-t-10" onClick={handleAdd} />
    </div>
  );
};

export default TaskChecklistContainer;
