import {
  ACTIVITY_MENU_KEYS,
  ACTIVITY_TYPE_CALL,
  ACTIVITY_TYPE_MAIL,
  ACTIVITY_TYPE_PROCESS,
  ACTIVITY_TYPE_SMS,
  ACTIVITY_TYPE_TASK,
} from '@activity/config/constants';
import * as keyNames from '@activity/config/keyNames';
import { dateByOptions, groupByOptions, searchFields } from '@activity/config/list-field/options';
import { SpanLang } from '@base/components';
import RawHTML from '@base/components/raw-html';
import { SearchFilter } from '@base/types/interfaces/app';
import { LabelValue } from '@base/types/types/app';
import { convertDateTimeServerToClient } from '@base/utils/helpers';
import { parseDurationValueToString } from '@base/utils/helpers/date-utils';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

export const columnRenderRemap = (menu: string) => ({
  subject(col: string, data: any) {
    const name = data[col] ? data[col] : '';
    if (data.type == ACTIVITY_TYPE_PROCESS) {
      if (data.source?.menu == 'MENU_DESK') {
        return <Link to={`/desk/ticket/${data.source.id}`}>{name}</Link>;
      }
    } else {
      const id = data.id ?? '';
      const type = ACTIVITY_MENU_KEYS[data.type];
      return <Link to={`/activity/${menu}/${type}_${id}`}>{name}</Link>;
    }
  },
  type(col: string, data: any) {
    return data[col];
  },
  priority(col: string, data: any) {
    return <SpanLang keyLang={data[col]?.languageKey} />;
  },
  taskType(col: string, data: any) {
    return <SpanLang keyLang={data[col]?.languageKey} />;
  },
  callType(col: string, data: any) {
    return <SpanLang keyLang={data[col]?.languageKey} />;
  },
  createdAt(col: string, data: any) {
    return data[col] ? convertDateTimeServerToClient({ date: data[col] }) : '';
  },
  updatedAt(col: string, data: any) {
    return data[col] ? convertDateTimeServerToClient({ date: data[col] }) : '';
  },
  activityDate(col: string, data: any) {
    if (
      data.type == ACTIVITY_TYPE_CALL ||
      data.type == ACTIVITY_TYPE_MAIL ||
      data.type == ACTIVITY_TYPE_SMS
    ) {
      return data[keyNames.KEY_NAME_ACTIVITY_START_TIME]
        ? convertDateTimeServerToClient({ date: data[keyNames.KEY_NAME_ACTIVITY_START_TIME] })
        : '';
    } else if (data.type == ACTIVITY_TYPE_TASK) {
      return data[keyNames.KEY_NAME_ACTIVITY_END_TIME]
        ? convertDateTimeServerToClient({ date: data[keyNames.KEY_NAME_ACTIVITY_END_TIME] })
        : '';
    } else {
      return;
    }
  },
  status(col: string, data: any) {
    return data[col];
  },
  to(col: string, data: any) {
    const direction = data['direction'];
    let items = data[col] ? (data[col] as any[]) : [];
    if (direction == 'DIRECTION_IN') {
      items = data['from'] ? (data['from'] as any[]) : [];
    }
    return items?.map((v) => {
      return <div key={v.id}>{v.name}</div>;
    });
  },
  createdBy(col: string, data: any) {
    const createdBy = data[col] ?? null;
    return createdBy?.name;
  },
  duration(col: string, data: any) {
    return data[col] ? parseDurationValueToString(data[col], false) : '';
  },
  direction(col: string, data: any) {
    return data[col] == 'DIRECTION_NONE' ? '' : data[col];
  },
  content(col: string, data: any) {
    const content = data[col] ?? '';
    return <RawHTML>{content}</RawHTML>;
  },
  startTime(col: string, data: any) {
    return data[col] ? convertDateTimeServerToClient({ date: data[col] }) : '';
  },
  endTime(col: string, data: any) {
    return data[col] ? convertDateTimeServerToClient({ date: data[col] }) : '';
  },
  dueDate(col: string, data: any) {
    if (
      data.type == ACTIVITY_TYPE_CALL ||
      data.type == ACTIVITY_TYPE_MAIL ||
      data.type == ACTIVITY_TYPE_SMS
    ) {
      return data[keyNames.KEY_NAME_ACTIVITY_START_TIME]
        ? convertDateTimeServerToClient({ date: data[keyNames.KEY_NAME_ACTIVITY_START_TIME] })
        : '';
    } else if (data.type == ACTIVITY_TYPE_TASK) {
      return data[keyNames.KEY_NAME_ACTIVITY_DUE_DATE]
        ? convertDateTimeServerToClient({ date: data[keyNames.KEY_NAME_ACTIVITY_DUE_DATE] })
        : '';
    } else {
      return;
    }
  },
  purpose(col: string, data: any) {
    return <SpanLang keyLang={data?.[col]?.name} />;
  },
  callResult(col: string, data: any) {
    return <SpanLang keyLang={data?.[col]?.name} />;
  },
  from(col: string, data: any) {
    const direction = data['direction'];
    let items = data[col] ? (data[col] as any[]) : [];
    if (direction == 'DIRECTION_IN') {
      items = data['to'] ? (data['to'] as any[]) : [];
    }
    return items?.map((v) => {
      return <div key={v.id}>{v.name}</div>;
    });
  },
});

export const getQuery = (filter: SearchFilter | undefined) => {
  // filters query
  const groupBy = filter?.headerFilters?.groupBy;
  const groupByValue = useMemo(
    () => groupByOptions?.find((v: LabelValue) => v.value === groupBy),
    [groupBy],
  );

  const query = useMemo(() => {
    let queries: string[] = [];
    Object.keys(filter?.headerFilters).forEach((key) => {
      const value = filter?.headerFilters[key];
      const isDateTime = dateByOptions?.findIndex((v: LabelValue) => v.value === key) > -1;
      if (isDateTime) {
        // Activity Date : Call -Call Start Date, Email/SMS-Send Date, Task-Done Date 출력
        if (key == keyNames.KEY_NAME_ACTIVITY_DATE) {
          if (
            groupByValue?.extra == ACTIVITY_TYPE_CALL ||
            groupByValue?.extra == ACTIVITY_TYPE_MAIL ||
            groupByValue?.extra == ACTIVITY_TYPE_SMS
          ) {
            key = keyNames.KEY_NAME_ACTIVITY_START_TIME;
          } else if (groupByValue?.extra == ACTIVITY_TYPE_TASK) {
            key = keyNames.KEY_NAME_ACTIVITY_END_TIME;
          } else {
            return;
          }
          // Due Date: Scheduled Call-Call Start Time, Scheduled Email/SMS-Send Date, Task-Due Date
        } else if (key == keyNames.KEY_NAME_ACTIVITY_DUE_DATE) {
          if (
            groupByValue?.extra == ACTIVITY_TYPE_CALL ||
            groupByValue?.extra == ACTIVITY_TYPE_MAIL ||
            groupByValue?.extra == ACTIVITY_TYPE_SMS
          ) {
            key = keyNames.KEY_NAME_ACTIVITY_START_TIME;
          } else if (groupByValue?.extra == ACTIVITY_TYPE_TASK) {
            key = keyNames.KEY_NAME_ACTIVITY_DUE_DATE;
          } else {
            return;
          }
        }
        queries.push([key, '>=', '"' + value?.[0] + '"'].join(''));
        queries.push([key, '<=', '"' + value?.[1] + '"'].join(''));
      } else if (
        key == keyNames.KEY_NAME_ACTIVITY_DURATION ||
        key == keyNames.KEY_NAME_ACTIVITY_EDURATION
      ) {
        queries.push([key, '>=', '"' + value?.['from'].duration + '"'].join(''));
        queries.push([key, '<=', '"' + value?.['to'].duration + '"'].join(''));
      } else {
        queries.push([key, '=', value].join(''));
      }
    });

    // search query
    if (filter?.keyword != '') {
      if (searchFields?.length > 0) {
        const orQueries = searchFields?.map((field: LabelValue) => {
          return [field?.value, ':', '"' + filter?.keyword + '"'].join('');
        });
        queries.push('{' + orQueries.join(' ') + '}');
      }
    }

    if (queries?.length) {
      return '(' + queries.join(' ') + ')';
    }
    return '';
  }, [filter]);

  return query;
};
