import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button, Input } from '@base/components/form';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Duration from '@base/components/form/duration/edit';
import { UserAutoComplete } from '@base/containers';
import { DurationOptions } from '@base/utils/helpers/date-utils';
import { X } from 'react-feather';
import { TaskSequence } from '@activity/types/interfaces';

const DUR_OPTIONS = _.cloneDeep(DurationOptions).splice(1, 4);

interface TaskSequenceWriteProps {
  className?: string;
  mode?: 'w' | 'r';
  value?: TaskSequence[];
  onChange?: (val: TaskSequence[]) => void;
}

const TaskSequenceForm = (props: TaskSequenceWriteProps) => {
  const { className, mode = 'w', value, onChange } = props;
  //state
  const [items, setItems] = useState<any[]>([]); //sequences list
  const defaultItem = {
    title: '',
    description: '',
    instruction: '',
    duration: {
      time: 1,
      unit: 'UNIT_DAY',
    },
    workers: [],
  };

  //init items by value
  useEffect(() => {
    if (value) {
      if (_.isArray(value) && JSON.stringify(value) !== JSON.stringify(items)) {
        setItems(value);
      }
    } else {
      setItems([]);
    }
  }, [value]);

  //end dragging
  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const newItems = [...items];
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
    setItems(newItems);
    //callback
    onChange && onChange(newItems);
  };

  //new row
  const handleAddRow = () => {
    const newItems = [...items];
    newItems.push(defaultItem);
    setItems(newItems);
    //callback
    onChange && onChange(newItems);
  };

  //remove row
  const handleRemoveRow = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    //callback
    onChange && onChange(newItems);
  };

  //item value change
  const handleValueChange = (index: number, keyName: any, keyValue: any) => {
    const newItems = [...items];
    newItems[index][keyName] = keyValue;
    setItems(newItems);
    //callback
    onChange && onChange(newItems);
  };

  /** ================================== RENDER ========================================= */

  //write item info
  const renderItemWrite = (item: TaskSequence, seq: number) => {
    return (
      <>
        <div className="divTableCell">#{seq + 1}</div>
        <div className="divTableCell">
          {/* justify-content-center */}
          <div className="d-flex">
            <Input
              className="wd-200"
              value={item.title}
              onChange={(val: string) => handleValueChange(seq, 'title', val)}
            />
          </div>
        </div>
        <div className="divTableCell">
          {/* justify-content-center */}
          <div className="d-flex">
            <Duration
              value={{ duration: Number(item.duration.time), durationUnit: item.duration.unit }}
              options={DUR_OPTIONS}
              onChange={(val) => {
                const newDuration = {
                  time: val.duration,
                  unit: val.durationUnit,
                };
                handleValueChange(seq, 'duration', newDuration);
              }}
            />
          </div>
        </div>
        <div className="divTableCell">
          {/* justify-content-center */}
          <div className="d-flex ">
            <UserAutoComplete
              className="wd-250"
              value={item.workers.map((v) => ({ id: v.user.id, name: v.user.name }))}
              onChange={(users: any[]) => {
                const newWorkers = users.map((_ele: any) => ({
                  user: { id: _ele.id, name: _ele.name },
                  group: { id: '', name: '' },
                }));
                handleValueChange(seq, 'workers', newWorkers);
              }}
            />
          </div>
        </div>
        <div className="divTableCell">
          <button
            type="button"
            className="btn btn-sm tx-danger"
            onClick={() => handleRemoveRow(seq)}
          >
            <X />
          </button>
        </div>
      </>
    );
  };

  //view item
  const renderItemView = (item: TaskSequence, seq: number) => {
    const durOption = DUR_OPTIONS.find((_ele) => _ele.value === item.duration.unit);
    return (
      <>
        <div className="divTableCell">#{seq + 1}</div>
        <div className="divTableCell">
          <span>{item.title}</span>
        </div>
        <div className="divTableCell">
          <span>{`${item.duration.time} ${durOption?.label || ''}`}</span>
        </div>
        <div className="divTableCell">
          <span>{item.workers.map((_ele: any) => _ele.user.name).join(', ')}</span>
        </div>
      </>
    );
  };

  //// console.log('items', items);
  return (
    <div className={classNames('pos-relative w-100', className)}>
      <div className="table-responsive">
        <div className="divTable minimalist">
          <div className="divTableHeading">
            <div className="divTableRow">
              <div className="divTableHead" style={{ textAlign: 'left' }}>
                Order
              </div>
              <div className="divTableHead" style={{ textAlign: 'left' }}>
                Title
              </div>
              <div className="divTableHead" style={{ textAlign: 'left' }}>
                Duration
              </div>
              <div className="divTableHead" style={{ textAlign: 'left' }}>
                Worker
              </div>
              <div className="divTableHead" style={{ textAlign: 'left' }}></div>
            </div>
          </div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="task-sequence">
              {(provided) => (
                <div className="divTableBody" {...provided.droppableProps} ref={provided.innerRef}>
                  {items.map((_item: any, index: number) => (
                    <Draggable
                      key={index}
                      draggableId={index.toString()}
                      index={index}
                      isDragDisabled={mode === 'r'}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={classNames(
                            'divTableRow',
                            snapshot.isDragging ? 'selected' : 'not-selected',
                          )}
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                        >
                          {mode === 'w' && renderItemWrite(_item, index)}
                          {mode === 'r' && renderItemView(_item, index)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      {mode === 'w' && (
        <Button
          name="Add a line"
          color="link"
          icon="Plus"
          className="mg-t-10"
          onClick={handleAddRow}
        />
      )}
    </div>
  );
};

export default TaskSequenceForm;
