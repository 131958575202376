import * as keyNames from '@activity/config/keyNames';

const myDoneTaskColumn = [
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_SUBJECT,
    languageKey: 'Subject',
    defaultViewInList: true,
    sortable: true,
    name: keyNames.KEY_NAME_ACTIVITY_SUBJECT,
    title: 'Subject',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_TO,
    languageKey: 'Customer',
    defaultViewInList: true,
    sortable: false,
    name: keyNames.KEY_NAME_ACTIVITY_TO,
    title: 'Customer',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_TASK_TYPE,
    languageKey: 'Task Type',
    defaultViewInList: true,
    sortable: false,
    name: keyNames.KEY_NAME_ACTIVITY_TASK_TYPE,
    title: 'Task Type',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_END_TIME,
    languageKey: 'Done Date',
    defaultViewInList: true,
    sortable: true,
    name: keyNames.KEY_NAME_ACTIVITY_END_TIME,
    title: 'Done Date',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_DURATION,
    languageKey: 'Real Duration',
    defaultViewInList: true,
    sortable: true,
    name: keyNames.KEY_NAME_ACTIVITY_DURATION,
    title: 'Real Duration',
  },
];

export default myDoneTaskColumn;
