import React, { useState, Suspense, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceAtom, listPageSettingsAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import SplitView, { EmptySplitViewFC } from '@base/components/split-view';
import ActivityListPage from '@activity/pages/list';
import ActivityViewPage from '@activity/pages/view';
import ComparisonPage from '@activity/pages/comparison';
import { useLanguageByMenu } from '@base/services/i18n';
import { Helmet } from 'react-helmet-async';
import Loading from '@base/components/loading';
import { MENU_ACTIVITY } from '@base/config/menus';
import { ListType } from '@base/types/enums';
import { isUndefined } from 'lodash';

const ActivityFirstPage = () => {
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MENU_ACTIVITY));
  const [{ innerHeight, isDesktop }] = useRecoilState(deviceAtom);
  // const listPageSettings = useRecoilValue(listPageSettingsAtom);
  const [isLoadingSetting, setIsLoadingSetting] = useState(false);
  // load menu language
  useLanguageByMenu([MENU_ACTIVITY]);

  const { isSplitMode } = pageData;
  const setIsSplitMode = (isSplitMode: boolean) => {
    setPageData({
      ...pageData,
      listType: isSplitMode ? ListType.SPLIT : ListType.LIST,
      isSplitMode,
    });
  };

  const splitViewProps = {
    isSplitMode,
    styles: {
      height: innerHeight !== 0 ? innerHeight - 60 : 0,
    },
  };

  const listProps = {
    isSplitMode,
    setIsSplitMode,
  };

  const comparisonProps = {
    isSplitMode: false,
  };

  // useEffect(() => {
  //   if (listPageSettings && listPageSettings[MENU_ACTIVITY]) {
  //     const menuListPage: any = listPageSettings[MENU_ACTIVITY];
  //     const nPageData = {
  //       ...pageData,
  //       isSplitMode: menuListPage?.listType === ListType.SPLIT,
  //       listType: menuListPage?.listType,
  //     };
  //     setPageData(nPageData);
  //   }
  //   if (
  //     listPageSettings &&
  //     (isUndefined(listPageSettings[MENU_ACTIVITY]) || listPageSettings[MENU_ACTIVITY])
  //   ) {
  //     setIsLoadingSetting(false);
  //   }
  // }, [listPageSettings]);

  return (
    <>
      <Helmet>
        <title>Hanbiro CRM - MyWork</title>
      </Helmet>
      {!isLoadingSetting && (
        <Routes>
          <Route path="comparison" element={<ComparisonPage {...comparisonProps} />} />
          <Route
            path=":menu"
            element={
              isSplitMode && isDesktop ? (
                <SplitView
                  {...splitViewProps}
                  leftPane={<ActivityListPage {...listProps} />}
                  rightPane={<EmptySplitViewFC />}
                />
              ) : (
                <ActivityListPage {...listProps} />
              )
            }
          />
          <Route
            path=":menu/:id/*"
            element={
              isSplitMode && isDesktop ? (
                <SplitView
                  {...splitViewProps}
                  leftPane={<ActivityListPage {...listProps} />}
                  rightPane={
                    <div className="pane-content" style={{ flex: 1 }}>
                      <Suspense fallback={<Loading />}>
                        <ActivityViewPage isSplitMode={isSplitMode} />
                      </Suspense>
                    </div>
                  }
                />
              ) : (
                <ActivityViewPage />
              )
            }
          />
          <Route path="/" element={<Navigate replace to="/activity/mywork" />} />
        </Routes>
      )}
    </>
  );
};

export default ActivityFirstPage;
