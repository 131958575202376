import { useCallback } from "react";

interface Cc {
    cc: boolean;
    bcc: boolean;
}

interface showMailCcProps {
  value: Cc
  onChange: (val: Cc) => void; 
}

function ShowMailCc({value, onChange}: showMailCcProps) {
  const onChangeCc = useCallback((source: string) => {
    if (source == 'cc') {
      onChange({...value, cc: !value.cc})
    } else {
      onChange({...value, bcc: !value.bcc})
    }
  }, [value])

  return (
    <div className="d-flex justify-content-end">
      <div className="pointer tx-primary mg-x-5" onClick={() => onChangeCc('cc')}>
        Cc
      </div>
      <div className="pointer tx-primary mg-x-5" onClick={() => onChangeCc('bcc')}>
        Bcc
      </div>
    </div>
  );
}

export default ShowMailCc;
