import { UserAutoComplete } from '@base/config/write-field/components';
import { useMemo } from 'react';
import { UserOrCustomer } from '@activity/types/interfaces';
import _ from 'lodash';

interface FromUserEditProps {
  value: UserOrCustomer[];
  mode?: 'phone' | 'email';
  single?: boolean;
  onChange: (val: UserOrCustomer[]) => void;
}

function FromUserEdit(props: FromUserEditProps) {
  const { value, single = false, onChange, mode = 'none' } = props;

  const items = useMemo(() => {
    return value?.map((v) => ({ ...v, label: v.name, value: v.id }));
  }, [value]);

  const setUser = (item: any) => {
    if (!_.has(item, 'properties')) return item;
    let user: UserOrCustomer = {
      type: 'TYPE_USER',
      id: item.id,
      name: item.name,
      group: item.properties?.crmBaseGroup,
    };
    if (mode == 'email') {
      const email = item.emails?.find((o: any) => o.label?.label == 'LABEL_PRIMARY');
      user.email = email?.email ?? '';
    } else if (mode == 'phone') {
      const phone = item.phones?.find((o: any) => o.label?.label == 'LABEL_PRIMARY');
      user.phone = phone?.phoneNumber ?? '';
    }
    return user;
  };

  const onChangeUser = (val: any | any[]) => {
    if (_.isEmpty(val)) {
      if (single) {
        onChange && onChange([]);
      }
    } else {
      if (single) {
        const user = setUser(val);
        onChange && onChange([user]);
      } else {
        const users = _.isArray(val)
          ? val.map((v) => {
              return setUser(v);
            })
          : [];
        onChange && onChange(users);
      }
    }
  };

  return (
    <div className="form-row">
      <div className="col-12">
        <UserAutoComplete
          single={single}
          value={items}
          onChange={(val: any) => onChangeUser(val)}
        />
      </div>
    </div>
  );
}

export default FromUserEdit;
