import RelatedToContainer from '@activity/components/related-to';
import { KEY_GRAPHQL_ACTIVITY_GET_RELATEDTOS } from '@activity/config/queryKeys';
import { ACTIVITY_GET_RELATEDTOS } from '@activity/services/mywork';
import { RelatedTo } from '@activity/types/interfaces';
import usePost from '@base/hooks/usePost';
import { BaseResponse } from '@base/types/interfaces/response';

import AssignRelatedToMutate from './mutate';

interface AssignRelatedToProps {
  menuSource: string;
  menuSourceId: string;
}

function AssignRelatedTo(props: AssignRelatedToProps) {
  const { menuSourceId } = props;
  const { data } = usePost<BaseResponse<RelatedTo>>(
    [KEY_GRAPHQL_ACTIVITY_GET_RELATEDTOS],
    ACTIVITY_GET_RELATEDTOS,
    {
      id: menuSourceId,
    },
  );

  const { mAddRelatedTo, mDeleteRelatedTo } = AssignRelatedToMutate();

  const onAdd = (relatedTo: RelatedTo) => {
    // console.log(relatedTo);
    const params = {
      relatedTo: relatedTo,
      id: menuSourceId,
    };
    // console.log('add relatedTo', params);
    mAddRelatedTo.mutate(params);
  };

  const onDelete = (id: string) => {
    // console.log(id);
    const params = {
      id: menuSourceId,
      relatedId: id,
    };
    mDeleteRelatedTo.mutate(params);
  };

  //render
  return (
    <>
      <div className="pos-relative">
        <RelatedToContainer
          value={data?.results ?? []}
          onAdd={onAdd}
          onDelete={onDelete}
          mode={'view'}
        />
      </div>
    </>
  );
}

export default AssignRelatedTo;
