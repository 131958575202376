import Icon from '@base/assets/icons/svg-icons';
import { LabelValue, SignatureIndex } from '@base/types/types/app';

export const ACTIVITY_TASK_TYPE_NONE = 'TASK_TYPE_NONE';
export const ACTIVITY_TASK_TYPE_MANUAL = 'TASK_TYPE_MANUAL';
export const ACTIVITY_TASK_TYPE_CHECKLIST = 'TASK_TYPE_CHECKLIST';
export const ACTIVITY_TASK_TYPE_SEQUENCE = 'TASK_TYPE_SEQUENCE';

export const ACTIVITY_STATUS_TODO = 'STATUS_TODO';
export const ACTIVITY_STATUS_DOING = 'STATUS_DOING';
export const ACTIVITY_STATUS_HOLID = 'STATUS_HOLD';
export const ACTIVITY_STATUS_DONE = 'STATUS_DONE';
export const ACTIVITY_STATUS_CANCEL = 'STATUS_CANCEL';

export const ACTIVITY_TYPE_TASK = 'TYPE_TASK';
export const ACTIVITY_TYPE_CALL = 'TYPE_CALL';
export const ACTIVITY_TYPE_MAIL = 'TYPE_MAIL';
export const ACTIVITY_TYPE_SMS = 'TYPE_SMS';
export const ACTIVITY_TYPE_PROCESS = 'TYPE_PROCESS';

export const ACTIVITY_CALL_TYPE_LOG = 'CALL_TYPE_LOG';
export const ACTIVITY_CALL_TYPE_SCHEDULE = 'CALL_TYPE_SCHEDULE';

export const ACTIVITY_DIRECTION_IN = 'DIRECTION_IN';
export const ACTIVITY_DIRECTION_OUT = 'DIRECTION_OUT';

export const ACTIVITY_SEND_TYPE_NOW = 'SEND_TYPE_NOW';
export const ACTIVITY_SEND_TYPE_LATER = 'SEND_TYPE_LATER';

export const ACTIVITY_SMS_TYPE_SMS = 'SMS_TYPE_SMS';
export const ACTIVITY_SMS_TYPE_LMS = 'SMS_TYPE_LMS';

export const ACTIVITY_VIEW_PAGE_SIZE = 15;

interface ActivityIndex {
  [key: string]: any;
}

export const ACTIVITY_MENU_TASK = 'task';
export const ACTIVITY_MENU_CALL = 'call';
export const ACTIVITY_MENU_EMAIL = 'email';
export const ACTIVITY_MENU_SMS = 'sms';
export const ACTIVITY_MENU_TICKET = 'ticket';

export const ACTIVITY_ADD_OPTIONS = {
  task: {
    name: 'Task',
    icon: Icon(ACTIVITY_MENU_TASK),
  },
  call: {
    name: 'Call',
    icon: Icon(ACTIVITY_MENU_CALL),
  },
  email: {
    name: 'Email',
    icon: Icon(ACTIVITY_MENU_EMAIL),
  },
  sms: {
    name: 'Sms',
    icon: Icon(ACTIVITY_MENU_SMS),
  },
};

export const ACTIVITY_ICONS: ActivityIndex = {
  [ACTIVITY_TYPE_TASK]: Icon(ACTIVITY_MENU_TASK),
  [ACTIVITY_TYPE_CALL]: Icon(ACTIVITY_MENU_CALL),
  [ACTIVITY_TYPE_MAIL]: Icon(ACTIVITY_MENU_EMAIL),
  [ACTIVITY_TYPE_SMS]: Icon(ACTIVITY_MENU_SMS),
};

export const ACTIVITY_MENU_KEYS: ActivityIndex = {
  [ACTIVITY_TYPE_TASK]: ACTIVITY_MENU_TASK,
  [ACTIVITY_TYPE_CALL]: ACTIVITY_MENU_CALL,
  [ACTIVITY_TYPE_MAIL]: ACTIVITY_MENU_EMAIL,
  [ACTIVITY_TYPE_SMS]: ACTIVITY_MENU_SMS,
};

export const ACTIVITY_MENU_PROTO: ActivityIndex = {
  [ACTIVITY_MENU_TASK]: ACTIVITY_TYPE_TASK,
  [ACTIVITY_MENU_CALL]: ACTIVITY_TYPE_CALL,
  [ACTIVITY_MENU_EMAIL]: ACTIVITY_TYPE_MAIL,
  [ACTIVITY_MENU_SMS]: ACTIVITY_TYPE_SMS,
};

export const StatusFields = (props: SignatureIndex) => [
  {
    label: props.todo,
    value: ACTIVITY_STATUS_TODO,
  },
  {
    label: props.doing,
    value: ACTIVITY_STATUS_DOING,
  },
  {
    label: props.hold,
    value: ACTIVITY_STATUS_HOLID,
  },
  {
    label: props.done,
    value: ACTIVITY_STATUS_DONE,
  },
  {
    label: props.cancel,
    value: ACTIVITY_STATUS_CANCEL,
  },
];

export const ActivityTypesOptions: LabelValue[] = [
  {
    label: 'Call',
    value: ACTIVITY_TYPE_CALL,
  },
  {
    label: 'Task',
    value: ACTIVITY_TYPE_TASK,
  },
  {
    label: 'Email',
    value: ACTIVITY_TYPE_MAIL,
  },
  {
    label: 'Sms',
    value: ACTIVITY_TYPE_SMS,
  },
];

export const ActivityDirectionOptions: LabelValue[] = [
  {
    label: 'Incoming',
    value: ACTIVITY_DIRECTION_IN,
  },
  {
    label: 'Outgoing',
    value: ACTIVITY_DIRECTION_OUT,
  },
];

export const ActivityTaskTypeOptions: LabelValue[] = [
  {
    label: 'Manual',
    value: ACTIVITY_TASK_TYPE_MANUAL,
  },
  {
    label: 'Checklist',
    value: ACTIVITY_TASK_TYPE_CHECKLIST,
  },
  {
    label: 'Sequence',
    value: ACTIVITY_TASK_TYPE_SEQUENCE,
  },
];

export const ActivityStatusOptions: LabelValue[] = [
  {
    label: 'Todo',
    value: ACTIVITY_STATUS_TODO,
  },
  {
    label: 'Doing',
    value: ACTIVITY_STATUS_DOING,
  },
  {
    label: 'Hold',
    value: ACTIVITY_STATUS_HOLID,
  },
  {
    label: 'Done',
    value: ACTIVITY_STATUS_DONE,
  },
];

export const ActivityCallTypeOptions: LabelValue[] = [
  {
    label: 'Call Log',
    value: ACTIVITY_CALL_TYPE_LOG,
  },
  {
    label: 'Scheduled Call',
    value: ACTIVITY_CALL_TYPE_SCHEDULE,
  },
];

export const ActivitySendTypeOptions: LabelValue[] = [
  {
    label: 'Send Now',
    value: ACTIVITY_SEND_TYPE_NOW,
  },
  {
    label: 'Send Later',
    value: ACTIVITY_SEND_TYPE_LATER,
  },
];

export const ActivitySmsTypeOptions: LabelValue[] = [
  {
    label: 'SMS',
    value: ACTIVITY_SMS_TYPE_SMS,
  },
  {
    label: 'LMS',
    value: ACTIVITY_SMS_TYPE_LMS,
  },
];
