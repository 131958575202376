import { StatusFields } from '@activity/config/constants';
import classnames from 'classnames';
import { ActivityStatus } from '@activity/types/types';

interface StatusProps {
  value: ActivityStatus | undefined;
}

function StatusBar({ value: status }: StatusProps) {
  const statuses = StatusFields({
    todo: 'Todo',
    doing: 'Doing',
    hold: 'on Hold',
    done: 'Done',
    cancel: 'Cancel',
  });
  let done = true;

  return (
    <ul className="task-stage mg-b-15-f">
      {statuses.map((o) => {
        const matched = o.value == status;
        if (matched) done = false;
        return (
          <li
            key={o.value}
            className={classnames('task-stage-item', {
              done: done,
              current: matched,
            })}
          >
            {o.label}
          </li>
        );
      })}
    </ul>
  );
}

export default StatusBar;
