import React from 'react';
import ListTableColumnSetting from '@base/components/list/list-table-column-setting';
import ListHeaderContainer, { IListHeader } from '@base/components/list/list-header';
import { useRecoilState } from 'recoil';
import { MENU_ACTIVITY } from '@base/config/menus';
import { activityComparisonFilterState } from '@activity/recoil/list';
import { KEY_NAME_COMPARISON_USERNAME } from '@activity/config/keyNames';

interface ListHeaderExtendProps extends IListHeader {
  filterComponent?: React.ReactNode;
  category: string;
  columns: any[];
}

const ListHeader = (props: ListHeaderExtendProps) => {
  const { filterComponent, category, columns } = props;

  const [listFilter, setListFilter] = useRecoilState(activityComparisonFilterState);

  const handleColumnSettingChange = (data: any) => {
    const newFilter: any = { ...listFilter };
    newFilter.settingColumns = data;
    setListFilter(newFilter);
  };

  return (
    <ListHeaderContainer
      filterComponent={filterComponent}
      columnSettingComponent={
        <div className="mg-l-auto">
          <ListTableColumnSetting
            menuSource={`${MENU_ACTIVITY}_${category}`}
            columns={columns}
            fixedColumns={[KEY_NAME_COMPARISON_USERNAME]}
            columLimit={10}
            onChange={handleColumnSettingChange}
          />
        </div>
      }
    />
  );
};

export default ListHeader;
