import { activityListQueryAtom } from '@activity/recoil/list';
import { Activity } from '@activity/types/interfaces';
import Loading from '@base/components/loading';
import BaseCalendar from '@base/containers/calendar';
import { ISchedule } from '@base/containers/calendar/calendar';
import usePosts from '@base/hooks/usePosts';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

// import BaseCalendar from '@base/containers/tui-calendar';
// import { ISchedule } from 'tui-calendar';
export type calendarProps = {};

function Calendar({}: calendarProps) {
  // console.log('Calendar layout');
  const listQuery = useRecoilValue(activityListQueryAtom);
  const [currentDate, setCurrentDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  // console.log('currentDate', currentDate);
  const [schedules, setSchedules] = useState<ISchedule[]>([]);
  const { data, isLoading } = usePosts<Activity[]>(
    [
      'activity_activities',
      {
        year: currentDate.substring(0, 4),
        month: currentDate.substring(5, 2),
      },
    ],
    listQuery,
    {
      filter: {
        sort: {
          field: 'createdAt',
          orderBy: 'DESC',
        },
        paging: {
          page: 1,
          size: 2000,
        },
      },
    },
    {
      keepPreviousData: true,
    },
  );

  const onChangeCurrentDate = (date: string) => {
    setCurrentDate(date);
  };

  useEffect(() => {
    if (!data) return;
    const calendar: ISchedule[] = [];
    for (const event of data.data) {
      const schedule = {
        title: event.subject,
        id: event.id,
        start: event.createdAt,
        end: event.endTime,
        editable: false,
        extendedProps: {
          category: event.type,
          source: event.source,
        },
      };
      calendar.push(schedule);
    }
    setSchedules(calendar);
  }, [data]);

  return isLoading ? (
    <Loading />
  ) : (
    <BaseCalendar
      schedules={schedules}
      currentDate={currentDate}
      setCurrentDate={onChangeCurrentDate}
    />
  );
}

export default Calendar;
