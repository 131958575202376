import React from 'react';
import classnames from 'classnames';
import styled from '@emotion/styled';
import { DataSourceSelect } from '@base/containers';

const Container = styled.div``;

type EditProps = {
  value: string[];
  onChange: () => void;
  errors: any;
  onSave: () => void;
};

const Edit = (props: EditProps) => {
  const { value = [], onChange = () => {}, errors = {}, onSave = () => {} } = props;

  // console.log('Purpose Value', value);

  return (
    <Container className={classnames('crm-customer-lead-source-view')}>
      <div
        className={classnames({
          'is-required': errors.isRequired,
        })}
      >
        <DataSourceSelect value={value} sourceKey="call_result" sourceType="field" />
      </div>
      {errors.isRequired && <div className="tx-danger tx-12">{errors.isRequired}</div>}
    </Container>
  );
};

export default Edit;
