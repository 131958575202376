import {
  KEY_GRAPHQL_ACTIVITY_CREATE_ASSIGNTO,
  KEY_GRAPHQL_ACTIVITY_DELETE_ASSIGNTO,
  KEY_GRAPHQL_ACTIVITY_GET_ASSIGNTOS,
} from '@activity/config/queryKeys';
import { ADD_ASSIGNTO, DELETE_ASSIGNTO } from '@activity/services/mywork';
import { UserOrCustomer } from '@activity/types/interfaces';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { toast } from 'react-toastify';

function AssignCustomerMutate() {
  const queryClient = useQueryClient();
  const mAddCustomer: any = useMutationPost<BaseMutationResponse>(
    ADD_ASSIGNTO,
    KEY_GRAPHQL_ACTIVITY_CREATE_ASSIGNTO,
    {
      onMutate: async () => {},
      onSuccess: (data: BaseMutationResponse, variables: any, context: any) => {
        // console.log('mAddCustomer', data);
        const optimistic = variables.assignTo as UserOrCustomer[];
        queryClient.setQueryData([KEY_GRAPHQL_ACTIVITY_GET_ASSIGNTOS], (old: any) => {
          return { results: [...old.results, ...optimistic] };
        });
        toast.success('Created Customer successfully!');
      },
      onError: (data: BaseMutationResponse) => {
        // console.log('mAddCustomer: Error', data);
        toast.success('Failed to add Customer');
      },
    },
  );

  const mDeleteCustomer: any = useMutationPost<BaseMutationResponse>(
    DELETE_ASSIGNTO,
    KEY_GRAPHQL_ACTIVITY_DELETE_ASSIGNTO,
    {
      onMutate: async () => {},
      onSuccess: (data: BaseMutationResponse, variables: any, context: any) => {
        // console.log('mDeleteCustomer', data);
        const optimistic = variables.assignTo as string[];
        queryClient.setQueryData([KEY_GRAPHQL_ACTIVITY_GET_ASSIGNTOS], (old: any) => {
          return {
            results: old.results?.filter((v: UserOrCustomer) => !_.includes(optimistic, v.id)),
          };
        });
        toast.success('Deleted Customer successfully!');
      },
      onError: (data: BaseMutationResponse) => {
        // console.log('mDeleteCustomer: Error', data);
        toast.success('Failed to delete Customer');
      },
    },
  );

  return { mAddCustomer, mDeleteCustomer };
}

export default AssignCustomerMutate;
