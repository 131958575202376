import React from 'react';
import { Calendar } from 'react-feather';
import { Avatar, SpanLang } from '@base/components';
import { FlexGrow } from './styles';
import { Activity } from '@activity/types/interfaces';
import dayjs from 'dayjs';
import { ACTIVITY_ICONS, ACTIVITY_MENU_KEYS } from '@activity/config/constants';
import { Checkbox } from '@base/components/form';
import { NavLink } from 'react-router-dom';

interface ItemProps {
  category: string | undefined;
  data: Activity;
  onCheckedRow?: (params: any) => void;
  checked?: boolean;
};

const Item = ({ data, onCheckedRow, checked, category }: ItemProps) => {
  const TypeIcon = ACTIVITY_ICONS[data.type];
  const type = ACTIVITY_MENU_KEYS[data.type];

  const onChange = (id: string) => {
    onCheckedRow && onCheckedRow(id);
  };

  return (
    <div className="d-flex pd-10 bg-white rounded shadow-sm mg-b-10">
      <Checkbox checked={checked} onChange={() => onChange(data.id)} />
      <FlexGrow>
        <div className="d-flex align-items-center wd-100p">
          <span className="activity-list-icon" data-han-tooltip="Task">
            {TypeIcon}
          </span>
          <NavLink
            to={`/activity/${category}/${type}_${data.id}`}
            className="mg-r-10 link-02 tx-bold text-truncate"
          >
            {data.subject}
          </NavLink>
          <span className="flex-shrink-0 d-inline-block mg-l-auto pd-x-10 pd-t-2 pd-b-4 bg-danger rounded-30 lh-1 tx-white">
            <SpanLang keyLang={data.priority.languageKey} />
          </span>
        </div>
        <div className="d-flex align-items-center wd-100p mg-t-10">
          <span className="d-flex align-items-center tx-13 tx-danger">
            <Calendar size={14} className="mg-r-5" />
            {dayjs(data.createdAt).format('YYYY-MM-DD')}
          </span>
          <span className="avatar avatar-xs mg-l-auto">
            <Avatar userno={'0'} height={30} width={30} />
          </span>
        </div>
      </FlexGrow>
    </div>
  );
};

export default React.memo(Item);
