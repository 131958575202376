import React from 'react';
import { useTranslation } from 'react-i18next';
import TimeAttachment from '@base/containers/attachment/time-attachment';
import DesktopLayout1ViewRight from '@base/layouts/desktop/layout1/view-right';
import AssignCustomer from '@activity/containers/assign-customer';
import AssignProduct from '@activity/containers/assign-product';
import KnowledgeBaseInserted from '@desk/knowledge-base/containers/knowledge-base-inserted';
import { ActivityDirection } from '@activity/types/types';
import AssignTags from '@activity/containers/assign-tags';
import AssignRelatedTo from '@activity/containers/assign-related-to';

interface RightProps {
  menuSourceId: string;
  menuSource: string;
  showFields?: string[];
  activityType: string;
  direction?: ActivityDirection;
}

interface RightConfig {
  title: string;
  key: string;
  component: React.ReactNode;
}

const ViewRight = (props: RightProps) => {
  const { menuSource, menuSourceId, showFields } = props;
  // console.log('showFields', showFields);

  const { t } = useTranslation();
  let rightConfig: RightConfig[] = [];

  if (showFields?.includes('to')) {
    rightConfig.push({
      title: 'Customers',
      key: 'to',
      component: (
        <AssignCustomer placement="left" menuSourceId={menuSourceId} menuSource={menuSource} />
      ),
    });
  }

  if (showFields?.includes('taskKnowledges')) {
    rightConfig.push({
      title: 'KnowledgeBase',
      key: 'knowledge',
      component: <KnowledgeBaseInserted menuSourceId={menuSourceId} menuSource={menuSource} />,
    });
  }

  /*
  if (showFields?.includes('from')) {
    rightConfig.push({
      title: t('common_section_assigned_rep'),
      key: 'assigned',
      component: <AssignRep placement="left" menuSourceId={menuSourceId} />,
    });
  }
  */
  if (showFields?.includes('products')) {
    rightConfig.push({
      title: 'Products',
      key: 'product',
      component: <AssignProduct menuSource={menuSource} menuSourceId={menuSourceId} />,
    });
  }

  if (showFields?.includes('relatedTo')) {
    rightConfig.push({
      title: 'ReletedTo',
      key: 'relatedTo',
      component: <AssignRelatedTo menuSource={menuSource} menuSourceId={menuSourceId} />,
    });
  }

  if (showFields?.includes('tags')) {
    rightConfig.push({
      title: 'Tags',
      key: 'tags',
      component: <AssignTags menuSource={menuSource} menuSourceId={menuSourceId} />,
    });
  }

  if (showFields?.includes('attachments')) {
    rightConfig.push({
      title: t('common_section_attachment'),
      key: 'attachment',
      component: (
        <TimeAttachment menuSource={menuSource} menuSourceId={menuSourceId} listType="list" />
      ),
    });
  }

  return <DesktopLayout1ViewRight rightConfig={rightConfig} />;
};

export default ViewRight;
