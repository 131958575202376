import React from 'react';
import TextView from '@base/containers/view-field/text/view';

type ViewProps = {
  value: string;
};

const View = (props: ViewProps) => {
  const { value } = props;
  return <TextView value={value ?? ''} />;
};

export default View;
