import { lazy } from 'react';

export const FromUserView = lazy(() => import('@activity/components/from-user'));
export const ToCustomerView = lazy(() => import('@activity/components/to-customer'));
export const PurposeView = lazy(() => import('@activity/components/purpose'));
export const CallResultView = lazy(() => import('@activity/components/call-result'));
export const StatusView = lazy(() => import('@activity/components/status'));
// export const TaskChecklistView = lazy(() => import('@activity/containers/checklist-form/view'));
export const TaskChecklistView = lazy(() => import('@activity/containers/task-checklist/view'));
export const TaskSequenceView = lazy(() => import('@activity/containers/task-sequence/view'));
