import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Select } from '@base/components/form';
import { components } from 'react-select';
import _ from 'lodash';
import { ActivityType } from '@activity/types/types';
import { useActivityList } from '@activity/hooks/queries/useActivityList';
import { Activity } from '@activity/types/interfaces';
import Icon from '@base/assets/icons/svg-icons';

interface ActivityAutoCompleteProps {
  outSide?: boolean;
  className?: string;
  placeholder?: string;
  single?: boolean;
  visible?: boolean;
  disabled?: boolean;
  type?: ActivityType; // call, email, sms, task
  value?: any | any[]; // [], initial value
  onChange?: (val: Activity) => void;
  iconIndicator: string;
}

const ActivityAutoComplete = (props: ActivityAutoCompleteProps) => {
  const {
    outSide = false,
    className,
    placeholder,
    single = false,
    disabled = false,
    visible = true,
    value = null,
    type = 'TYPE_TASK',
    onChange,
    iconIndicator,
  } = props;

  // state
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [options, setOptions] = useState<any[]>([]);

  const setSearchTextDebounced = useRef(
    _.debounce((searchText) => setSearchText(searchText), 1000),
  ).current;

  // initial selected
  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
            setSelectedValue(value);
          }
        } else {
          setSelectedValue([]);
        }
      } else {
        //single object
        if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
          setSelectedValue(value);
        }
      }
    } else {
      setSelectedValue(null);
    }
  }, [value]);

  // build params
  const getSearchParams = () => {
    let queryFilters: string[] = [`type:${type}`];

    if (searchText != '') {
      queryFilters.push(`subject:${searchText}`);
    }

    let params = {
      filter: {
        query: queryFilters.join(' '),
        sort: {
          field: 'createdAt',
          orderBy: 2,
        },
        paging: {
          page: 1,
          size: 99,
        },
      },
    };

    return params;
  };

  // convert to select options
  const formatSelectOptions = (results: any) => {
    let newOptions = results?.data?.map((_item: any) => {
      return {
        ..._item,
        label: _item.subject,
        value: _item.id,
      };
    });

    return newOptions;
  };

  const {
    data: results,
    status: searchStatus,
    isLoading,
  } = useActivityList(['id', 'subject'].join('\n'), getSearchParams());

  // console.log('isloading', isLoading, 'status', searchStatus)

  useEffect(() => {
    if (results) {
      let newOptions = formatSelectOptions(results);
      setOptions(newOptions);
    } else {
      setOptions([]);
    }
  }, [results]);

  //input text change
  const handleInputChange = (inputText: string, event: any) => {
    // prevent outside click from resetting inputText to ""
    if (event.action !== 'input-blur' && event.action !== 'menu-close') {
      setInputText(inputText);
      setSearchTextDebounced(inputText);
    }
  };

  //value change
  const handleSelectChange = (newValue: any) => {
    let newItem = newValue;
    if (visible) {
      if (!single) {
        //just apply for multi
        const allIdx = newItem?.findIndex((ele: any) => ele.value === 'all');
        if (allIdx > -1) {
          newItem = [newItem[allIdx]];
        }
        setSelectedValue(newItem);
      } else {
        setSelectedValue(newItem);
      }
    }
    //callback
    onChange && onChange(newItem);
  };

  //select custom option
  const CustomOption = ({ children, ...props }: any) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <>
          <div
            className={classNames('', { 'font-weight-bold pd-b-0 mg-b-0': data?.value === 'all' })}
          >
            {children}
          </div>
        </>
      </components.Option>
    );
  };

  //render
  return (
    <div className={classNames('pos-relative', className)} style={{ minWidth: '250px' }}>
      <Select
        isMulti={!single}
        outSide={outSide}
        isDisabled={disabled}
        noOptionsMessage={() => 'No found.'}
        placeholder={
          <div className="react-select-placeholder">
            {placeholder || 'Type or click to select an item'}
          </div>
        }
        iconIndicator={<div className="icons-size-1">{Icon(iconIndicator)}</div>}
        isClearable
        components={{ Option: CustomOption }}
        isLoading={searchStatus === 'loading' || isLoading}
        inputValue={inputText}
        onInputChange={handleInputChange}
        value={selectedValue}
        options={options}
        onChange={handleSelectChange}
      />
    </div>
  );
};

export default ActivityAutoComplete;
