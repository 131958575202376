import React, {useEffect, useRef, useState} from "react";
import {Check, ChevronDown, ChevronRight, X} from "react-feather";
import classNames from "classnames";
import DateRangePicKer from "@base/components/date-range-picker";
import {EDateRangeType} from "@base/types/enums";
import {dateRangeIncludeCustomOptions} from "@base/config/options";
import dayjs from "dayjs";
import {
  clickOutSide,
  generateUUID,
  getDateRangeStringFromKey
} from "@base/utils/helpers";
import {isFunction} from "lodash";

const tmpStyle = {
  backgroundColor: '#fff',
  border: '1px solid #c0ccda',
  padding: '1px 8px',
  color: '#1b2e4b',
  borderRadius: '0.25rem'
};

interface PeriodFilterProps {
  label?: string;
  options: any[];
  onChange?: (v: any) => void;
  defaultSelected?: string;
}

const PeriodFilter = (props: PeriodFilterProps) => {
  const componentIdRef = useRef<string>('dropdown-menu-' + generateUUID());

  const {label = '', options = [], onChange, defaultSelected = ''} = props;

  const [isDroppedDown, setIsDroppedDown] = useState<boolean>(false);

  const [type, setType] = useState(0);

  const [selected, setSelected] = useState<string>(defaultSelected);

  const [selectedValue, setSelectedValue] = useState<any>(null);

  const SelectedTag = () => {
    const isCustomize = selected === EDateRangeType.DATE_RANGE_CUSTOM;

    let value = '';
    if(isCustomize){
      value = [
        dayjs(selectedValue.startDate).format('YYYY/MM/DD'),
        dayjs(selectedValue.endDate).format('YYYY/MM/DD')
      ].join(' ~ ');
    } else {
      value = dateRangeIncludeCustomOptions?.[selected] ?? '(None)';
    }

    return (
      <>
        {
          selected && <div className="d-flex align-items-center" style={tmpStyle}>
            <span className="tx-12">{value}</span>
            {
              selected !== defaultSelected && <a href="#" className="mg-l-3 text-danger tx-bold" onClick={() => setSelected(defaultSelected)}>
                <X size={10}/>
              </a>
            }
          </div>
        }
        {
          !selected && <span className="tx-12 font-italic">(None)</span>
        }
      </>
    );
  };

  const handleDateRangeOnChange = (range: any) => {
    if (!!range) {
      setSelected(EDateRangeType.DATE_RANGE_CUSTOM);
      const {startDate, endDate} = range;
      setSelectedValue({
        startDate: dayjs(startDate).utc().startOf('day').toISOString(),
        endDate: dayjs(endDate).utc().endOf('day').toISOString()
      })
    }
  }

  useEffect(() => {
    if(!selected){
      setSelectedValue(null);
    }
    else if(selected !== EDateRangeType.DATE_RANGE_CUSTOM){
      const {startDate, endDate} = getDateRangeStringFromKey(selected);
      setSelectedValue({
        startDate: dayjs(startDate).utc().startOf('day').toISOString(),
        endDate: dayjs(endDate).utc().endOf('day').toISOString()
      });
    }
  }, [selected]);

  useEffect(() => {
    onChange && onChange(selectedValue)
  }, [selectedValue]);

  const dropdownMenuId = componentIdRef?.current ?? 'dropdown-menu';
  let outside: any = null;
  useEffect(() => {
    if (isDroppedDown) {
      if(!outside){
        outside = clickOutSide(dropdownMenuId, () => {
          setIsDroppedDown(false);
        }, (target: any) => {
          return !target?.closest('button.btn-filter')?.length;
        });
      }
    }
    else {
      !!outside && isFunction(outside) && outside();
      outside = null;
    }
    return () => {
      !!outside && isFunction(outside) && outside();
      outside = null;
    }
  }, [isDroppedDown]);

  return <div className="dropdown list-filter-item d-flex align-items-center">
    <div className="d-flex align-items-center">
      <span className="pd-r-5">{label}</span>
      <SelectedTag />
      <button type="button" className="btn btn-filter pd-l-0" onClick={() => setIsDroppedDown(!isDroppedDown)}>
        <ChevronDown className="mg-l-5" />
      </button>
    </div>
    <div id={dropdownMenuId} className={classNames('dropdown-menu wd-300 pd-10', {'show': isDroppedDown})}>
      <div>
        <button
          type="button"
          data-toggle="collapse"
          className={classNames('btn d-flex wd-100p align-items-center tx-12 tx-semibold pd-x-5', {
            collapsed: type !== 0
          })}
          onClick={() => setType(0)}
        >
          <span className="mg-r-5">Options:</span>
          {type === 0 ? <ChevronDown className="mg-l-auto" /> : <ChevronRight className="mg-l-auto" />}
        </button>
        <div className={classNames('pd-x-5 collapse', {show: type === 0})}>
          {options.filter((v: any) => v.value !== EDateRangeType.DATE_RANGE_CUSTOM).map((v: any, i: number) => {
            const isSelected = v.value === selected
            return (
              <button className={classNames('dropdown-item pd-x-5 tx-14', {'d-flex': isSelected})} type="button" key={i} onClick={() => setSelected(v.value)}>
                {v.label}
                {
                  v.value === selected && <span className="mg-l-auto tx-success">
                    <Check size={14}/>
                  </span>
                }
              </button>
            );
          })}
        </div>
      </div>
      <div className="dropdown-divider" />
      <div>
        <button
          type="button"
          data-toggle="collapse"
          className={classNames('btn d-flex wd-100p align-items-center tx-12 tx-semibold pd-x-5', {
            collapsed: type !== 1
          })}
          onClick={() => setType(1)}
        >
          <span className="mg-r-5">Customize:</span>
          {type === 1 ? <ChevronDown className="mg-l-auto" /> : <ChevronRight className="mg-l-auto" />}
        </button>
        <div className={classNames('pd-x-5 collapse', {show: type === 1})}>
          <DateRangePicKer
            className="wd-100p ht-40"
            value={[]}
            onChange={handleDateRangeOnChange}
            disabled={false}
            willConvertLocalTime={false}
          />
        </div>
      </div>
    </div>
  </div>
};

export default PeriodFilter;