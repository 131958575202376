import React from 'react';
import CommonViewField from '@base/containers/view-field/common';

import View from './view';
import Edit from './edit';

const PurposeViewField = (props: any) => {
  // console.log('Purpose ViewFields', props);
  return <CommonViewField {...props} componentView={View} componentEdit={Edit} />;
};

export default PurposeViewField;
