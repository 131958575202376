import { KEY_GRAPHQL_ACTIVITY_GET_ASSIGNTOS } from '@activity/config/queryKeys';
import { ACTIVITY_GET_ASSIGNTOS } from '@activity/services/mywork';
import { UserOrCustomer } from '@activity/types/interfaces';
import { AssignCustomerContainer } from '@base/containers';
import usePost from '@base/hooks/usePost';
import { BaseResponse } from '@base/types/interfaces/response';
import { IShortCustomer } from '@customer/customer/types/interface/customer';
import _ from 'lodash';

import AssignCustomerMutate from './mutate';

interface AssignCustomerProps {
  placement?: string;
  menuSource: string;
  menuSourceId: string;
  menuSourceName?: string;
  menuTab?: string;
  menuType?: string;
}

function AssignCustomer(props: AssignCustomerProps) {
  const { placement, menuSource, menuSourceId, menuSourceName, menuTab, menuType } = props;
  const { data, isLoading } = usePost<BaseResponse<IShortCustomer>>(
    [KEY_GRAPHQL_ACTIVITY_GET_ASSIGNTOS],
    ACTIVITY_GET_ASSIGNTOS,
    {
      id: menuSourceId,
    },
  );

  const { mAddCustomer, mDeleteCustomer } = AssignCustomerMutate();

  const onAssignTo = (item: any) => {
    if (_.isEmpty(item)) return;

    const is = data?.results?.findIndex((v) => v.id == item.id) != -1;
    if (is) return;

    let assignTo: UserOrCustomer = {
      type: item.category == 'CATEGORY_ACCOUNT' ? 'TYPE_ACCOUNT' : 'TYPE_CONTACT',
      id: item.id,
      name: item.name,
    };

    const params = {
      assignTo: [assignTo],
      type: 'ASSIGN_TO_TYPE_TO',
      id: menuSourceId,
    };
    // console.log('add customer', params);
    mAddCustomer.mutate(params);
  };

  const onDeleteAssignTo = (item: any) => {
    //remove
    const params = {
      id: menuSourceId,
      type: 'ASSIGN_TO_TYPE_TO',
      assignTo: [item.id],
    };
    mDeleteCustomer.mutate(params);
  };

  return (
    <AssignCustomerContainer
      items={data?.results || []}
      placement={placement}
      isLoading={isLoading}
      menuSource={menuSource}
      menuSourceId={menuSourceId}
      menuSourceName={menuSourceName}
      menuTab={menuTab}
      menuType={menuType}
      onAssign={onAssignTo}
      onDelete={onDeleteAssignTo}
      sendMail={true}
      sendSms={true}
    />
  );
}

AssignCustomer.defaultProps = {
  placement: 'right',
  menuSource: '',
  menuSourceId: '',
  menuSourceName: '',
  menuTab: 'activity', //on url
  menuType: '',
};

export default AssignCustomer;
