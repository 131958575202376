import React, {useMemo} from 'react';
import { useNavigate } from 'react-router-dom';
import {MENU_ACTIVITY} from '@base/config/menus';
import ListToolbarContainer from '@base/containers/list/toolbar';
import {ACTIVITY_MENU} from "@base/config/route-menus";

const PageToolbar: React.FC<any> = (props) => {
  const { category, refetch } = props;
  const navigate = useNavigate();

  const allCategories = useMemo(() => {
    const menus: { [index: string]: string } = {};
    for (const menu of ACTIVITY_MENU) {
      menus[menu.value] = menu.label;
    }
    return menus;
  }, []);

  const moreActions = {
    actions: {
      export: {
        label: 'Export',
        icon: 'Download',
      },
    },
    onSelect: (key: string) => {},
  };

  return (
    <>
      <ListToolbarContainer
        onRefresh={refetch}
        categoryOptions={allCategories}
        categorySelected={category}
        onCategoryChange={(category: string) => navigate(`/${MENU_ACTIVITY}/${category}`)}
        menu={MENU_ACTIVITY}
        actionsProps={moreActions}
      />
    </>
  );
};

export default PageToolbar;
