import { lazy } from 'react';

const ActivityFirst = lazy(() => import('./main'));

export default [
  {
    path: '/activity/*',
    component: ActivityFirst,
  },
];
