import {MENU_ACTIVITY} from "@base/config/menus";
import {UserGroupSelect} from "@base/containers";
import {dateRangeIncludeCustomOptions} from "@base/config/options";
import {keys} from "lodash";

export const CATEGORY_COMPARISON = 'comparison';
export const MENU_CATEGORY_COMPARISON = `${MENU_ACTIVITY}_${CATEGORY_COMPARISON}`;

export const filterOptions = [
  {
    label: 'Groups',
    value: 'groupIds',
    component: UserGroupSelect,
    getValue: (value: any) => {
      return value?.length > 0 ? value?.map((v: any) => v?.id).join(',') : '';
    },
    setValue: (value: string) => {
      return value ? value.split(',') : [];
    },
  },
];

export const periodOptions = keys(dateRangeIncludeCustomOptions).map((k) => {
  return {
    label: dateRangeIncludeCustomOptions[k],
    value: k
  };
});