import React, { useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import { TaskSequence } from '@activity/types/interfaces';
import SequenceItem from './item';
import { Button } from '@base/components/form';
import { useRecoilCallback, useRecoilState, useResetRecoilState } from 'recoil';
import { sequenceAtom } from '@activity/recoil/write/atom';
import { DurationOptions } from '@base/utils/helpers/date-utils';

export const DUR_OPTIONS = _.cloneDeep(DurationOptions).splice(1, 4);

interface TaskSequenceContainerProps {
  menuSourceId?: string;
  mode?: 'write' | 'view';
  className?: string;
  value?: TaskSequence[];
  onChange?: (val: TaskSequence[]) => void;
}

const TaskSequenceContainer = (props: TaskSequenceContainerProps) => {
  // console.log('tasksequence', props);
  const { menuSourceId, mode = 'write', className, value, onChange } = props;
  const resetSequence = useResetRecoilState(sequenceAtom);
  const [sequence, setSequence] = useRecoilState(sequenceAtom);

  // https://react.vlpt.us/basic/16-useEffect.html
  useEffect(() => {
    if (value) {
      if (_.isArray(value) && JSON.stringify(value) !== JSON.stringify(sequence)) {
        setSequence(value);
      }
    }
  }, [value]);

  useEffect(() => {
    return () => {
      // console.log('컴포넌트 사라짐');
      resetSequence();
    };
  }, []);

  const handleAdd = useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const items = snapshot.getLoadable(sequenceAtom).contents as TaskSequence[];
        // if there is newFlag: true in items, It can't add new one.
        if (mode == 'view' && items.findIndex((v) => v.newFlag) != -1) return;
        let newItem: TaskSequence = {
          id: uuidv4(),
          // seq: items.length - 1,
          title: '',
          description: '',
          instruction: '',
          duration: {
            time: 1,
            unit: 'UNIT_DAY',
          },
          workers: [],
        };

        if (mode == 'view') {
          newItem.newFlag = true;
        }
        const newItems = [...items, newItem];
        set(sequenceAtom, newItems);
        onChange && onChange(newItems);
      },
    [],
  );

  const handleEnd = useRecoilCallback(
    ({ set }) =>
      (result: any) => {
        if (!result.destination) return;
        const items = _.clone(sequence);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        set(sequenceAtom, items);
        onChange && onChange(items);
      },
    [sequence],
  );

  return (
    <div className={classNames('pos-relative w-100', className)}>
      <div className="table-responsive">
        <div className="divTable minimalist">
          <div className="divTableHeading">
            <div className="divTableRow">
              <div className="divTableHead">Title</div>
              <div className="divTableHead">Duration</div>
              <div className="divTableHead">Worker</div>
              <div className="divTableHead"></div>
            </div>
          </div>
          <DragDropContext onDragEnd={handleEnd}>
            <Droppable droppableId="sequence">
              {(provided) => (
                <div className="divTableBody" {...provided.droppableProps} ref={provided.innerRef}>
                  {sequence.map((item, index) => {
                    return (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            className={classNames(
                              'divTableRow',
                              snapshot.isDragging ? 'selected' : 'not-selected',
                            )}
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                          >
                            <SequenceItem
                              item={item}
                              mode={mode}
                              seq={index}
                              menuSourceId={menuSourceId}
                              onChange={onChange}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <Button name="Add a line" color="link" icon="Plus" className="mg-t-10" onClick={handleAdd} />
    </div>
  );
};

export default TaskSequenceContainer;
