import {MENU_ANALYTIC} from '@base/config/menus';
import { useRecoilValue } from 'recoil';
import { FilterInput } from '@base/types/interfaces/common';
import { buildListSchema } from '@base/utils/helpers/schema';
import {generateFilterQuery, keyStringify} from '@base/utils/helpers/list-page-utils';
import {comparisonFieldsConfig} from "@activity/config/view-field";
import {activityComparisonFilterState} from "@activity/recoil/list";
import {Comparison, ComparisonsResponse} from "@activity/types/interfaces";
import usePost from "@base/hooks/usePost";
import {gql} from "graphql-request";

const getListQuery = (menu: string, schemas: string) => {
    return gql`
        query q($filter: SearchFilter) {
            ${menu}(filter: $filter) {
                lastTotal {
                    ${schemas}
                }
                results {
                    user {
                        id
                        name
                    }
                    total {
                        ${schemas}
                    }
                    lastTotal {
                        ${schemas}
                    }
                }
                total {
                    ${schemas}
                }
            }
        }
    `;
};

export const useComparisons = (fields: any[]) => {
  const menu = `${MENU_ANALYTIC}_activityComparisons`;

  const { filter } = useRecoilValue(activityComparisonFilterState);

  let strQuery = '';
  strQuery += ' ' + generateFilterQuery(filter.query);
  let filtersQuery: FilterInput = {
    sort: filter?.sort,
    query: strQuery
  };

  let listQuerySchema = '';
  if (fields && fields.length > 0) {
    listQuerySchema = buildListSchema({ fields, configFields: comparisonFieldsConfig, ignore: ['id', 'user'] });
  }

  const queryString = getListQuery(menu, listQuerySchema);

  let params = {
    filter: filtersQuery,
  };

  const fieldQueryKey = fields.map((_ele: any) => _ele.keyName).join(',');

  const usePostResult = usePost<ComparisonsResponse<Comparison>>(
    [menu, keyStringify(filtersQuery, ''), fieldQueryKey],
    queryString,
    params,
    {
      //keepPreviousData: true,
      enabled: fields.length > 0,
    },
  );

  return usePostResult;
};
