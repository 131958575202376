import React, { VFC } from 'react';

interface DesktopLayout1List {
  toolbar?: React.ReactNode;
  header?: React.ReactNode;
  body: React.ReactNode | React.VFC;
}

const DesktopLayout1List = ({ toolbar, header, body }: DesktopLayout1List) => {
  return (
    <>
      {toolbar}
      {header}
      {body}
    </>
  );
};

export default DesktopLayout1List;
