import usePosts from '@base/hooks/usePosts';
import { keyStringify } from '@base/utils/helpers';
import { Activity } from '@activity/types/interfaces';
import { getListQuery } from '@activity/services/mywork';
import { KEY_GRAPHQL_ACTIVITY_LIST_ACTIVITY } from '@activity/config/queryKeys';

export const useActivityList = (schema: string, params: any, opts?: any) => {
  const queryKey = KEY_GRAPHQL_ACTIVITY_LIST_ACTIVITY;
  const result = usePosts<Activity[]>(
    [queryKey, keyStringify(params?.filter, '')],
    getListQuery(schema),
    params,
    opts,
  );

  return result;
};
