import React from 'react';
import { useRecoilValue } from 'recoil';
import { deviceAtom } from '@base/recoil/atoms/app';
import displayLayoutConfig from '@activity/config/display-layout';
import BaseLayoutDisplay from '@base/containers/base-layout-display';

const WritePage = (props: any) => {
  const deviceState = useRecoilValue(deviceAtom);

  return (
    <BaseLayoutDisplay
      displayConfig={displayLayoutConfig}
      device={deviceState.device}
      layout={deviceState.layout}
      form={'write'}
      {...props}
    />
  );
};

export default WritePage;
