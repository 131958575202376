import {
  KEY_GRAPHQL_ACTIVITY_CREATE_PRODUCT,
  KEY_GRAPHQL_ACTIVITY_DELETE_PRODUCT,
  KEY_GRAPHQL_ACTIVITY_GET_PRODUCTS,
} from '@activity/config/queryKeys';
import { ADD_PRODUCT, DELETE_PRODUCT } from '@activity/services/mywork';
import useMutationPost from '@base/hooks/useMutationPost';
import { IdName } from '@base/types/interfaces/common';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { ShortProduct } from '@product/product/types/interfaces/product-interface';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

function AssignProductMutate() {
  const queryClient = useQueryClient();
  const mAddProduct: any = useMutationPost<BaseMutationResponse>(
    ADD_PRODUCT,
    KEY_GRAPHQL_ACTIVITY_CREATE_PRODUCT,
    {
      onMutate: async () => {},
      onSuccess: (data: BaseMutationResponse, variables: any, context: any) => {
        // console.log('mAddProduct', data);
        const optimistic = variables.product as IdName;
        queryClient.setQueryData([KEY_GRAPHQL_ACTIVITY_GET_PRODUCTS], (old: any) => {
          return { results: [...old.results, optimistic] };
        });
        toast.success('Created Product successfully!');
      },
      onError: (data: BaseMutationResponse) => {
        // console.log('mAddProduct: Error', data);
        toast.success('Failed to add Product');
      },
    },
  );

  const mDeleteProduct: any = useMutationPost<BaseMutationResponse>(
    DELETE_PRODUCT,
    KEY_GRAPHQL_ACTIVITY_DELETE_PRODUCT,
    {
      onMutate: async () => {},
      onSuccess: (data: BaseMutationResponse, variables: any, context: any) => {
        // console.log('mDeleteProduct', data);
        const optimistic = variables.refId as string;
        queryClient.setQueryData([KEY_GRAPHQL_ACTIVITY_GET_PRODUCTS], (old: any) => {
          return {
            results: old.results?.filter((v: ShortProduct) => v.id != optimistic),
          };
        });
        toast.success('Deleted Product successfully!');
      },
      onError: (data: BaseMutationResponse) => {
        // console.log('mDeleteProduct: Error', data);
        toast.success('Failed to delete Product');
      },
    },
  );

  return { mAddProduct, mDeleteProduct };
}

export default AssignProductMutate;
