import { KEY_GRAPHQL_ACTIVITY_GET_PRODUCTS } from '@activity/config/queryKeys';
import { ACTIVITY_GET_PRODUCTS } from '@activity/services/mywork';
import AssignProductContainer from '@base/containers/assign-product-container';
import usePost from '@base/hooks/usePost';
import { BaseResponse } from '@base/types/interfaces/response';
import { ShortProduct } from '@product/product/types/interfaces/product-interface';
import _ from 'lodash';

import AssignProductMutate from './mutate';

interface AssignProductProps {
  menuSource?: string;
  menuSourceId: string;
  placement?: string;
}

function AssignProduct(props: AssignProductProps) {
  const { placement, menuSourceId } = props;
  const { data, isLoading } = usePost<BaseResponse<ShortProduct>>(
    [KEY_GRAPHQL_ACTIVITY_GET_PRODUCTS],
    ACTIVITY_GET_PRODUCTS,
    {
      id: menuSourceId,
    },
  );
  // console.log('get products', data);

  const { mAddProduct, mDeleteProduct } = AssignProductMutate();

  const onAssignProduct = (item: any) => {
    if (_.isEmpty(item)) return;

    const is = data?.results?.findIndex((v) => v.id == item.id) != -1;
    if (is) return;

    const params = {
      product: {
        id: item.id,
        name: item.name,
      },
      id: menuSourceId,
    };
    // console.log('add product', params);
    mAddProduct.mutate(params);
  };

  const onDeleteProduct = (item: any) => {
    const params = {
      id: menuSourceId,
      refId: item.id,
    };
    mDeleteProduct.mutate(params);
  };

  return (
    <AssignProductContainer
      items={data?.results || []}
      placement={placement}
      isLoading={isLoading}
      onAssign={onAssignProduct}
      onDelete={onDeleteProduct}
    />
  );
}

AssignProduct.defaultProps = {
  placement: 'right',
  menuSourceId: '',
};

export default AssignProduct;
