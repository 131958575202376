import { IdLanguageKey } from './../../../base/types/interfaces/common';
import * as keyNames from '@activity/config/keyNames';
import {
  ActivityCallTypeOptions,
  ActivityStatusOptions,
  ActivityTaskTypeOptions,
} from '@activity/config/constants';
import { LabelValue } from '@base/types/types/app';
import {
  SelectBox,
  Lookup,
  DurationRange,
  CustomerAutoComplete,
  UserAutoComplete,
  ProductAutoComplete,
} from '@base/config/write-field/components';
import { ActivityTypesOptions, ActivityDirectionOptions } from '@activity/config/constants';
import { DurationOptions } from '@base/utils/helpers/date-utils';
import { useSelectionFields } from '@settings/general-setting/services/format-service';
import _ from 'lodash';

export const groupByOptions: LabelValue[] = [
  { label: 'All Activities', value: 'all' },
  { label: 'My Activities', value: 'my' },
  { label: 'My Overdue', value: 'my_overdue' },
  { label: 'My Group Activities', value: 'my_group' },
  { label: 'All Calls', value: 'all_call', extra: 'TYPE_CALL' },
  { label: 'All Missed Call', value: 'all_missed_call', extra: 'TYPE_CALL' },
  { label: 'All Outgoing Call', value: 'all_outgoing_call', extra: 'TYPE_CALL' },
  { label: 'All Incoming Call', value: 'all_incoming_call', extra: 'TYPE_CALL' },
  { label: 'All Emails', value: 'all_emails', extra: 'TYPE_MAIL' },
  { label: 'All Scheduled Emails', value: 'all_scheduled_emails', extra: 'TYPE_MAIL' },
  { label: 'All Sent Emails', value: 'all_sent_emails', extra: 'TYPE_MAIL' },
  { label: 'All Received Emails', value: 'all_received_emails', extra: 'TYPE_MAIL' },
  { label: 'All SMS', value: 'all_sms', extra: 'TYPE_SMS' },
  { label: 'All Scheduled SMS', value: 'all_scheduled_sms', extra: 'TYPE_SMS' },
  { label: 'All Tasks', value: 'all_tasks', extra: 'TYPE_TASK' },
  { label: 'All Overdue Tasks', value: 'all_overdue_tasks', extra: 'TYPE_TASK' },
  { label: 'All Todo Tasks', value: 'all_todo_tasks', extra: 'TYPE_TASK' },
  { label: 'All Done Tasks', value: 'all_done_tasks', extra: 'TYPE_TASK' },
  { label: 'My Calls', value: 'my_calls', extra: 'TYPE_CALL' },
  { label: 'My Missed Call', value: 'my_missed_call', extra: 'TYPE_CALL' },
  { label: 'My Outgoing Call', value: 'my_outgoing_call', extra: 'TYPE_CALL' },
  { label: 'My Incoming Call', value: 'my_incoming_call', extra: 'TYPE_CALL' },
  { label: 'My Emails', value: 'my_emails', extra: 'TYPE_MAIL' },
  { label: 'My Scheduled Emails', value: 'my_scheduled_emails', extra: 'TYPE_MAIL' },
  { label: 'My Sent Emails', value: 'my_sent_emails', extra: 'TYPE_MAIL' },
  { label: 'My Received Emails', value: 'my_received_emails', extra: 'TYPE_MAIL' },
  { label: 'My SMS', value: 'my_sms', extra: 'TYPE_SMS' },
  { label: 'My Scheduled SMS', value: 'my_scheduled_sms', extra: 'TYPE_SMS' },
  { label: 'My Tasks', value: 'my_tasks', extra: 'TYPE_TASK' },
  { label: 'My Overdue Tasks', value: 'my_overdue_tasks', extra: 'TYPE_TASK' },
  { label: 'My Todo Tasks', value: 'my_todo_tasks', extra: 'TYPE_TASK' },
  { label: 'My Done Tasks', value: 'my_done_tasks', extra: 'TYPE_TASK' },
];

export const dateByOptions: LabelValue[] = [
  { label: 'Created Date', value: keyNames.KEY_NAME_ACTIVITY_CREATED_AT },
  { label: 'Activity Date', value: keyNames.KEY_NAME_ACTIVITY_DATE },
  { label: 'Due Date', value: keyNames.KEY_NAME_ACTIVITY_DUE_DATE },
  { label: 'Updated Date', value: keyNames.KEY_NAME_ACTIVITY_UPDATED_AT },
];

export const filterByOptions = [
  {
    label: 'Activity Type',
    value: keyNames.KEY_NAME_ACTIVITY_TYPE,
    component: SelectBox,
    componentProps: {
      options: ActivityTypesOptions,
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    getValue: (val: LabelValue) => {
      return val?.value;
    },
    setValue: (val: string) => {
      return ActivityTypesOptions.find((e) => e.value == val);
    },
  },
  {
    label: 'Customer',
    value: keyNames.KEY_NAME_ACTIVITY_TO,
    component: CustomerAutoComplete,
    componentProps: {
      //single: true,
      type: 'account',
    },
    getValue: (value: any) => {
      return value?.length > 0 ? value?.map((v: any) => v?.id).join(',') : '';
    },
    setValue: (value: string) => {
      return value ? value.split(',') : [];
    },
  },
  {
    label: 'Assigned Rep',
    value: keyNames.KEY_NAME_ACTIVITY_FROM,
    component: UserAutoComplete,
    getValue: (value: any) => {
      return value?.length > 0 ? value?.map((v: any) => v?.id).join(',') : '';
    },
    setValue: (value: string) => {
      return value ? value.split(',') : [];
    },
  },
  {
    label: 'Owner',
    value: keyNames.KEY_NAME_ACTIVITY_CREATED_BY,
    component: UserAutoComplete,
    getValue: (value: any) => {
      return value?.length > 0 ? value?.map((v: any) => v?.id).join(',') : '';
    },
    setValue: (value: string) => {
      return value ? value.split(',') : [];
    },
  },
  {
    label: 'Purpose',
    value: keyNames.KEY_NAME_ACTIVITY_PURPOSE,
    component: Lookup,
    componentProps: {
      fetchList: useSelectionFields,
      fieldLabel: 'languageKey',
      fieldValue: 'id',
      extraParams: { filter: { query: 'keyRoot=activity_purpose' } },
      isMultiple: true,
      useDefault: false,
    },
    getValue: (value: IdLanguageKey[]) => {
      return value?.map((v: any) => v?.id).join(',');
    },
  },
  {
    label: 'Priority',
    value: keyNames.KEY_NAME_ACTIVITY_PRIORITY,
    component: Lookup,
    componentProps: {
      fetchList: useSelectionFields,
      fieldLabel: 'languageKey',
      fieldValue: 'id',
      extraParams: { filter: { query: 'keyRoot=priority' } },
      isMultiple: true,
      useDefault: false,
    },
    getValue: (value: IdLanguageKey[]) => {
      return value?.map((v: any) => v?.id).join(',');
    },
  },
  {
    label: 'Duration',
    value: keyNames.KEY_NAME_ACTIVITY_DURATION,
    component: DurationRange,
    componentProps: {
      options: _.clone(DurationOptions).splice(0, 4),
    },
  },
  {
    label: 'Direction',
    value: keyNames.KEY_NAME_ACTIVITY_DIRECTION,
    component: SelectBox,
    componentProps: {
      options: ActivityDirectionOptions,
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    getValue: (val: LabelValue) => {
      return val?.value;
    },
    setValue: (val: string) => {
      return ActivityDirectionOptions.find((e) => e.value == val);
    },
  },
  {
    label: 'Expected Duration',
    value: keyNames.KEY_NAME_ACTIVITY_EDURATION,
    components: DurationRange,
    componentProps: {
      options: _.clone(DurationOptions).splice(0, 4),
    },
  },
  {
    label: 'Call Type',
    value: keyNames.KEY_NAME_ACTIVITY_CALL_TYPE,
    component: SelectBox,
    componentProps: {
      options: ActivityCallTypeOptions,
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    getValue: (val: LabelValue) => {
      return val?.value;
    },
    setValue: (val: string) => {
      return ActivityCallTypeOptions.find((e) => e.value == val);
    },
  },
  {
    label: 'Task Type',
    value: keyNames.KEY_NAME_ACTIVITY_TASK_TYPE,
    component: SelectBox,
    componentProps: {
      options: ActivityTaskTypeOptions,
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    getValue: (val: LabelValue) => {
      return val?.value;
    },
    setValue: (val: string) => {
      return ActivityTaskTypeOptions.find((e) => e.value == val);
    },
  },
  {
    label: 'Status',
    value: keyNames.KEY_NAME_ACTIVITY_STATUS,
    component: SelectBox,
    componentProps: {
      options: ActivityStatusOptions,
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    getValue: (val: LabelValue) => {
      return val?.value;
    },
    setValue: (val: string) => {
      return ActivityStatusOptions.find((e) => e.value == val);
    },
  },
  {
    label: 'Related Product',
    value: keyNames.KEY_NAME_ACTIVITY_PRODUCTS,
    component: ProductAutoComplete,
    getValue: (value: any) => {
      return value?.length > 0 ? value?.map((v: any) => v?.id).join(',') : '';
    },
    setValue: (value: string) => {
      return value ? value.split(',') : [];
    },
  },
  {
    label: 'Call Result',
    value: keyNames.KEY_NAME_ACTIVITY_CALL_RESULT,
    component: Lookup,
    componentProps: {
      fetchList: useSelectionFields,
      fieldLabel: 'languageKey',
      fieldValue: 'id',
      extraParams: { filter: { query: 'keyRoot=activity_call_result' } },
      isMultiple: true,
      useDefault: false,
    },
    getValue: (value: IdLanguageKey[]) => {
      return value?.map((v: any) => v?.id).join(',');
    },
  },
];

export const searchFields: LabelValue[] = [
  // {
  //   name: 'type',
  //   type: 'select',
  //   label: 'Type',
  //   defaultValue: '',
  //   options: [
  //     { label: 'All', value: 'TYPE_NONE' },
  //     { label: 'Customer', value: 'TYPE_CUSTOMER' },
  //     { label: 'Potential', value: 'TYPE_POTENTIAL' },
  //   ],
  // },
  // {
  //   name: 'email',
  //   type: 'email',
  //   label: 'Email',
  //   defaultValue: '',
  // },
  // {
  //   name: 'phone',
  //   type: 'text',
  //   label: 'Telephone',
  //   defaultValue: '',
  // },
  // {
  //   name: 'conversionDate',
  //   type: 'range',
  //   label: 'Conversion Date',
  //   time: true,
  // },
  {
    value: 'subject',
    label: 'Subject',
  },
];
