import { KEY_GRAPHQL_ACTIVITY_GET_ACTIVITY } from '@activity/config/queryKeys';
import { sequenceAtom } from '@activity/recoil/write/atom';
import { ADD_SEQUENCE, DELETE_SEQUENCE, UPDATE_SEQUENCE } from '@activity/services/mywork';
import { TaskSequence } from '@activity/types/interfaces';
import Duration from '@base/components/form/duration/edit';
import { CheckBox, Input, UserAutoComplete } from '@base/config/write-field/components';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { replaceItemAtIndex } from '@base/utils/helpers/array-utils';
import { parseDurationValueToString } from '@base/utils/helpers/date-utils';
import { convertDateTimeServerToClient } from '@base/utils/helpers/general.utils';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import React, { FormEvent, useState } from 'react';
import { Edit2, Save, X } from 'react-feather';
import { toast } from 'react-toastify';
import { useRecoilCallback } from 'recoil';

import { DUR_OPTIONS } from '.';

interface TaskSequenceItemProps {
  item: TaskSequence;
  seq: number;
  mode?: 'write' | 'view';
  menuSourceId?: string;
  onChange?: (val: TaskSequence[]) => void;
}

function TaskSequenceItem(props: TaskSequenceItemProps) {
  const { item, mode = 'write', menuSourceId, onChange, seq } = props;
  // console.log('sequence item', item);
  const [isSaving, setIsSaving] = useState(false);
  const itemMode = mode == 'view' ? (item.editFlag || item.newFlag ? 'write' : 'view') : 'write';

  const queryClient = useQueryClient();
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    ADD_SEQUENCE,
    'activity_createTaskSequence',
    {
      onMutate: async (variables: any) => {
        setIsSaving(true);
        // Cancel current queries for the  sequence
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(['activity_sequences']);

        // Snapshot the previous value
        const previousSequence = queryClient.getQueryData(['activity_sequences']);
        return { previousSequence };
      },
      onSuccess: (res: any, variables: any, context: any) => {
        setIsSaving(false);
        onMutationSuccess(variables);
        // Optimistically update to the new value
        // const optimisticSequence = variables.sequence as TaskSequence;
        // queryClient.setQueryData(['activity_sequence'], (old: any) => {
        //   return { results: [...old.results, optimisticSequence] };
        // });
        toast.success('Created Sequence successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        setIsSaving(false);
        // onMutationError();
        queryClient.setQueryData(['activity_sequences'], context.previousSequence);
        toast.success('Created Sequence failed');
      },
      // Always refetch after error or success:
      // onSettled: (data: any, error: any, variables: any, context: any) => {
      //   queryClient.invalidateQueries(['activity_sequence']);
      // },
    },
  );

  const mutationUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_SEQUENCE,
    'activity_updateTaskSequence',
    {
      onMutate: async () => {
        setIsSaving(true);
        await queryClient.cancelQueries(['activity_sequences']);
        const previousSequence = queryClient.getQueryData(['activity_sequences']);
        return { previousSequence };
      },
      onSuccess: (res: any, variables: any, context: any) => {
        setIsSaving(false);
        onMutationSuccess(variables);
        // const optimisticSequence = variables.sequence as TaskSequence;
        // queryClient.setQueryData(['activity_sequence'], (old: any) => {
        //   const items = old.results as TaskSequence[];
        //   const targetIndex = items.findIndex((v: TaskSequence) => v.id == item.id);
        //   const updateData = { ...items[targetIndex], ...optimisticSequence };
        //   const newItems = replaceItemAtIndex(items, targetIndex, updateData);
        //   return { results: newItems };
        // });
        toast.success('Updated Sequence successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        setIsSaving(false);
        // onMutationError();
        queryClient.setQueryData(['activity_sequences'], context.previousSequence);
        toast.success('Updated Sequence failed');
      },
    },
  );

  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_SEQUENCE,
    'activity_deleteTaskSequence',
    {
      onMutate: async (variables: any) => {
        setIsSaving(true);
        await queryClient.cancelQueries(['activity_sequences']);
        const previousSequence = queryClient.getQueryData(['activity_sequences']);
        return { previousSequence };
      },
      onSuccess: (res: any, variables: any, context: any) => {
        // console.log('conttext', context);
        setIsSaving(false);
        // queryClient.setQueryData(['activity_sequence'], (old: any) => {
        //   return {
        //     results: old.results?.filter(
        //       (v: TaskSequence) => v.id !== context.optimisticSequence.id,
        //     ),
        //   };
        // });
        toast.success('Deleted Sequence successfully!');
      },
      onError: (error: any, context: any) => {
        setIsSaving(false);
        queryClient.setQueryData(['activity_sequences'], context.previousSequence);
        toast.success('Deleted Sequence failed');
      },
    },
  );

  // integrate with save api
  const onSave = () => {
    // api : newFlag : true - create, newFlag: false - edit
    let updateItem = _.clone(item);
    delete updateItem.newFlag;
    delete updateItem.editFlag;
    // delete updateItem.seq;
    delete updateItem.done;
    delete updateItem.doneTime;
    if (item.newFlag) {
      mutationAdd.mutate({ id: menuSourceId, sequence: updateItem });
    } else {
      mutationUpdate.mutate({ id: menuSourceId, sequence: updateItem });
    }
  };

  const handleRemoveRow = useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        if (item.editFlag) {
          handleValueChange('editFlag', false);
        } else {
          const items = snapshot.getLoadable(sequenceAtom).contents as TaskSequence[];
          const newItems = items.filter((ele) => ele.id !== item.id);
          set(sequenceAtom, newItems);
          onChange && onChange(newItems);

          if (!item.newFlag && mode == 'view') {
            // run delete mutatation
            // console.log('run delete mutation');
            mutationDelete.mutate({ id: menuSourceId, refId: item.id });
          }
        }
      },
    [item, mode],
  );

  // const onMutationError = useRecoilCallback(
  //   ({ set, snapshot }) =>
  //     () => {
  //       const items = snapshot.getLoadable(sequenceAtom).contents as TaskSequence[];
  //       const newItems = items.filter((ele) => ele.id !== item.id);
  //       set(sequenceAtom, newItems);
  //       onChange && onChange(newItems);
  //     },
  //   [item],
  // );

  const onMutationSuccess = useRecoilCallback(
    ({ set, snapshot }) =>
      (variables: any) => {
        const optimistic = variables.sequence as TaskSequence;
        let newItem = _.clone(item);
        delete newItem.newFlag;
        delete newItem.editFlag;
        const updateData = { ...newItem, ...optimistic };
        const items = snapshot.getLoadable(sequenceAtom).contents as TaskSequence[];
        const targetIndex = items.findIndex((v) => v.id == item.id);
        const newItems = replaceItemAtIndex(items, targetIndex, updateData);
        set(sequenceAtom, newItems);
        onChange && onChange(newItems);
        // check done all and update activity status
        const completed = items.filter((v) => v.done).length;
        if (completed == items.length) {
          queryClient.setQueryData([KEY_GRAPHQL_ACTIVITY_GET_ACTIVITY], (old: any) => {
            return { ...old, status: 'STATUS_DONE' };
          });
        }
      },
    [item],
  );

  const handleValueChange = useRecoilCallback(
    ({ set, snapshot }) =>
      (key: keyof TaskSequence, val: any | any[]) => {
        const items = snapshot.getLoadable(sequenceAtom).contents as TaskSequence[];
        const targetIndex = items.findIndex((v) => v.id == item.id);
        if (key == 'done') {
          val = val.currentTarget.checked;
          if (targetIndex != 0 && !items[targetIndex - 1]?.done) {
            toast.info('You should check in order.');
            return;
          }
        }
        const targetValue = { ...item, [key]: val };
        const newItems = replaceItemAtIndex(items, targetIndex, targetValue);
        set(sequenceAtom, newItems);
        onChange && onChange(newItems);
        if (key == 'done' && mode == 'view') {
          let updateData: any = {
            id: menuSourceId,
            sequence: {
              id: item.id,
              done: val,
            },
          };
          const completed = items.filter((v) => v.done).length;
          if (completed + 1 == items.length) {
            updateData.sequence.completed = true;
          }
          mutationUpdate.mutate(updateData);
        }
      },
    [item],
  );

  const renderItemWrite = () => {
    return (
      <>
        <div className="divTableCell">
          <div className="d-flex align-items-center justify-content-start mg-l-10">
            <div className="mg-r-5">#{seq + 1}</div>
            <Input
              className="wd-200"
              value={item.title}
              onChange={(val: string) => handleValueChange('title', val)}
            />
          </div>
        </div>
        <div className="divTableCell">
          <div className="d-flex justify-content-center">
            <Duration
              value={{ duration: Number(item.duration.time), durationUnit: item.duration.unit }}
              options={DUR_OPTIONS}
              onChange={(val) => {
                const newDuration = {
                  time: val.duration,
                  unit: val.durationUnit,
                };
                handleValueChange('duration', newDuration);
              }}
            />
          </div>
        </div>
        <div className="divTableCell">
          <div className="d-flex justify-content-center">
            <UserAutoComplete
              className="wd-250"
              value={item.workers?.map((v) => ({ id: v.user.id, name: v.user.name }))}
              onChange={(users: any[]) => {
                const newWorkers = users?.map((_ele: any) => ({
                  user: { id: _ele.id, name: _ele.name },
                  group: { id: '', name: '' },
                }));
                handleValueChange('workers', newWorkers);
              }}
            />
          </div>
        </div>
        <div className="divTableCell wd-80">
          <div className="d-flex align-items-center justify-content-center">
            {mode == 'view' && (
              <button
                type="button"
                className="btn btn-xs tx-success btn-icon"
                onClick={onSave}
                disabled={isSaving}
              >
                {isSaving ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <Save />
                )}
              </button>
            )}
            <button
              type="button"
              className="btn btn-xs tx-warning btn-icon"
              onClick={handleRemoveRow}
              disabled={isSaving}
            >
              <X />
            </button>
          </div>
        </div>
      </>
    );
  };

  //view item
  const renderItemView = () => {
    return (
      <>
        <div className="divTableCell">
          <div className="d-flex align-items-center justify-content-start mg-l-10">
            <CheckBox
              checked={item.done}
              disabled={item.done}
              onChange={(e: FormEvent<HTMLInputElement>) => handleValueChange('done', e)}
            />
            <div className="mg-r-5">#{seq + 1}</div>
            {item.title}
          </div>
          {item.done && (
            <div className="tx-12 tx-color-03 mg-l-35">
              {convertDateTimeServerToClient({
                date: item.doneTime,
                isTime: true,
                humanize: true,
              })}
            </div>
          )}
        </div>
        <div className="divTableCell text-right">
          <div className="mg-r-5">
            {parseDurationValueToString({
              durationUnit: item.duration.unit,
              duration: item.duration.time,
            })}
          </div>
        </div>
        <div className="divTableCell">
          <div className="mg-l-10">{item.workers?.map((_ele) => _ele.user?.name).join(', ')}</div>
        </div>
        <div className="divTableCell wd-80">
          <div className="d-flex align-items-center justify-content-center">
            {mode == 'view' && (
              <button
                type="button"
                className="btn btn-xs tx-primary btn-icon"
                onClick={() => handleValueChange('editFlag', true)}
              >
                <Edit2 />
              </button>
            )}
            <button
              type="button"
              className="btn btn-xs tx-danger btn-icon"
              onClick={handleRemoveRow}
              disabled={isSaving}
            >
              <X />
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <React.Suspense>{itemMode == 'write' ? renderItemWrite() : renderItemView()}</React.Suspense>
  );
}

export default React.memo(TaskSequenceItem);
