import React, { useCallback } from 'react';
import RawHTML from '@base/components/raw-html';
import { Activity, UserOrCustomer } from '@activity/types/interfaces';

interface SmsBodyProps {
  value: Activity;
}

function SmsBody({ value: activityData }: SmsBodyProps) {
  const makeSmsFormat = useCallback((data: UserOrCustomer[]) => {
    const l = data.length;
    return data.map((v: UserOrCustomer, index) => {
      return (
        <React.Fragment key={v.id}>
          {v.name}
          {v.phone && `<${v.phone}>`}
          {l > index + 1 && ','}
        </React.Fragment>
      );
    });
  }, []);

  return (
    <div className="card card-body overflow-x-auto">
      <ul className="list-unstyled mg-0 pd-0">
        <li className="d-flex mg-b-10">
          <span className="mg-r-10 tx-color-03">From:</span>
          {makeSmsFormat(activityData?.from)}
        </li>
        <li className="d-flex mg-b-10">
          <span className="mg-r-10 tx-color-03">To:</span>
          {makeSmsFormat(activityData?.to)}
        </li>
      </ul>
      <div className="mg-t-15 pd-10 bd rounded">
        <RawHTML>{activityData?.description}</RawHTML>
      </div>
    </div>
  );
}

export default SmsBody;
