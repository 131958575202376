import { KEY_GRAPHQL_ACTIVITY_GET_TAGS } from '@activity/config/queryKeys';
import { ACTIVITY_GET_TAGS } from '@activity/services/mywork';
import TagInput from '@base/components/form/tag-input';
import usePost from '@base/hooks/usePost';
import { BaseResponse } from '@base/types/interfaces/response';

import AssignTagsMutate from './mutate';

interface AssignTagsProps {
  menuSource: string;
  menuSourceId: string;
}

function AssignTags(props: AssignTagsProps) {
  const { menuSourceId } = props;
  const { data } = usePost<BaseResponse<string>>(
    [KEY_GRAPHQL_ACTIVITY_GET_TAGS],
    ACTIVITY_GET_TAGS,
    {
      id: menuSourceId,
    },
  );

  const { mAddTags, mDeleteTags } = AssignTagsMutate();

  const onAdd = (tag: string) => {
    // console.log(tag);
    const params = {
      tag: tag,
      id: menuSourceId,
    };
    // console.log('add tag', params);
    mAddTags.mutate(params);
  };

  const onDelete = (tag: string) => {
    // console.log(tag);
    const params = {
      id: menuSourceId,
      tag: tag,
    };
    mDeleteTags.mutate(params);
  };

  //render
  return (
    <>
      <div className="pos-relative">
        <TagInput value={data?.results ?? []} onAdd={onAdd} onDelete={onDelete} mode={'view'} />
      </div>
    </>
  );
}

export default AssignTags;
