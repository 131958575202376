import React, { useState } from 'react';
import { Select } from '@base/components/form';

const Edit = (props: any) => {
  const { className, value, options, onChange } = props;
  const [selectedValue, setSelectedValue] = useState(value);

  const handleValueChange = (newValue: string) => {
    setSelectedValue(newValue);
    //callback
    onChange && onChange(newValue);
  };

  return (
    <div className={className}>
      <Select
        outSide={true}
        options={options}
        value={options.find((el: any) => el.value === selectedValue)}
        onChange={handleValueChange}
      />
    </div>
  );
};

export default Edit;
