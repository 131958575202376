import React from 'react';
import CommonViewField from '@base/containers/view-field/common';

import View from './view';
import Edit from './edit';
import { StatusFields } from '@activity/config/constants';

const Status = (props: any) => {
  const options = StatusFields({ todo: 'Todo', doing: 'Doing', hold: 'Hold', done: 'Done' });

  return <CommonViewField {...props} options={options} componentView={View} componentEdit={Edit} />;
};

export default Status;
