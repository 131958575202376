import * as keyNames from '@activity/config/keyNames';

const allCallColumn = [
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_SUBJECT,
    languageKey: 'Subject',
    defaultViewInList: true,
    sortable: true,
    name: keyNames.KEY_NAME_ACTIVITY_SUBJECT,
    title: 'Subject',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_DIRECTION,
    languageKey: 'Direction',
    defaultViewInList: true,
    sortable: true,
    name: keyNames.KEY_NAME_ACTIVITY_DIRECTION,
    title: 'Direction',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_TO,
    languageKey: 'Customer',
    defaultViewInList: true,
    sortable: false,
    name: keyNames.KEY_NAME_ACTIVITY_TO,
    title: 'Customer',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_CALL_TYPE,
    languageKey: 'Call Type',
    defaultViewInList: true,
    sortable: false,
    name: keyNames.KEY_NAME_ACTIVITY_CALL_TYPE,
    title: 'Call Type',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_START_TIME,
    languageKey: 'Start Date',
    defaultViewInList: true,
    sortable: true,
    name: keyNames.KEY_NAME_ACTIVITY_START_TIME,
    title: 'Start Date',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_DURATION,
    languageKey: 'Duration',
    defaultViewInList: true,
    sortable: true,
    name: keyNames.KEY_NAME_ACTIVITY_DURATION,
    title: 'Duration',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_CALL_RESULT,
    languageKey: 'Call Result',
    defaultViewInList: true,
    sortable: false,
    name: keyNames.KEY_NAME_ACTIVITY_CALL_RESULT,
    title: 'Call Result',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_FROM,
    languageKey: 'Assigned Rep',
    defaultViewInList: true,
    sortable: false,
    name: keyNames.KEY_NAME_ACTIVITY_FROM,
    title: 'Assigned Rep',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_STATUS,
    languageKey: 'Status',
    defaultViewInList: true,
    sortable: false,
    name: keyNames.KEY_NAME_ACTIVITY_STATUS,
    title: 'Status',
  },
];

export default allCallColumn;
