import * as keyNames from '@activity/config/keyNames';

const myScheduleSmsColumn = [
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_SUBJECT,
    languageKey: 'Subject',
    defaultViewInList: true,
    sortable: true,
    name: keyNames.KEY_NAME_ACTIVITY_SUBJECT,
    title: 'Subject',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_DIRECTION,
    languageKey: 'Direction',
    defaultViewInList: true,
    sortable: true,
    name: keyNames.KEY_NAME_ACTIVITY_DIRECTION,
    title: 'Direction',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_TO,
    languageKey: 'Customer',
    defaultViewInList: true,
    sortable: false,
    name: keyNames.KEY_NAME_ACTIVITY_TO,
    title: 'Customer',
  },
  {
    keyName: keyNames.KEY_NAME_ACTIVITY_START_TIME,
    languageKey: 'Send Date',
    defaultViewInList: true,
    sortable: true,
    name: keyNames.KEY_NAME_ACTIVITY_START_TIME,
    title: 'Send Date',
  },
];

export default myScheduleSmsColumn;
