import {
  ActivityCallTypeOptions,
  ActivityDirectionOptions,
  ActivitySendTypeOptions,
  ActivitySmsTypeOptions,
  ActivityTaskTypeOptions,
} from '@activity/config/constants';
import * as keyNames from '@activity/config/keyNames';
import * as components from '@activity/config/write-field/components';
import { ReminderState } from '@base/components/form/reminder';
import { Repeat } from '@base/components/form/repeat-time';
import { defaultReDay, RECURRENCE_EVERY_TYPE, RECURRENCE_TYPE } from '@base/components/form/repeat-time/configs';
import {
  CheckBox,
  Date,
  Duration,
  Editor,
  Input,
  Lookup,
  ProductAutoComplete,
  RadioGroup,
  Reminder,
  RepeatTime,
  TagInput,
  TextArea,
  UploadFile,
} from '@base/config/write-field/components';
import { DurationValue, IdLanguageKey, IdName } from '@base/types/interfaces/common';
import { LabelValue } from '@base/types/types/app';
import { DurationOptions } from '@base/utils/helpers/date-utils';
import validators from '@base/utils/validation/field-validator';
import { useSelectionFields } from '@settings/general-setting/services/format-service';
import { useMenuTemplates } from '@settings/template/services/service';
import dayjs from 'dayjs';
import _ from 'lodash';

export default {
  [keyNames.KEY_NAME_ACTIVITY_TASK_TYPE]: {
    component: RadioGroup,
    componentProps: {
      options: ActivityTaskTypeOptions,
    },
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: ActivityTaskTypeOptions[0],
    parseParam: (v: LabelValue) => v.value,
  },
  [keyNames.KEY_NAME_ACTIVITY_DIRECTION]: {
    component: RadioGroup,
    componentProps: {
      options: ActivityDirectionOptions,
    },
    validate: {
      required: validators.required,
    },
    defaultValue: ActivityDirectionOptions[1],
    parseParam: (v: LabelValue) => v.value,
  },
  [keyNames.KEY_NAME_ACTIVITY_START_TIME]: {
    component: Date,
    componentProps: {
      showTimeInput: true,
      dateFormat: 'yyyy/MM/dd H:m',
    },
    defaultValue: dayjs(),
    parseParam: (v: string) => v || null,
  },
  [keyNames.KEY_NAME_ACTIVITY_END_TIME]: {
    component: Date,
    componentProps: {
      showTimeInput: true,
      dateFormat: 'yyyy/MM/dd H:m',
    },
    defaultValue: null,
    parseParam: (v: string) => v || null,
  },
  [keyNames.KEY_NAME_ACTIVITY_DUE_DATE]: {
    component: Date,
    componentProps: {},
    validate: {
      required: validators.required,
    },
    defaultValue: null,
    parseParam: (v: string) => v || null,
  },
  [keyNames.KEY_NAME_ACTIVITY_SUBJECT]: {
    component: Input,
    componentProps: {},
    defaultValue: '',
    showFullRow: true,
    validate: {
      required: validators.required,
    },
  },
  [keyNames.KEY_NAME_ACTIVITY_DESCRIPTION]: {
    component: TextArea,
    componentProps: {},
    defaultValue: '',
    showFullRow: true,
  },
  [keyNames.KEY_NAME_ACTIVITY_CONTENT]: {
    component: Editor,
    componentProps: {
      editorProps: {
        height: '200px',
      },
    },
    showFullRow: true,
    defaultValue: '',
  },
  [keyNames.KEY_NAME_ACTIVITY_PRIORITY]: {
    component: Lookup,
    componentProps: {
      fetchList: useSelectionFields,
      fieldLabel: 'languageKey',
      fieldValue: 'id',
      extraParams: { filter: { query: 'keyRoot=priority' } },
      isMultiple: false,
    },
    validate: {
      required: validators.required,
    },
    defaultValue: null,
    parseParam: (v: IdLanguageKey) => v || null,
  },
  [keyNames.KEY_NAME_ACTIVITY_DURATION]: {
    component: Duration,
    componentProps: {
      options: _.clone(DurationOptions).splice(0, 4),
    },
    defaultValue: {
      duration: 86400,
      durationUnit: 'UNIT_DAY',
    },
    parseParam: (v: DurationValue) => ({
      time: v.duration,
      unit: v.durationUnit,
    }),
  },
  [keyNames.KEY_NAME_ACTIVITY_EDURATION]: {
    component: Duration,
    componentProps: {
      options: _.clone(DurationOptions).splice(0, 4),
    },
    validate: {
      required: validators.required,
    },
    defaultValue: {
      duration: 86400,
      durationUnit: 'UNIT_DAY',
    },
    showFullRow: false,
    parseParam: (v: DurationValue) => ({
      time: v.duration,
      unit: v.durationUnit,
    }),
  },
  [keyNames.KEY_NAME_ACTIVITY_FROM]: {
    component: components.FromUser,
    componentProps: {
      single: true,
      showAvatar: true,
      placement: 'left',
    },
    showFullRow: true,
    defaultValue: [],
  },
  [keyNames.KEY_NAME_ACTIVITY_MAIL_SHOW_CC]: {
    component: components.ShowMailCc,
    componentProps: {},
    showFullRow: true,
    hideTitle: true,
    defaultValue: { cc: false, bcc: false },
  },
  [keyNames.KEY_NAME_ACTIVITY_TO]: {
    component: components.ToCustomer,
    componentProps: {
      single: false,
      showAvatar: true,
      placement: 'left',
    },
    showFullRow: true,
    defaultValue: [],
  },
  [keyNames.KEY_NAME_ACTIVITY_PRODUCTS]: {
    component: ProductAutoComplete,
    componentProps: {
      isMultiple: true,
    },
    showFullRow: true,
    defaultValue: [],
    parseParam: (v: any[]) =>
      v.map((_v) => ({
        id: _v.id,
        name: _v.name,
      })),
  },
  [keyNames.KEY_NAME_ACTIVITY_TPL]: {
    component: Lookup,
    componentProps: {
      fetchList: useMenuTemplates,
      fieldLabel: 'name',
      fieldValue: 'id',
      extraParams: {},
      schema: `
        results {
          id
          name
        }
      `,
      isMultiple: false,
    },
    defaultValue: null,
    parseParam: (v: IdName) => v || null,
  },
  [keyNames.KEY_NAME_ACTIVITY_PURPOSE]: {
    component: Lookup,
    componentProps: {
      fetchList: useSelectionFields,
      fieldLabel: 'languageKey',
      fieldValue: 'id',
      extraParams: { filter: { query: 'keyRoot=activity_purpose' } },
      isMultiple: false,
    },
    validate: {
      required: validators.required,
    },
    defaultValue: null,
    parseParam: (v: IdLanguageKey) => v || null,
  },
  [keyNames.KEY_NAME_ACTIVITY_CALL_RESULT]: {
    component: Lookup,
    componentProps: {
      fetchList: useSelectionFields,
      fieldLabel: 'languageKey',
      fieldValue: 'id',
      extraParams: { filter: { query: 'keyRoot=activity_call_result' } },
      isMultiple: false,
    },
    validate: {
      required: validators.required,
    },
    defaultValue: null,
    parseParam: (v: IdLanguageKey) => v || null,
  },
  [keyNames.KEY_NAME_ACTIVITY_CALL_TYPE]: {
    component: RadioGroup,
    componentProps: {
      options: ActivityCallTypeOptions,
    },
    validate: {
      required: validators.required,
    },
    defaultValue: ActivityCallTypeOptions[0],
    parseParam: (v: LabelValue) => v.value || null,
  },
  [keyNames.KEY_NAME_ACTIVITY_SEND_TYPE]: {
    component: RadioGroup,
    componentProps: {
      options: ActivitySendTypeOptions,
    },
    defaultValue: ActivitySendTypeOptions[0],
    parseParam: (v: LabelValue) => v.value || null,
  },
  [keyNames.KEY_NAME_ACTIVITY_TAGS]: {
    component: TagInput,
    componentProps: {},
    defaultValue: [],
    showFullRow: true,
    parseParam: (v: any[]) => (v.length > 0 ? v : null),
  },
  [keyNames.KEY_NAME_ACTIVITY_TASK_KNOWLEDGE]: {
    component: components.KnowledgeBaseInserted,
    componentProps: {},
    showFullRow: true,
    defaultValue: [],
    parseParam: (v: any[]) => (v.length > 0 ? v : null),
  },
  [keyNames.KEY_NAME_ACTIVITY_TASK_CHECKLIST]: {
    component: components.TaskChecklist,
    componentProps: {},
    showFullRow: true,
    defaultValue: [],
    parseParam: (v: any[]) => (v.length > 0 ? v : null),
  },
  [keyNames.KEY_NAME_ACTIVITY_TASK_SEQUENCE]: {
    component: components.TaskSequence,
    componentProps: {},
    showFullRow: true,
    defaultValue: [],
    parseParam: (v: any[]) => (v.length > 0 ? v : null),
  },
  [keyNames.KEY_NAME_ACTIVITY_RELATED_TO]: {
    component: components.RelatedTo,
    componentProps: {},
    showFullRow: true,
    defaultValue: [],
    parseParam: (v: any[]) => (v.length > 0 ? v : null),
  },
  [keyNames.KEY_NAME_ACTIVITY_REPEAT]: {
    component: RepeatTime,
    componentProps: {},
    showFullRow: true,
    defaultValue: {
      type: RECURRENCE_TYPE.hourly,
      startTime: '0:5',
      endTime: '23:55',
      everyNr: '1',
      weekdays: defaultReDay,
      //monthly
      monthlyOption: 'week', //or 'day', 'last'
      monthlyWeek: 1,
      monthlyDay: 1,
      //yearly
      yearlyOption: 'week', //or 'day'
      yearlyWeekMonth: 1,
      yearlyDayMonth: 1,
      yearlyWeek: 1,
      yearlyDay: 1,
    },
    parseParam: (v: Repeat) => {
      const params: Record<string, any> = {
        use: v.use,
      };
      if (v.use) {
        params.type = v.type;
        if (v.type == 'TYPE_HOURLY') {
          params.startTime = v.startTime;
          params.endTime = v.endTime;
          params.days = v.weekdays;
        } else if (v.type == 'TYPE_DAILY') {
          params.days = v.weekdays;
        } else if (v.type == 'TYPE_WEEKLY') {
          params.times = Number(v.everyNr);
          params.days = v.weekdays;
        } else if (v.type == 'TYPE_MONTHLY') {
          params.times = Number(v.everyNr);
          params.every = RECURRENCE_EVERY_TYPE[v.monthlyOption];
          if (v.monthlyOption == 'week') {
            params.days = v.weekdays;
            params.weekNth = v.monthlyWeek;
          } else if (v.monthlyOption == 'day') {
            params.monthDay = v.monthlyDay;
          } else {
            // last
          }
        } else {
          // yearly
          params.times = Number(v.everyNr);
          params.every = RECURRENCE_EVERY_TYPE[v.yearlyOption];
          if (v.yearlyOption == 'week') {
            params.days = v.weekdays;
            params.weekNth = v.yearlyWeek;
            params.monthNth = v.yearlyWeekMonth;
          } else if (v.yearlyOption == 'day') {
            params.monthNth = v.yearlyDayMonth;
            params.monthDay = v.yearlyDay;
          }
        }
      }
      return params;
    },
  },
  [keyNames.KEY_NAME_ACTIVITY_REMINDER]: {
    component: Reminder,
    componentProps: {},
    defaultValue: {
      useReminder: false,
      reminderNotify: 'NOTIFY_POPUP',
      reminderEnd: 1440,
    },
    parseParam: (v: ReminderState) => {
      const reminder: Record<string, any> = {
        use: v.use,
      };
      if (v.use) {
        reminder.notify = v.notify;
        reminder.end = v.end;
      }
    },
  },
  [keyNames.KEY_NAME_ACTIVITY_SMS_TYPE]: {
    component: RadioGroup,
    componentProps: {
      options: ActivitySmsTypeOptions,
    },
    validate: {
      required: validators.required,
    },
    defaultValue: ActivitySmsTypeOptions[0],
    parseParam: (v: LabelValue) => v.value || null,
  },
  [keyNames.KEY_NAME_ACTIVITY_CC]: {
    component: components.CustomerUser,
    componentProps: {
      single: false,
      showAvatar: true,
      showEmail: true,
      placement: 'top',
    },
    showFullRow: true,
    defaultValue: [],
    parseParam: (v: any[]) => (v.length > 0 ? v : null),
  },
  [keyNames.KEY_NAME_ACTIVITY_BCC]: {
    component: components.CustomerUser,
    componentProps: {
      single: false,
      showAvatar: true,
      showEmail: true,
      placement: 'top',
    },
    showFullRow: true,
    defaultValue: [],
    parseParam: (v: any[]) => (v.length > 0 ? v : null),
  },
  [keyNames.KEY_NAME_ACTIVITY_MAIL_SEND_INDIVIDUAL]: {
    component: CheckBox,
    hideTitle: true,
    componentProps: {
      label: 'Send Individually',
    },
    showFullRow: true,
    parseParam: (v: any) => v || null,
  },
  [keyNames.KEY_NAME_ACTIVITY_ATTACHMENTS]: {
    component: UploadFile,
    componentProps: {
      canDrop: true,
      multiple: true,
    },
    showFullRow: true,
    defaultValue: [],
    parseParam: (v: any[]) => (v.length > 0 ? v : null),
  },
  [keyNames.KEY_NAME_ACTIVITY_SMS_IMAGE]: {
    component: UploadFile,
    componentProps: {
      canDrop: true,
      multiple: false,
      imageUpload: true,
      maxSize: 1000000,
    },
    showFullRow: true,
    defaultValue: [],
    parseParam: (v: any[]) => (v.length > 0 ? v : null),
  },
};
