import * as keyNames from '@activity/config/keyNames';

import {
  allCallColumn,
  allIncomingCallColumn,
  allMissedCallColumn,
  allOutgoingCallColumn,
  myCallColumn,
  myIncomingCallColumn,
  myMissedCallColumn,
  myOutgoingCallColumn,
} from './call-column';
import {
  allEmailColumn,
  allReceiveEmailColumn,
  allScheduleEmailColumn,
  allSentEmailColumn,
  myEmailColumn,
  myReceiveEmailColumn,
  mySentEmailColumn,
} from './mail-column';
import myScheduleEmailColumn from './mail-column/myScheduleEmailColumn';
import { myColumn, myOverdueColumn } from './my-column';
import { allScheduleSmsColumn, allSmsColumn, mySmsColumn } from './sms-column';
import myScheduleSmsColumn from './sms-column/myScheduleSmsColumn';
import {
  allDoneTaskColumn,
  allTaskColumn,
  allTodoTaskColumn,
  myDoneTaskColumn,
  myTaskColumn,
  myTodoTaskColumn,
} from './task-column';

/*
dataType: "activity_type"
defaultViewInList: true
hidden: false
id: "24886442-767b-460a-9774-7e79309c3f16"
isDefault: true
keyName: "type"
languageKey: "activity_activity_field_basic_type"
name: "type"
order: 0
orderInList: 0
orderInView: 0
orderInWrite: 0
permissionType: "basic_fixed"
showInList: true
showInView: false
showInWrite: true
title: "activity_activity_field_basic_type"
*/
export const listLayoutColumns: { [index: string]: any[] } = {
  my: myColumn,
  my_group: myColumn,
  my_overdue: myOverdueColumn,
  all_call: allCallColumn,
  all_missed_call: allMissedCallColumn,
  all_outgoing_call: allOutgoingCallColumn,
  all_incoming_call: allIncomingCallColumn,
  all_emails: allEmailColumn,
  all_scheduled_emails: allScheduleEmailColumn,
  all_sent_emails: allSentEmailColumn,
  all_received_emails: allReceiveEmailColumn,
  all_sms: allSmsColumn,
  all_scheduled_sms: allScheduleSmsColumn,
  all_tasks: allTaskColumn,
  all_todo_tasks: allTodoTaskColumn,
  all_done_tasks: allDoneTaskColumn,
  my_calls: myCallColumn,
  my_missed_call: myMissedCallColumn,
  my_outgoing_call: myOutgoingCallColumn,
  my_incoming_call: myIncomingCallColumn,
  my_emails: myEmailColumn,
  my_scheduled_emails: myScheduleEmailColumn,
  my_sent_emails: mySentEmailColumn,
  my_received_emails: myReceiveEmailColumn,
  my_sms: mySmsColumn,
  my_scheduled_sms: myScheduleSmsColumn,
  my_tasks: myTaskColumn,
  my_todo_tasks: myTodoTaskColumn,
  my_done_tasks: myDoneTaskColumn,
};

export const configFields = {
  [keyNames.KEY_NAME_ACTIVITY_SUBJECT]: {
    schema: `
      subject
      source {
        id
        menu
      }
    `,
  },
  [keyNames.KEY_NAME_ACTIVITY_FROM]: {
    schema: `
      from {
        type
        id
        name
      }
    `,
  },
  [keyNames.KEY_NAME_ACTIVITY_CREATED_BY]: {
    schema: `
      createdBy {
        id
        name
      }
    `,
  },
  [keyNames.KEY_NAME_ACTIVITY_TO]: {
    schema: `
      to {
        type
        id
        name
      }
    `,
  },
  [keyNames.KEY_NAME_ACTIVITY_DATE]: {
    schema: `
      startTime  
      endTime
    `,
  },
  [keyNames.KEY_NAME_ACTIVITY_DURATION]: {
    schema: `
      duration {
        time
        unit
      }
    `,
  },
  [keyNames.KEY_NAME_ACTIVITY_EDURATION]: {
    schema: `
      eduration {
        time
        unit
      }
    `,
  },
  [keyNames.KEY_NAME_ACTIVITY_PRIORITY]: {
    schema: `
      priority {
        id
        languageKey
      }
    `,
  },
  [keyNames.KEY_NAME_ACTIVITY_PURPOSE]: {
    schema: `
      purpose {
        id
        languageKey
      }
    `,
  },
  [keyNames.KEY_NAME_ACTIVITY_CALL_RESULT]: {
    schema: `
      callResult {
        id
        languageKey
      }
    `,
  },
};
