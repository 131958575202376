import React, { VFC } from 'react';

import { useTranslation } from 'react-i18next';
import { isFunction } from 'lodash';
import { Mail, Phone } from 'react-feather';

import ListGridItemBase, {
  defaultColumnRender,
  ListGridItemHeader,
  ListGridItemBody,
} from '@base/components/list/list-grid/list-grid-item';
import { ACTIVITY_MENU_KEYS } from '../../../../config/constants';
import { useParams } from 'react-router-dom';
import { MENU_ACTIVITY } from '@base/config/menus';

export interface IListGridItem {
  primaryKey: string | number;
  data: any;
  isSplitMode: boolean;
  onCheckedRow: (checkedValue: string | number) => void;
  selectedIds: string[];
  columns: {
    name: string;
    title: string;
  }[];
  columnsOnSplitMode: {
    [key: string]: {
      icon?: React.ReactNode;
      isTag: boolean;
    };
  };
  columnRender?: (col: string) => any;
}

const ListGridItem: VFC<IListGridItem> = ({
  data,
  isSplitMode,
  onCheckedRow,
  selectedIds,
  columns,
  columnRender,
}: any) => {
  const { category } = useParams();
  const { t, i18n } = useTranslation();
  const type = ACTIVITY_MENU_KEYS[data.category];

  return (
    <ListGridItemBase
      primaryKey={'id'}
      data={data}
      isSplitMode={isSplitMode}
      onCheckedRow={onCheckedRow}
      selectedIds={selectedIds}
    >
      <ListGridItemHeader
        id={data.id}
        title={data?.subject}
        titleLink={`/activity/${category}/${type}_${data.id}`}
        isSplitMode={isSplitMode}
        onTitleEdited={() => {}}
        menuSource={MENU_ACTIVITY}
        keyName={'subject'}
      />

      <ListGridItemBody isSplitMode={isSplitMode}>
        {isSplitMode ? (
          <ul className="list-unstyled mg-b-0">
            <li className="d-flex flex-wrap mg-t-5">
              {type && (
                <span className="bg-indigo op-5 mg-y-5 pd-x-10 tx-white tx-13 text-truncate tx-normal rounded-30">
                  {type}
                </span>
              )}
            </li>
          </ul>
        ) : (
          <div className="grid-list-item-detail-body">
            {columns &&
              columns.map((col: any, i: number) => {
                const columnComponent =
                  isFunction(columnRender) && columnRender(col.name)
                    ? columnRender(col.name)(col.name, data)
                    : defaultColumnRender(col.name, data);

                return (
                  <div className="grid-list-item-col" key={i}>
                    <span className="grid-list-item-col-title">{col.title}:</span>
                    <span className="grid-list-item-col-content">{columnComponent}</span>
                  </div>
                );
              })}
          </div>
        )}
      </ListGridItemBody>
    </ListGridItemBase>
  );
};

ListGridItem.defaultProps = {
  primaryKey: 'id',
};

export default React.memo(ListGridItem);
