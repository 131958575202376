import React, { lazy } from 'react';
import CommonViewField from '@base/containers/view-field/common';
import { UserOrCustomer } from '@activity/types/interfaces';

const View = lazy(() => import('./view'));
const Edit = lazy(() => import('./edit'));

interface FromUserProps {
  value: UserOrCustomer[];
  onSave: (params: any) => void;
  keyName: string;
  menuSourceId: string;
  menuSource: string;
}

const FromUser = (props: FromUserProps) => {
  const { onSave, value, menuSourceId, menuSource, keyName, ...remainProps } = props;

  return (
    <CommonViewField
      keyName={keyName}
      menuSourceId={menuSourceId}
      menuSource={menuSource}
      onSave={onSave}
      componentView={View}
      componentEdit={Edit}
      value={value}
      {...remainProps}
    />
  );
};

export default FromUser;
