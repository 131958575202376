import React, {useEffect, useMemo} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {deviceAtom, listDataByMenuAtom} from "@base/recoil/atoms";
import {ListContainer} from "@base/components/list";
import {usePageLayoutByMenu} from "@base/hooks/usePageLayout";
import displayLayoutConfig from "@activity/config/display-layout";
import BaseLayoutDisplay from "@base/containers/base-layout-display";
import PageToolbar from "@activity/pages/comparison/toolbar";
import PageHeader from "@activity/pages/comparison/header";
import PageBody from "@activity/pages/comparison/body";
import {
  CATEGORY_COMPARISON,
  MENU_CATEGORY_COMPARISON
} from "@activity/config/comparison";
import {useComparisons} from "@activity/services/comparison";
import {activityComparisonFilterState} from "@activity/recoil/list";

interface ComparisonPageProps {}

const ComparisonPage: React.FC<ComparisonPageProps> = (props: ComparisonPageProps) => {
  let category = CATEGORY_COMPARISON;

  const {isDesktop, device, layout} = useRecoilValue(deviceAtom);
  const [listData, setListData] = useRecoilState<any>(listDataByMenuAtom(MENU_CATEGORY_COMPARISON));
  const [listFilter, setListFilter] = useRecoilState<any>(activityComparisonFilterState);

  let {data: listLayoutData, isLoading: isLayoutLoading} = usePageLayoutByMenu(
    MENU_CATEGORY_COMPARISON,
    'list',
  );

  let viewingFields: any = [];
  if (listFilter.activeMenu === category && listFilter.settingColumns) {
    viewingFields = listFilter.settingColumns.filter((_ele: any) => _ele.isViewing);
  }

  const {data, isLoading, refetch}: any = useComparisons(viewingFields);

  useEffect(() => {
    if (listLayoutData?.data) {
      const newItems: any[] = [];
      listLayoutData?.data.map((_ele: any) => {
        newItems.push({..._ele, isViewing: _ele.isDefault});
      });
      const newListFilter = {
        ...listFilter,
        settingColumns: newItems,
        activeMenu: category,
      };
      setListFilter(newListFilter);
    }
  }, [listLayoutData, category]);

  const defaultTotal = {
    outgoingCall: 0,
    incomingCall: 0,
    allCallDuration: 0,
    sentEmail: 0,
    receivedEmail: 0,
    sentSms: 0,
    task: 0
  };

  const defaultListData: any = {
    lastTotal: defaultTotal,
    results: [],
    total: defaultTotal
  };

  useEffect(() => {
    if (data) {
      let newListData = defaultListData;
      if(!!data?.lastTotal){
        newListData.lastTotal = {...newListData.lastTotal, ...data.lastTotal};
      }
      if(!!data?.results){
        newListData.results = [...newListData.results, ...data.results];
      }
      if(!!data?.total){
        newListData.total = {...newListData.total, ...data.total};
      }

      setListData(newListData);
    } else {
      setListData(defaultListData);
    }
  }, [data]);

  const ToolbarMemo = useMemo(() => {
    return (
      <PageToolbar
        category={category}
        refetch={refetch}
      />
    );
  }, [category]);

  const HeaderMemo = useMemo(() => {
    return (
      <PageHeader
        fields={listFilter.settingColumns || []}
        category={category}
      />
    );
  }, [category, listFilter.settingColumns, listData]);

  const BodyMemo = useMemo(() => {
    return (
      <PageBody
        category={category}
        isFetching={false}
        fields={viewingFields}
        listData={listData}
        paging={data?.paging}
      />
    );
  }, [category, listData, viewingFields]);

  return (
    <ListContainer
      isLoading={isLayoutLoading || isLoading}
      isDesktop={isDesktop}
    >
      <BaseLayoutDisplay
        displayConfig={displayLayoutConfig}
        device={device}
        layout={layout}
        form="list"
        toolbar={ToolbarMemo}
        header={HeaderMemo}
        body={BodyMemo}
      />
    </ListContainer>
  );
}

export default ComparisonPage;