import { lazy } from 'react';

// export const TaskSequence = lazy(() => import('@activity/components/sequence'));
// export const TaskSequence = lazy(() => import('@activity/containers/sequence-form'));
// export const TaskChecklist = lazy(() => import('@activity/components/checklist'));
// export const TaskChecklist = lazy(() => import('@activity/containers/checklist-form/edit'));
export const TaskSequence = lazy(() => import('@activity/containers/task-sequence'));
export const TaskChecklist = lazy(() => import('@activity/containers/task-checklist'));
export const RelatedTo = lazy(() => import('@activity/components/related-to'));
export const CustomerUser = lazy(() => import('@activity/components/customer-user'));
export const ToCustomer = lazy(() => import('@activity/components/to-customer/edit'));
export const FromUser = lazy(() => import('@activity/components/from-user/edit'));
export const ShowMailCc = lazy(() => import('@activity/components/show-mail-cc'));
export const KnowledgeBaseInserted = lazy(
  () => import('@desk/knowledge-base/components/knowledge-base-inserted'),
);
