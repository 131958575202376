import { CustomerAutoComplete } from '@base/config/write-field/components';
import { useCallback, useMemo } from 'react';
import { UserOrCustomer } from '@activity/types/interfaces';
import _ from 'lodash';

interface ToCustomerEditProps {
  value: UserOrCustomer[];
  mode?: 'phone' | 'email' | 'none';
  single?: boolean;
  onChange: (val: UserOrCustomer[]) => void;
}

function ToCustomerEdit(props: ToCustomerEditProps) {
  const { value, onChange, single = false, mode = 'none' } = props;

  const items = useMemo(() => {
    return value.map((v) => ({ ...v, label: v.name, value: v.id }));
  }, [value]);

  const setCustomer = (item: any) => {
    if (!_.has(item, 'code')) return item;
    let customer: UserOrCustomer = {
      type: item.category == 'CATEGORY_ACCOUNT' ? 'TYPE_ACCOUNT' : 'TYPE_CONTACT',
      id: item.id,
      name: item.name,
    };
    if (mode == 'email') {
      const email = item.emails?.find((o: any) => o.label?.label == 'LABEL_PRIMARY');
      customer.email = email?.email ?? '';
    } else if (mode == 'phone') {
      const phone = item.phones?.find((o: any) => o.label?.label == 'LABEL_PRIMARY');
      customer.phone = phone?.phoneNumber ?? '';
    }
    return customer;
  };

  const onChangeCustomer = (val: any | any[]) => {
    if (_.isEmpty(val)) {
      return;
    }

    if (single) {
      const customer = setCustomer(val);
      onChange && onChange([customer]);
    } else {
      const customers = _.isArray(val)
        ? val.map((v) => {
            return setCustomer(v);
          })
        : [];
      onChange && onChange(customers);
    }
  };

  // console.log('items', items);

  return (
    <div className="form-row">
      <div className="col-12">
        <CustomerAutoComplete
          single={single}
          value={items}
          onChange={(val: any) => onChangeCustomer(val)}
        />
      </div>
    </div>
  );
}

export default ToCustomerEdit;
