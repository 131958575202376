import React from 'react';
import Section from './section';

// Kanban Filter
const KabanCols = [
  {
    id: 'overdue',
    title: 'Overdue',
  },
  {
    id: 'today',
    title: 'Today',
  },
  {
    id: 'thisweek',
    title: 'This Week',
  },
  {
    id: 'others',
    title: 'Others',
  },
];

interface KanbanProps {}

const Kanban = (props: KanbanProps) => {
  return (
    <div className="d-flex ht-100p mg-r-15">
      {KabanCols.map((cols) => {
        return <Section key={cols.id} id={cols.id} title={cols.title} />;
      })}
    </div>
  );
};

export default Kanban;
