import React from 'react';
import PagelayoutSummary from '@base/containers/pagelayout-summary';

interface ViewLeftProps {
  menuSourceId: string;
  menuSource: string;
  ignoreFields?: string[];
};

const ViewLeft = (props: ViewLeftProps) => {
  const { menuSource, menuSourceId, ignoreFields = [] } = props;

  return (
    <div className="scroll-box h-100 overflow-auto">
      <PagelayoutSummary
        menuSource={menuSource}
        menuSourceId={menuSourceId}
        ignoreFields={ignoreFields}
      />
    </div>
  );
};
export default ViewLeft;
