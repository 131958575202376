import React, { useCallback } from 'react';
import RawHTML from '@base/components/raw-html';
import { Activity, UserOrCustomer } from '@activity/types/interfaces';

interface MailBodyProps {
  value: Activity;
}

function MailBody({ value: activityData }: MailBodyProps) {
  console.log('activitydata');
  const makeEmailFormat = useCallback((data: UserOrCustomer[]) => {
    const l = data?.length;
    return data?.map((v: UserOrCustomer, index) => {
      return (
        <React.Fragment key={v.id}>
          {v.name}
          {v.email && `<${v.email}>`}
          {l > index + 1 && ','}
        </React.Fragment>
      );
    });
  }, []);

  return (
    <div className="card card-body overflow-x-auto">
      <ul className="list-unstyled mg-0 pd-0">
        <li className="d-flex mg-b-10">
          <span className="mg-r-10 tx-color-03">From:</span>
          {makeEmailFormat(activityData?.from)}
        </li>
        <li className="d-flex mg-b-10">
          <span className="mg-r-10 tx-color-03">To:</span>
          {makeEmailFormat(activityData?.to)}
        </li>
        {activityData?.cc && (
          <li className="d-flex mg-b-10">
            <span className="mg-r-10 tx-color-03">CC:</span>
            {makeEmailFormat(activityData?.cc)}
          </li>
        )}
        {activityData?.bcc && (
          <li>
            <span className="mg-r-10 tx-color-03">BCC:</span>
            {makeEmailFormat(activityData?.bcc)}
          </li>
        )}
      </ul>
      <div className="mg-t-15 pd-10 bd rounded">
        <RawHTML>{activityData?.content}</RawHTML>
      </div>
    </div>
  );
}

export default MailBody;
